import React, { useState } from 'react';
import { Box, Card, CardHeader, Grid, TextField, Button, Snackbar, Alert, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { createSurvey } from 'src/services/apiService'; 
import { styled, useTheme } from '@mui/material/styles';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';

const RedAsterisk = styled('span')({
  color: 'red',
});

const FormCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  padding: theme.spacing(4),
}));

const FormHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const RequestSurvey = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery('(min-width: 1280px)');
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const tablet = useMediaQuery('(min-width: 768px)');
  
  const validationSchema = yup.object({
    SurveyProductName: yup.string().required(t('Survey Product Name is required')).matches(/^[A-Za-z0-9\s]+$/, t('Survey Product Name must contain only alphabets, numbers, and spaces')),
    SurveyProductCategory: yup.string().required(t('Survey Product Category is required')).matches(/^[A-Za-z0-9\s]+$/, t('Survey Product Category must contain only alphabets, numbers, and spaces')),
    SurveyName: yup.string().required(t('Survey Name is required')).matches(/^[A-Za-z0-9\s]+$/, t('Survey Name must contain only alphabets, numbers, and spaces')),
    AdditionalContext: yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      SurveyProductName: '',
      SurveyProductCategory: '',
      SurveyName: '',
      AdditionalContext: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const response = await createSurvey(values);
        if (response.success) {
          setAlertSeverity('success');
          setSnackbarMessage(t('Your survey creation request has been received and will be processed.'));
          formik.resetForm();
        } else {
          setAlertSeverity('error');
          setSnackbarMessage(t('Failed to initiate survey creation process.'));
        }
      } catch (error) {
        console.error('Error initiating survey creation process:', error);
        setAlertSeverity('error');
        setSnackbarMessage(t('Error initiating survey creation process.'));
      } finally {
        setSnackbarOpen(true);
        setIsSubmitting(false);
      }
    }
  });

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Grid container justifyContent="center" sx={{ background: 'linear-gradient(to right, #f5f7fa, #c3cfe2)', minHeight: '100vh' }}>
      <Grid item xs={12} sm={10} md={8} lg={6} sx={{ mt: 4 }}>
        <FormCard>
          <FormHeader title={t('Request a Survey')} />
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>{t('Please fill out the form below to request a new survey.')}</Typography>
            <TextField
              name="SurveyProductName"
              label={
                  <>
                    {t('Survey Product Name')} <RedAsterisk>*</RedAsterisk>
                  </>
                }
              placeholder={t('e.g., SQL Survey')}
              value={formik.values.SurveyProductName}
              onChange={formik.handleChange}
              error={formik.touched.SurveyProductName && Boolean(formik.errors.SurveyProductName)}
              helperText={formik.touched.SurveyProductName && formik.errors.SurveyProductName}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              name="SurveyProductCategory"
              label={
                <>
                  {t('Survey Product Category')} <RedAsterisk>*</RedAsterisk>
                </>
              }
              placeholder={t('e.g., Database Management')}
              value={formik.values.SurveyProductCategory}
              onChange={formik.handleChange}
              error={formik.touched.SurveyProductCategory && Boolean(formik.errors.SurveyProductCategory)}
              helperText={formik.touched.SurveyProductCategory && formik.errors.SurveyProductCategory}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              />
              <TextField
              name="SurveyName"
              label={
                <>
                  {t('Survey Name')} <RedAsterisk>*</RedAsterisk>
                </>
              }
              placeholder={t('e.g., Introduction to SQL')}
              value={formik.values.SurveyName}
              onChange={formik.handleChange}
              error={formik.touched.SurveyName && Boolean(formik.errors.SurveyName)}
              helperText={formik.touched.SurveyName && formik.errors.SurveyName}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              name="AdditionalContext"
              label={t('Additional Context (Optional)')}
              placeholder={t('e.g., Any additional information about the survey')}
              value={formik.values.AdditionalContext}
              onChange={formik.handleChange}
              error={formik.touched.AdditionalContext && Boolean(formik.errors.AdditionalContext)}
              helperText={formik.touched.AdditionalContext && formik.errors.AdditionalContext}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              multiline
              rows={4}
            />
            <SubmitButton
              variant="contained"
              type="submit"
              fullWidth
              color="primary"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <AddCircleIcon />}
            >
              {isSubmitting ? t('Submitting...') : t('Submit')}
            </SubmitButton>
          </Box>
        </FormCard>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ 
           top: isLargeScreen ? '64px' : mobile ? '90px' : '120px',
           marginTop: isLargeScreen ? '60px' : tablet ? '60px' : '0',
           ml: isLargeScreen? '45px' : tablet ? '5px' : '0',
           }}
      >
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default RequestSurvey;
