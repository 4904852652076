import { useContext } from "react";
import AuthContext from "src/contexts/Auth0Context";

const ApiBaseUrl = process.env.REACT_APP_COE_INDEX_SERVICE_ENDPOINT || '';
const PdfBlobBaseUrl = process.env.REACT_APP_CDN_ENDPOINT || '';
const ApiBaseUrlNode = process.env.REACT_APP_COE_INDEX_SERVICE_ENDPOINT_NODE || ''
const ApiAuth0DomainUrl = process.env.REACT_APP_AUTH0_DOMAIN || ''

export const ApiUrls = {
  base: ApiBaseUrl,
  surveyEndpoint: ApiBaseUrl + '/api/v1/survey',
  membershipEndpoint: ApiBaseUrl + '/api/v1/membership',
  stripePaymentSuccessReturnUrl: process.env.REACT_APP_STRIPE_RETURN_URL || '',
  pdfBlobEndpoint: PdfBlobBaseUrl + '/report/user/',
  CreateUserEndpoint : ApiAuth0DomainUrl + '/api/v2/users',
  UsersDataNodeEndpoint : ApiBaseUrlNode + '/users'
};