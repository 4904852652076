import { useState, useEffect, useContext } from 'react';
import { Box, Card, CardHeader, Grid, TextField, Button, Snackbar, Alert, CircularProgress, useTheme, styled, IconButton, Avatar, Typography, Skeleton, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/ui-bloom/hooks/useAuth';
import { User } from 'src/services/apiService/response-models';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { getProfileImageUrl, getUserByID, updateUser, uploadProfileImageToAzure } from 'src/services/apiService';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import {ProfileImageContext} from 'src/contexts/ProfileImageProviderContext'
import {dataURLtoFile} from 'src/utility/dataURLtoFile'
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";

const RedAsterisk = styled('span')({
  color: 'red',
});

const AvatarContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

const EditIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  backgroundColor: 'white',
  borderRadius: '50%',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  opacity: 1,
  width: 36, 
  height: 36, 
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

  
function GeneralContent() {
  const { t }: { t: any } = useTranslation();
  const auth = useAuth();
  const { userCtx } = useSelector((state: RootState) => state.app);
  const userId = userCtx.userId;
  const theme = useTheme();
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'warning'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [userData, setUserData] = useState<User | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { setProfileImage } = useContext(ProfileImageContext);
  const [localProfileImage, setLocalProfileImage] = useState<string | null>(null);
  const [fileInputKey, setFileInputKey] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [cropper, setCropper] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserByID(userId);
        if (response.success) {
          const user = response.data[0];
          const sanitizedUser: User = {
            ...user,
            UserFirstName: user.UserFirstName ?? '',
            UserLastName: user.UserLastName ?? '',
            UserPhoneNumber: user.UserPhoneNumber ?? '',
            UserCurrentCompanyLink: user.UserCurrentCompanyLink ?? '',
            UserCompanyName: user.UserCompanyName ?? '',
            UserJobTitle: user.UserJobTitle ?? '',
          };
          setUserData(sanitizedUser);
          formik.setValues(sanitizedUser);
  
          const profileImageResponse = await getProfileImageUrl(userId);
          if (profileImageResponse.success) {
            setLocalProfileImage(profileImageResponse.imageUrl);
            setProfileImage(profileImageResponse.imageUrl);
          } else {
            console.error('Failed to fetch profile image');
          }
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId,setProfileImage]);

  const validationSchema = yup.object({
    UserFirstName: yup
      .string()
      .required(t('First Name is required'))
      .matches(/^[A-Za-z\s]+$/, t('First Name must contain only alphabets')),
    UserLastName: yup
      .string()
      .required(t('Last Name is required'))
      .matches(/^[A-Za-z\s]+$/, t('Last Name must contain only alphabets')),
    UserPhoneNumber: yup
      .string()
      .required(t('Contact Number is required'))
      .matches(/^[0-9]{10}$/, t('Contact Number must be exactly 10 digits long')),
    UserCurrentCompanyLink: yup
      .string()
      .url(t('Company URL must be a valid URL'))
      .nullable(),
    UserCompanyName: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, t('Company Name must contain only alphabets and spaces'))
      .nullable(),
    UserJobTitle: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, t('Job Position must contain only alphabets and spaces'))
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      UserFirstName: '',
      UserLastName: '',
      UserPhoneNumber: '',
      UserCurrentCompanyLink: '',
      UserCompanyName: '',
      UserJobTitle: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (userData) {
          const updatedFields: Partial<User> = Object.keys(values).reduce((acc, key) => {
            if (values[key] !== userData[key]) {
              acc[key] = values[key];
            }
            return acc;
          }, {} as Partial<User>);

          if (Object.keys(updatedFields).length === 0) {
            setAlertSeverity('warning');
            setSnackbarMessage(t('Please update before submitting'));
            setSnackbarOpen(true);
            return;
          }
          setIsSubmitting(true);
          const response = await updateUser({ UserID: userId, UserEmail: userData.UserEmail, ...updatedFields });
          if (response.success) {
            setUserData((prevUserData) => ({
              ...prevUserData!,
              ...updatedFields,
            }));
            setAlertSeverity('success');
            setSnackbarMessage(t('User details updated successfully'));
          } else {
            setAlertSeverity('error');
            setSnackbarMessage(t('Failed to update details.'));
            console.error('Failed to update user');
            setIsSubmitting(false);
          }
          setSnackbarOpen(true);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Error updating user:', error);
        setAlertSeverity('error');
        setSnackbarMessage(t('Error updating user'));
        setSnackbarOpen(true);
        setIsSubmitting(false);
      }
    },
  });

  const handleAvatarClick = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setCropModalOpen(false);
    setFileInputKey((prevKey) => prevKey + 1); 
    setSelectedImage(null);
    setCropper(null); 
    setCropData(null); 
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  
  const handleProfileImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl = reader.result as string;
        setSelectedImage(imageUrl);
        setCropModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };
  
  const generateUniqueFilename = (baseName, extension) => {
    const timestamp = new Date().getTime();
    return `${baseName}_${timestamp}.${extension}`;
  };
  
  const handleUpload = async () => {
    if (typeof cropper !== "undefined") {
      const croppedData = cropper.getCroppedCanvas().toDataURL();
      setCropData(croppedData);
    }
  };
  
  useEffect(() => {
    const uploadCroppedImage = async () => {
      if (!cropData) return;
  
      setCropModalOpen(false);
      const uniqueFilename = generateUniqueFilename('profile_image', 'jpg');
      const file = dataURLtoFile(cropData, uniqueFilename);
  
      if (!file) return;
  
      setUploading(true);
      try {
        const imageUrl = await uploadProfileImageToAzure(file, userId);
        const imageUrlWithTimestamp = `${imageUrl}?${new Date().getTime()}`;
        setLocalProfileImage(imageUrlWithTimestamp);
        setProfileImage(imageUrlWithTimestamp);
        setFileInputKey((prevKey) => prevKey + 1);
        setAlertSeverity('success');
        setSnackbarMessage(t('Profile image updated successfully'));
      } catch (error) {
        setAlertSeverity('error');
        setSnackbarMessage(t('Error updating profile image'));
      } finally {
        setUploading(false);
        setSnackbarOpen(true);
        setCropper(null); 
        setCropData(null); 
      }
    };
  
    uploadCroppedImage();
  }, [cropData]);
  
  

  const getCustomHelperText = (field: string) => {
    if (formik.touched[field] && formik.errors[field]) {
      switch (field) {
        case 'UserFirstName':
          return t('Please provide a valid first name containing only alphabets.');
        case 'UserLastName':
          return t('Please provide a valid last name containing only alphabets.');
        case 'UserPhoneNumber':
          return t('Please provide a valid contact number containing exactly 10 digits.');
        case 'UserCurrentCompanyLink':
          return t('Please provide a valid company URL.');
        case 'UserCompanyName':
          return t('Please provide a valid company name containing only alphabets and spaces.');
        case 'UserJobTitle':
          return t('Please provide a valid job position containing only alphabets and spaces.');
        default:
          return t('This field is required.');
      }
    }
    return '';
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={10} lg={11} sx={{ mt: 2 }}>
        <Helmet>
          <title>Settings</title>
        </Helmet>
        <Card sx={{ p: 2 }}>
          <CardHeader title={t('User Profile Settings')} />
          {loading ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Box sx={{ position: 'relative' }}>
              <Skeleton variant="circular" width={120} height={120} />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={56} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={56} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={56} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={56} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={56} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={56} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Skeleton variant="rectangular" height={40} width={100}/>
          </Grid>
        </Grid>
      ) :(
            <>
              <AvatarContainer>
                <Box sx={{ position: 'relative' }}>
                <Avatar
                    alt={auth.user?.name}
                    src={localProfileImage || auth.user?.avatar}
                    sx={{
                      width: 120,
                      height: 120,
                      opacity: uploading ? 0.5 : 1, 
                      filter: uploading ? 'blur(2px)' : 'none',
                    }}
                    onClick={handleAvatarClick}
                    key={localProfileImage}
                  >
                  </Avatar>
                  {uploading && (
                      <CircularProgress
                        size={40}
                        sx={{
                          position: 'absolute',
                          top: '35%',
                          left: '35%',
                          transform: 'translate(-35%, -35%)',
                          zIndex: 1,
                          color: 'white'
                        }}
                      />
                    )}
                  <EditIconButton
                    className="edit-icon"
                    onClick={() => document.getElementById('profile-image-input')?.click()}
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      zIndex: 1,
                    }}
                  >
                    <CameraAltIcon />
                  </EditIconButton>
                </Box>
                <Typography variant="caption" sx={{ mt: 1 }}>
                  {auth.user?.name}
                </Typography>
                <input
                  type="file"
                  id="profile-image-input"
                  key={fileInputKey}
                  onChange={handleProfileImageChange}
                  style={{ display: 'none' }}
                />
                <input
                  type="file"
                  id="profile-image-input"
                  onChange={handleProfileImageChange}
                  style={{ display: 'none' }}
                />
               {cropModalOpen && (
                <Modal
                  open={cropModalOpen}
                  onClose={handleClose }
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{
                      width: '400px',
                      height: '400px', 
                      border: '1px solid #ddd',
                      borderRadius: '10px',
                      boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden' 
                    }}>
                      <Cropper
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        src={selectedImage}
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        style={{ 
                          maxHeight: '100%',
                          maxWidth: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '400px' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          handleClose(); 
                        }}
                      >
                        {t('Cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                      >
                        {t('Upload')}
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              )}
              {isModalOpen && (
                <Modal
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '65%',
                    transform: 'translate(-60%, -60%)',
                    p: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                    [theme.breakpoints.down('sm')]: {
                      top: '40%',
                      left: '50%',
                      transform: 'translate(-50%, -40%)',
                      width: '90vw',
                      height: '80vh',
                    },
                  }}>
                    <img src={localProfileImage} alt="Profile Picture" style={{
                      maxWidth: '100%',
                      maxHeight: '100vh',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      width: '300px',
                      height: '300px',
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        height: '100%',
                      },
                    }} />
                    <IconButton onClick={() => setIsModalOpen(false)} sx={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      color: 'white',
                      [theme.breakpoints.down('sm')]: {
                        top: 5,
                        right: 5,
                      },
                    }}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Modal>
              )}
              </AvatarContainer>
              <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={
                        <>
                          {t('First Name')} <RedAsterisk>*</RedAsterisk>
                        </>
                      }
                      name="UserFirstName"
                      value={formik.values.UserFirstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={getCustomHelperText('UserFirstName')}
                      error={formik.touched.UserFirstName && Boolean(formik.errors.UserFirstName)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={
                        <>
                          {t('Last Name')} <RedAsterisk>*</RedAsterisk>
                        </>
                      }
                      name="UserLastName"
                      value={formik.values.UserLastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={getCustomHelperText('UserLastName')}
                      error={formik.touched.UserLastName && Boolean(formik.errors.UserLastName)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={
                        <>
                          {t('Contact Number')} <RedAsterisk>*</RedAsterisk>
                        </>
                      }
                      name="UserPhoneNumber"
                      value={formik.values.UserPhoneNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={getCustomHelperText('UserPhoneNumber')}
                      error={formik.touched.UserPhoneNumber && Boolean(formik.errors.UserPhoneNumber)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t('Company URL')}
                      name="UserCurrentCompanyLink"
                      value={formik.values.UserCurrentCompanyLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={getCustomHelperText('UserCurrentCompanyLink')}
                      error={formik.touched.UserCurrentCompanyLink && Boolean(formik.errors.UserCurrentCompanyLink)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t('Company Name')}
                      name="UserCompanyName"
                      value={formik.values.UserCompanyName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={getCustomHelperText('UserCompanyName')}
                      error={formik.touched.UserCompanyName && Boolean(formik.errors.UserCompanyName)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t('Job Position')}
                      name="UserJobTitle"
                      value={formik.values.UserJobTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={getCustomHelperText('UserJobTitle')}
                      error={formik.touched.UserJobTitle && Boolean(formik.errors.UserJobTitle)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                      {isSubmitting ? t('Submitting...') : t('Submit')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
         <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{
                mt: { xs: 10, sm: 8, lg: 8 },
                mr: { xs: 4, sm: 20, lg: 40 },
                ml: { xs: 2, sm: 5, lg: 5 }
              }}
            >
              <Alert onClose={handleCloseSnackbar} severity={alertSeverity}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
        </Card>
      </Grid>
    </Grid>
  );
}

export default GeneralContent;
