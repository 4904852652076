import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  Skeleton,
  Select,
  MenuItem,
  Badge,
  Tooltip
} from '@mui/material';
import {
  CheckCircleOutline,
  Group,
  Business,
  ManageAccounts,
  AccountCircle,
  Email,
  AssignmentTurnedIn,
  VerifiedUser,
  HourglassFullTwoTone,
  FilterList
} from '@mui/icons-material';
import { getRoles, updateUserApproval, getUsersApproval, getUsersWithRoleID } from 'src/services/apiService';
import AlertSnackbar from '../../components/AlertSnackbar';
import { UserRoles, UsersApproval } from 'src/services/apiService/response-models';

interface ManageUsersProps {
  searchKey: string;
}

const ManageUsers: React.FC<ManageUsersProps> = ({ searchKey }) => {
  const [users, setUsers] = useState<UsersApproval[]>([]);
  const [roles, setRoles] = useState<UserRoles[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [approvalFilter, setApprovalFilter] = useState<string[]>([]);
  const [roleFilter, setRoleFilter] = useState<number[]>([]);
  const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true; 
    const fetchData = async () => {
      try {
        setLoading(true);
        const [userDataResponse, rolesResponse] = await Promise.all([
          getUsersWithRoleID(),
          getRoles()
        ]);
        if (isMounted) {
          const usersWithRoleID = userDataResponse.data; 
          setUsers(usersWithRoleID);
          setRoles(rolesResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  // const handleStatusChange = async (userId: number, newStatus: string) => {
  //   try {
  //     setUsers((prevUsers) =>
  //       prevUsers.map((user) =>
  //         user.UserID === userId ? { ...user, IsApproved: newStatus === 'Approved' ? 1 : 0 } : user
  //       )
  //     );

  //     const userToUpdate = users.find((user) => user.UserID === userId);
  //     if (!userToUpdate) return;

  //     await updateUserApproval({
  //       UserID: userId,
  //       IsApproved: newStatus === 'Approved' ? 1 : 0,
  //     });

  //     setSnackbarMessage(`User "${userToUpdate.UserEmail}" status changed to "${newStatus}"`);
  //     setAlertSeverity('success');
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     console.error('Error updating status:', error);
  //     setAlertSeverity('error');
  //     setSnackbarMessage('Failed to update status');
  //     setSnackbarOpen(true);
  //   }
  // };

  const handleRoleChange = async (userId: number, roleId: number | null) => {
    try {
      const userToUpdate = users.find((user) => user.UserID === userId);
      if (!userToUpdate) return;

      const roleName = roleId !== null ? roles.find((role) => role.RoleID === roleId)?.RoleName : 'None';

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.UserID === userId ? { ...user, RoleID: roleId } : user
        )
      );

      const updateData = {
        UserID: userId,
        RoleID: roleId
      };

      await updateUserApproval(updateData);

      const message = roleId !== null
        ? `Assigned role "${roleName}" to user "${userToUpdate.UserEmail}", and a notification email has been sent`
        : `Removed role from user "${userToUpdate.name}"`;

      setAlertSeverity('success');
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating role:', error);
      setAlertSeverity('error');
      setSnackbarMessage('Failed to assign role');
      setSnackbarOpen(true);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };

  const handleFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  // const handleApprovalFilterChange = (status: string) => {
  //   if (approvalFilter.includes(status)) {
  //     setApprovalFilter(approvalFilter.filter((item) => item !== status));
  //   } else {
  //     setApprovalFilter([...approvalFilter, status]);
  //   }
  // };

  const handleRoleFilterChange = (roleId: number) => {
    if (roleFilter.includes(roleId)) {
      setRoleFilter(roleFilter.filter((item) => item !== roleId));
    } else {
      setRoleFilter([...roleFilter, roleId]);
    }
  };

  const clearAllFilters = () => {
    setApprovalFilter([]); 
    setRoleFilter([]); 
  };

  const isFilterApplied = useMemo(() => {
    return approvalFilter.length > 0 || roleFilter.length > 0;
  }, [approvalFilter, roleFilter]);

  const filteredUsers = useMemo(() => {
    const key = searchKey.trim().toLowerCase();
    return users.filter((user) => {
      const approvalMatch = approvalFilter.length === 0 ||
        (approvalFilter.includes('Approved') && user.IsApproved === 1) 
        // || (approvalFilter.includes('Pending') && user.IsApproved === 0);
      const roleMatch = roleFilter.length === 0 || roleFilter.includes(user.RoleID);
      const nameMatch = (user.name && user.name.toLowerCase().includes(key));
      const emailMatch = (user.UserEmail && user.UserEmail.toLowerCase().includes(key));
      return approvalMatch && roleMatch && (nameMatch || emailMatch);
    });
  }, [users, approvalFilter, roleFilter, searchKey]);
  
  

  const paginatedUsers = useMemo(() => {
    return filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredUsers, page, rowsPerPage]);

  return (
    <>
      <Paper sx={{ padding: isSmallScreen? 0.5 : 2, boxShadow: 3 }} >
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1.5}>
      <Badge
  color="primary"
  badgeContent={isFilterApplied ? approvalFilter.length + roleFilter.length : 0}
  invisible={!isFilterApplied}
>
        <IconButton onClick={handleFilterDialogOpen} color={isFilterApplied ? 'primary' : 'default'} sx={{ fontSize: '2rem' }}>
          <FilterList sx={{ fontSize: 'inherit' }} />
        </IconButton>
      </Badge>
      <Typography variant="body2" color={isFilterApplied ? 'primary' : 'textSecondary'} ml={1}>
        {isFilterApplied ? 'Filters Applied' : 'Apply Filter'}
      </Typography>
    </Box>
        <Dialog open={filterDialogOpen} onClose={handleFilterDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>
    {isFilterApplied ? (
      <Button onClick={clearAllFilters}>Clear Filters</Button>
    ) : (
      'Filter Users'
    )}
  </DialogTitle>
          <DialogContent>
            {/* <Box display="flex" flexDirection="column">
              <Typography variant="h6" color="textPrimary">
                Approval Status
              </Typography>
              <FormControlLabel
    control={<Checkbox checked={approvalFilter.includes('Pending')} onChange={() => handleApprovalFilterChange('Pending')} />}
    label="Pending"
  />
  <FormControlLabel
    control={<Checkbox checked={approvalFilter.includes('Approved')} onChange={() => handleApprovalFilterChange('Approved')} />}
    label="Approved"
  />
            </Box> */}
            <Box display="flex" flexDirection="column" mt={2}>
              <Typography variant="h6">Roles</Typography>
              {roles.map((role) => (
                <FormControlLabel
                  key={role.RoleID}
                  control={<Checkbox checked={roleFilter.includes(role.RoleID)} onChange={() => handleRoleFilterChange(role.RoleID)} />}
                  label={role.RoleName}
                  />
                  ))}
                  </Box>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleFilterDialogClose} color="primary">
                  Close
                  </Button>
                  </DialogActions>
                  </Dialog>
                      {/* Table */}
    <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#2442AF' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Id</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Email</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            Array.from(Array(10).keys()).map((index) => (
              <TableRow key={index}>
                <TableCell colSpan={5}>
                  <Skeleton variant="rectangular" height={50} animation="wave" />
                </TableCell>
              </TableRow>
            ))
          ) : (
            paginatedUsers.map((user) => (
              <TableRow
                key={`${user.UserID}-${user.RoleID}`}
                sx={{
                  '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                  '&:hover': { backgroundColor: '#e3f2fd', cursor: 'pointer' }
                }}
              >
                <TableCell>{user.UserID}</TableCell>
                <TableCell>
                      {user.name.trim() ? (
                        <Tooltip title={user.name.trim()} arrow>
                          <span style={{ display: 'inline-block', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            <AccountCircle sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                            <span style={{ color: theme.palette.text.primary }}>
                              {user.name.trim()}
                            </span>
                          </span>
                        </Tooltip>
                      ) : (
                        <span style={{ display: 'inline-block', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <AccountCircle sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                          <span style={{ color: theme.palette.text.disabled }}>
                            No Name
                          </span>
                        </span>
                      )}
                    </TableCell>
                <TableCell>
                  <Email sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                  {user.UserEmail || 'No Email'}
                </TableCell>
                <TableCell >
                <VerifiedUser sx={{ verticalAlign: 'middle', marginRight: 1, color: 'green' }} />{' '}
                <span style={{ color: 'textPrimary' }}>Approved</span>
                  {/* <Select
                    value={user.IsApproved === 0 ? 'Pending' : 'Approved'}
                    onChange={(e) => handleStatusChange(user.UserID, e.target.value as string)}
                    sx={{ width: isSmallScreen ? '100%' : '150px' }}
                    MenuProps={{ disableScrollLock: true }}
                  >
                    <MenuItem sx={{ color: '#ff9800', display: 'flex', alignItems: 'center' }} dense value="Pending">
                      <HourglassFullTwoTone sx={{ verticalAlign: 'middle', marginRight: 1, color: '#ff9800' }} />{' '}
                      <span style={{ color: 'textPrimary' }}>Pending</span>
                    </MenuItem>
                    <MenuItem sx={{ color: '#4caf50', display: 'flex', alignItems: 'center' }} dense value="Approved">
                      <VerifiedUser sx={{ verticalAlign: 'middle', marginRight: 1, color: 'green' }} />{' '}
                      <span style={{ color: 'textPrimary' }}>Approved</span>
                    </MenuItem>
                  </Select> */}
                </TableCell>
                <TableCell>
                  <Select
                    value={user.RoleID !== null && user.RoleID !== undefined ? user.RoleID.toString() : 'null'}
                    onChange={(e) =>
                      handleRoleChange(
                        user.UserID,
                        e.target.value !== 'null' ? parseInt(e.target.value as string) : null
                      )
                    }
                    sx={{ width: isSmallScreen ? '100%' : '150px' }}
                    MenuProps={{ disableScrollLock: true }}
                  >
                    <MenuItem value="null">
                      <Typography color="textPrimary">None</Typography>
                    </MenuItem>
                    {roles.map((role) => (
                      <MenuItem
                        key={role.RoleID}
                        value={role.RoleID}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          {role.RoleName === 'AppAdmin' && (
                            <ManageAccounts sx={{ color: '#673ab7', marginRight: 1 }} />
                          )}
                          {role.RoleName === 'User' && <Group sx={{ color: '#4caf50', marginRight: 1 }} />}
                          {role.RoleName === 'CompanyAdmin' && (
                            <Business sx={{ color: '#ff9800', marginRight: 1 }} />
                          )}
                          {role.RoleName === 'ConsultingAdmin' && (
                            <Business sx={{ color: '#f44336', marginRight: 1 }} />
                          )}
                          {role.RoleName === 'SurveyAssigner' && (
                            <AssignmentTurnedIn sx={{ color: '#9c27b0', marginRight: 1 }} />
                          )}
                          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {role.RoleName}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>

    <AlertSnackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={() => setSnackbarOpen(false)}
      severity={alertSeverity}
      message={snackbarMessage}
      />
      <Box mt={2}>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  </>
);
};

export default ManageUsers;


