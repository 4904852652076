const dataURLtoBlob = (dataUrl: string) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  const u8arr = new Uint8Array(bstr.length);

  for (let i = 0; i < bstr.length; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  return new Blob([u8arr], { type: mime });
};

 export const dataURLtoFile = (dataurl: string, filename: string) => {
  const blob = dataURLtoBlob(dataurl);
  return new File([blob], filename, { type: blob.type });
};