import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Box,
  CardHeader,
  CardActions,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Pagination,
  styled,
  Grid,
  useTheme,
  TextField,
  CircularProgress,
  useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import NavigateBeforeTwoToneIcon from '@mui/icons-material/NavigateBeforeTwoTone';
import NavigateNextTwoToneIcon from '@mui/icons-material/NavigateNextTwoTone';
import ConfirmDialog from 'src/components/confirm-dailog';
import { Question, Response } from 'src/services/apiService/response-models';
import EvalContext from '../EvalContext';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
  `
);

const BoxWrapper = styled(Box)(
  ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
  `
);

const ButtonWrapper = styled(Button)(
  ({ theme }) => `
      color: ${theme.colors.primary.main};
      background: ${theme.colors.alpha.white[70]};
      font-weight: normal;
      
      &:hover {
        color: ${theme.colors.alpha.black[100]};
        background: ${theme.colors.alpha.white[100]};
      }
  `
);

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
  `
);

function QuestionPager(props: {
  questions: Question[];
  onFinishEval: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const context = useContext(EvalContext);
  const [answer, setAnswer] = useState<Response | null>(null);
  const [comment, setComment] = useState<string>('');
  const [tempComment, setTempComment] = useState<string>(''); 
  const [hasComment, setHasComment] = useState<boolean>(false);
  const [showComment, setShowComment] = useState<boolean>(false);

  const question = props.questions[context.activeIndex];
  const [isEvalFinishOpen, setIsEvalFinishOpen] = useState(false);

  const onAnswerSelect = (e: Response | null) => {
    if (e) {
      setAnswer(e ? { ...e } : null);
      if (question.iscomment) {
        setShowComment(true); 
      } else {
        context.onAnswerSubmit(question.questionID, { ...e }, null); 
      }
    } else {
      context.onAnswerClear(question.questionID); 
      setAnswer(null);
      setComment(''); 
      setTempComment(''); 
      setShowComment(false); 
    }
  };
   

  const moveTo = (step: number) => {
    const newIndex = context.activeIndex + step;
    if (newIndex >= 0 && newIndex < props.questions.length) {
      context.setActiveIndex(newIndex);
    }
  };

  const onCommentBlur = () => {
    setComment(tempComment);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = e.target.value;
    setTempComment(inputText);
    setHasComment(!!inputText);
  };

  
  const handleCommentSubmit = () => {
  
    if (answer) {
      context.onAnswerSubmit(question.questionID, { ...answer }, tempComment);
    }
    setComment(tempComment); 
    setTempComment(''); 
    setShowComment(false); 
  };
  

  useEffect(() => {
    const match = context.answers.find(
      (o) => o.questionID === question.questionID
    );
    if (match) {
      const resMatch = question.responses.find(
        (o) => o.responseID === match.responseID
      );
      if (resMatch) {
        setAnswer({ ...resMatch });
        setTempComment(match.comment || ''); 
        setHasComment(!!(match.comment)); 
        setShowComment(!!(match.comment)); 
      }
    } else {
      setTempComment(''); 
      setHasComment(false);
      setShowComment(false); 
    }
  }, [context.activeIndex]);

  return (
    <>
      <Card>
        <CardHeader title={t('Question no. ' + (context.activeIndex + 1))} />
        <Divider />
        <Grid container>
          <Grid item xs={12} md={12} justifyContent={'center'}>
            <Card sx={{ p: { xs: 1, md: 4 }, py: { sx: 0, md: 2 } }}>
              <Typography
                variant="h3"
                sx={{
                  py: { xs: 0, md: 1 }
                }}
                component="h3"
              >
                {question.questionShortText}
              </Typography>
              <Typography variant="subtitle2">
                {question.questionText}
              </Typography>
              <Grid container spacing={0}>
                <Grid item xs={12} sx={{ pt: 2 }}>
                  <AnswerList
                    answers={question.responses || []}
                    onSelect={onAnswerSelect}
                    selectedResponseId={answer?.responseID}
                    comment={tempComment}
                    onCommentChange={handleCommentChange}
                    onCommentBlur={onCommentBlur}
                    onCommentSubmit={handleCommentSubmit}
                    hasComment={hasComment}
                    showComment={showComment} 
                  />
                </Grid>
                <Grid item xs={12} sx={{ pt: 2 }}>
                  <BoxWrapper
                    display="flex"
                    alignItems="stretch"
                    justifyContent="space-around"
                    p={1}
                  >
                    <Button
                      disabled={context.activeIndex === 0}
                      size="small"
                      color="primary"
                      variant="contained"
                      startIcon={<NavigateBeforeTwoToneIcon />}
                      onClick={() => moveTo(-1)}
                    >
                      {t('Prev')}
                    </Button>
                    <Button
                      disabled={
                        context.activeIndex >= props.questions.length - 1
                      }
                      size="small"
                      color="primary"
                      variant="contained"
                      endIcon={<NavigateNextTwoToneIcon />}
                      onClick={() => moveTo(1)}
                    >
                      {t('Next')}
                    </Button>
                  </BoxWrapper>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <CardActions
          disableSpacing
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Pagination
            variant="outlined"
            showFirstButton
            showLastButton
            page={context.activeIndex + 1}
            onChange={(e, v) => {
              context.setActiveIndex(v - 1);
              setAnswer(null);
              setTempComment('');
              setShowComment(false); 
            }}
            shape="rounded"
            count={props.questions.length}
            siblingCount={0}
            boundaryCount={2}
            color="primary"
          />
        </CardActions>
      </Card>
      {context.answers.length === props.questions.length && (
        <Box
          py={1}
          sx={{
            textAlign: 'center',
            background: `${theme.colors.alpha.black[5]}`
          }}
        >
          <Button
            fullWidth
            disabled={context.isSubmitting}
            startIcon={
              context.isSubmitting ? <CircularProgress size="1rem" /> : null
            }
            variant="text"
            color="success"
            onClick={() => setIsEvalFinishOpen(true)}
            sx={{
              backgroundColor: `${theme.colors.success.main}`,
              textTransform: 'uppercase',
              py: 1.5,
              color: `${theme.palette.getContrastText(
                theme.colors.success.dark
              )}`,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: `${theme.colors.success.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.success.dark
                )}`
              }
            }}
          >
            {t('Finish Evaluation')}
          </Button>
        </Box>
      )}
      <ConfirmDialog
        open={isEvalFinishOpen}
        message="Are you sure you want to submit evaluation?"
        cancelText="No"
        confirmText="Yes"
        onClose={() => setIsEvalFinishOpen(false)}
        onConfirm={props.onFinishEval}
      />
    </>
  );
}

function AnswerList(props: {
  answers: Response[];
  selectedResponseId?: number;
  onSelect: (e: Response) => void;
  comment: string;
  onCommentChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCommentBlur: () => void;
  onCommentSubmit: () => void;
  hasComment: boolean;
  showComment: boolean; 
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selected, setSelected] = useState<Response | null>(null);

  useEffect(() => {
    if (props.selectedResponseId) {
      const match = props.answers.find(
        (o) => o.responseID === props.selectedResponseId
      );
      setSelected({ ...match });
    } else {
      setSelected(null);
    }
  }, [props.answers, props.selectedResponseId]);

  const onSelect = (e: Response) => {
    if (selected && selected.responseID === e.responseID) {
      setSelected(null);
      props.onSelect(null);
    } else {
      setSelected(e);
      props.onSelect(e);
    }
  };

  return (
    <Card
      sx={{
        height: '100%',
        pt: { xs: 0, md: 2 }
      }}
    >
      <CardHeader title={t('Select Option')} />
      <Divider />
      {props.answers.map((o, i) => (
        <React.Fragment key={o.responseID}>
          <List disablePadding>
            <ListItem
              onClick={() => onSelect(o)}
              selected={selected?.responseID === o.responseID}
              button
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    width: 38,
                    height: 38,
                    background: `${
                      selected?.responseID === o.responseID
                        ? theme.colors.success.main
                        : theme.colors.info.main
                    }`,
                    color: `${
                      selected?.responseID === o.responseID
                        ? theme.palette.success.contrastText
                        : theme.palette.info.contrastText
                    }`
                  }}
                >
                  {i + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  variant: mobile ? 'subtitle2' : 'h5',
                  color: `${theme.colors.alpha.black[100]}`
                }}
                primary={o.responseText}
              />
              {selected?.responseID === o.responseID && (
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: theme.colors.success.main,
                    color: theme.palette.success.contrastText,
                    marginLeft: 'auto'
                  }}
                >
                  <CheckTwoToneIcon />
                </Avatar>
              )}
            </ListItem>
            {selected?.responseID === o.responseID && props.showComment && (
              <Box sx={{ p: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('Do you want to describe in detail?')}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  variant="outlined"
                  value={props.comment}
                  onChange={props.onCommentChange}
                  onBlur={props.onCommentBlur}
                  inputProps={{ maxLength: 500 }}
                  helperText={`${props.comment.length}/500`}
                />
                {props.hasComment && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onCommentSubmit}
                    sx={{ mt: 1 }}
                  >
                    {t('Submit Comment')}
                  </Button>
                )}
              </Box>
            )}
            <Divider />
          </List>
        </React.Fragment>
      ))}
    </Card>
  );
}

export default QuestionPager;
