import React from 'react';
import { CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: theme.spacing(2),
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)', // Gradient background
    borderRadius: '10px',
    boxShadow: theme.shadows[5], 
  },
  spinner: {
    marginBottom: theme.spacing(2),
    color: '#007bff', 
  },
  text: {
    color: '#333', 
    fontWeight: 600,
  },
}));

const AuthenticatingLoader: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.spinner} size={60} thickness={5} /> {/* Larger spinner */}
      <Typography variant="h6" className={classes.text}>
        Authenticating...
      </Typography>
    </div>
  );
};

export default AuthenticatingLoader;
