import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, styled, useMediaQuery, useTheme } from '@mui/material';

const PageTitle = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(4)};
`
);
const PageTitleXS = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(2)};
`
);

interface PageTitleWrapperProps {
  children?: ReactNode;
}

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  if (mobile) {
    return (
      <PageTitleXS className="MuiPageTitle-wrapper">
        {/*      <Container maxWidth="lg">{children}</Container>*/}
        {children}
      </PageTitleXS>
    );
  }
  return (
    <PageTitle className="MuiPageTitle-wrapper">
      {/*      <Container maxWidth="lg">{children}</Container>*/}
      {children}
    </PageTitle>
  );
};

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageTitleWrapper;
