import React from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

interface AlertSnackbarProps {
  open: boolean;
  onClose: () => void;
  severity: AlertColor;
  message: string;
  autoHideDuration: number;
}

const AlertSnackbar: React.FC<AlertSnackbarProps> = ({ open, onClose, severity, message,autoHideDuration }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery('(min-width: 1280px)');
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const tablet = useMediaQuery('(min-width: 768px)');

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ 
        top: isLargeScreen ? '64px' : mobile ? '90px' : '120px',
        marginTop: isLargeScreen ? '60px' : tablet ? '60px' : '0',
      }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
