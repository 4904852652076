import { useEffect, useState } from 'react';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import SidebarContent from './SidebarContent';
import TopBarContent from './TopBarContent';

import Scrollbar from 'src/ui-bloom/components/Scrollbar';

import {
  Box,
  Divider,
  Drawer,
  IconButton,
  styled,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'src/store';
import getSettingTabs, { SettingTab } from './setting-tabs';
import useAuth from 'src/ui-bloom/hooks/useAuth';
import GeneralContent from './GeneralContent';

const RootWrapper = styled(Box)(
  ({ theme }) => `
       height: calc(100vh - ${theme.header.height});
       display: flex;
`
);

const Sidebar = styled(Box)(
  ({ theme }) => `
        width: 300px;
        background: ${theme.colors.alpha.white[100]};
        border-right: ${theme.colors.alpha.black[10]} solid 1px;
`
);

const ChatWindow = styled(Box)(
  () => `
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
`
);

const ChatTopBar = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
        border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
        padding: ${theme.spacing(2)};
        align-items: center;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  background: ${theme.colors.alpha.white[100]};
`
);

const DrawerWrapperMobile = styled(Drawer)(
  () => `
    width: 340px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
  }
`
);

function ApplicationSetting() {
  const theme = useTheme();
  const user = useAuth();

  const [searchParams] = useSearchParams();
  const { t }: { t: any } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const tab = searchParams.get('tab');
  const tabs = getSettingTabs(user.user);
  const [activeTab, setActiveTab] = useState<SettingTab>(
    tabs.find((o) => o.name == tab) || tabs[0]
  );

  const loadCardApps = async () => {
    setIsLoading(true);
    try {
      // const res = await getCardApps();
      //setCardApps(res.data);
    } catch (ex) {
      // setCardApps(null);
      console.error(ex);
      enqueueSnackbar(t('Failed to load card apps'), { variant: 'error' });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    loadCardApps();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onNavChange = (e: SettingTab) => {
    setActiveTab(e);
  };

  return (
    <>
      <RootWrapper className="Mui-FixedWrapper">
        <DrawerWrapperMobile
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <Scrollbar>
            <SidebarContent
              user={user.user}
              selectedTab={activeTab}
              onChange={onNavChange}
            />
          </Scrollbar>
        </DrawerWrapperMobile>
        <Sidebar
          sx={{
            display: { xs: 'none', lg: 'inline-block' }
          }}
        >
          <Scrollbar>
            <SidebarContent
              user={user.user}
              selectedTab={activeTab}
              onChange={onNavChange}
            />
          </Scrollbar>
        </Sidebar>
        <ChatWindow>
          <ChatTopBar
            sx={{
              display: { xs: 'flex', lg: 'inline-block' }
            }}
          >
            <IconButtonToggle
              sx={{
                display: { lg: 'none', xs: 'flex' },
                mr: 2
              }}
              color="primary"
              onClick={handleDrawerToggle}
              size="small"
            >
              <MenuTwoToneIcon />
            </IconButtonToggle>
            <TopBarContent selectedTab={activeTab} onSave={() => {}} />
          </ChatTopBar>
          <Box flex={1} sx={{ background: '#F8F8F8' }}>
            <Scrollbar>
              {activeTab?.name == 'general' && <GeneralContent />}
            </Scrollbar>
          </Box>
          <Divider />
        </ChatWindow>
      </RootWrapper>
    </>
  );
}

export default ApplicationSetting;
