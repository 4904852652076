import { Dictionary } from 'lodash';

export interface CompanyMain {
  id: number;
}
export const defaultCompany: CompanyMain = {
  id: 1
}
export enum AllConstant {
  DEFAULT_TEAM_NAME = 'Default team'
}
export interface SurveyGroup {
  surveyProductID: number;
  surveyProductName: string;
  surveyProductCategory: string;
  surveyProductDesc: string;
  surveyProductIsOpen?: boolean;
  surveys: Survey[];
}

export interface Survey {
  surveyID: number;
  surveyProductID: number;
  surveyCategory: string;
  surveyName: string;
  surveyDesc: string;
  surveyLastUpdated?: Date;
  surveyStartDate?: Date;
  surveyEndDate?: Date;
  surveySalesYearDate?: Date;
  surveyIsLockedForSeason?: boolean;
  surveyGroup1: string;

  surveyGroup2: string;
  surveyGroup3: string;

  questions: Question[];
  surveyGroup: SurveyGroup;
  status: string;

}


export interface Question {
  questionID: number;
  questionSurveyID: number;
  questionText: string;
  questionShortText: string;
  questionGroupText: string;
  questionSort?: number;
  questionIsMultiMark?: boolean;
  questionIsScale?: boolean;
  questionIsTopBox?: boolean;
  questionIsSkipQuestion?: boolean;
  questionSkipToQuestionID?: number;
  questionGroup1Text: string;
  questionGroup2Text: string;
  questionGroup3Text: string;
  questionGroup4Text: string;
  questionGroup5Text: string;
  responses: Response[];
  iscomment?: boolean; 
  CommentText: string; 
}


export interface Response {
  responseID: number;
  responseQuestionID: number;
  responseText: string;
  responseWeightage?: number;
  responseSort?: number;
  responseIsDefaultChoice?: boolean;
  responseIsCompulsoryChoice?: boolean;
  responseIsTopBoxChoice?: boolean;
  responseIsBottomBoxChoice?: boolean;
  responseIsAverageChoice?: boolean;
  responseIsSkipChoice?: boolean;
  responseGroup1: string;
  responseGroup2: string;
  responseGroup3: string;
  responseGroup4: string;
  responseGroup5: string;
}

export interface Tracker {
  trackerID: number;
  userID: number;
  surveyID: number;
  lastAnswerQuestionID: number;
  isCompleted: number;
  completionDate?: Date;
  lastUpdatedDate: Date;
}

export interface FactEval {
  evalID: number;
  trackerID: number;
  userID: number;
  surveyID: number;
  questionID: number;
  responseID: number;
  questionStartDisplayDataTime?: Date;
  questionResponceSubmitedDateTime: Date;
  recordResponceType: string;
  backendRecordDataTime: Date;
  comment: string;
}

export interface ActiveTracker {
  tracker: Tracker;
  factEvals: FactEval[];
}
export interface UserContext {
  userId: any;
  userID: number;
  UserCompanyId: number;
  email: string;
  fullname: string;
  isAdmin: boolean;
  isUser: boolean;
  userRoles: { roleID: number, roleName: string }[];
}

export interface UsersApproval {
  surveyProductName: any;
  surveyName: any;
  ApprovalID: number;
  UserID: number;
  name: string;
  UserEmail: string;
  RoleID: number;
  SurveyID: number;
  ApprovalStatus: string;
  IsApproved:number,
  ApprovalType: string;
  SurveyProductID: number;
  UserRoles : UserRoles;
  CompanyID: number;
  ConsultantID: number;
  surveys: Survey[];

}

export interface SurveyStatusResponse {
  success: boolean;
  status: string;
}

export interface UserRoles {
  RoleID: number;
  RoleName: string;
  Description:string;
}

export interface Companies {
            CompanyID: number;
            CompanyName: string;
            CompanyType: string;
            CompanyIndustry: string;
            CompanyHeadquarters: string;
            CompanyFoundedYear: number;
            CompanyRevenue: number;
            CompanyNumberOfEmployees: number;
            CompanyMarketSegment: string;
            CompanyParentCompanyID: number;
            CompanyGlobalPresence: string;
            CompanyWebsiteURL: string;
            CompanyContactInfo: string;
            CompanyDescription: string;
            CompanyIsConsultingCompany: boolean;
            ConsultingCompanyID: number;
}

export interface User {
  data: any;
  success: any;
  UserID : number
  AuthId : string
  UserFirstName : string
  UserLastName : string
  UserEmail : string
  UserPhoneNumber : string
  UserAge : number
  UserGender : string
  UserEducationLevel : string
  UserOccupation : string
  UserIncomeRange : string
  UserCountry : string
  UserState : string
  UserCity : string
  UserLinkedInProfileLink : string
  UserFacebookProfileLink : string
  UserInstagramProfileLink : string
  UserTwitterProfileLink : string
  UserCurrentCompanyLink : string
  UserPastCompaniesLink : string
  UserCompanyID : number
  UserJobTitle : string
  UserCompanyName : string
}


export interface BuildSurvey {
  BuildSurveyID: number; 
  SurveyProductName: string; 
  SurveyProductCategory: string; 
  AdditionalContext: string; 
  SurveyName: string; 
  QuestionNum: number; 
  SurveyProductID: number; 
  Status: string;
  CreatedDate: Date;
  CreatedBy: string; 
  UpdatedDate: Date; 
  UpdatedBy: string;
}