import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  IconButton,
  Box,
  Divider,
  Tooltip,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Chip,
  styled,
  Skeleton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { BuildSurvey } from 'src/services/apiService/response-models';
import { deleteBuildSurvey, getRequestSurveys, updateSurveyStatus } from 'src/services/apiService';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import AlertSnackbar from 'src/components/AlertSnackbar';
import WarnConfirm from 'src/components/warn-confirm';
import moment from 'moment';

const getStatusColor = (status: string) => {
  switch (status) {
    case 'Requested':
      return 'default';     
    case 'Added':
      return 'info'; 
    case 'Started':
      return 'primary';  
    case 'InProgress':
      return 'warning';   
    case 'Completed':
      return 'success'; 
    case 'Reject':
      return 'error';   
    case 'Failed':
      return 'error';     
    default:
      return 'default'; 
  }
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderBottom: `1px solid ${theme.palette.divider}`,
  wordWrap: 'break-word',
}));

const RequestedSurveysStatus = () => {
  const { t } = useTranslation();
  const [surveys, setSurveys] = useState<BuildSurvey[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('All');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [statusDetails, setStatusDetails] = useState<{ surveyId: number; surveyName: string; status: string } | null>(null);
  const [deleteDetails, setDeleteDetails] = useState<{ surveyId: number; surveyName: string, status: string } | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchSurveys = async () => {
    setLoading(true);
    try {
      const response = await getRequestSurveys();
      setSurveys(response.data);
    } catch (error) {
      setError(t('Error fetching surveys'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveys();
  }, []);

  const filteredSurveys = surveys.filter(survey =>
    (survey.SurveyProductName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      survey.SurveyName.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (statusFilter === 'All' || survey.Status === statusFilter)
  );

  useEffect(() => {
    setPage(0);
  }, [statusFilter, searchTerm]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    setStatusFilter(event.target.value as string);
  };

  const handleDelete = (BuildSurveyID: number, SurveyProductName: string, SurveyName: string, Status: string) => {
    setDeleteDetails({
      surveyId: BuildSurveyID,
      surveyName: `${SurveyProductName} - ${SurveyName}`,
      status: Status,
    });
    setOpenDeleteDialog(true);
  };

  const handleStatusChange = (BuildSurveyID: number, SurveyName: string, Status: string) => {
    setStatusDetails({
      surveyId: BuildSurveyID,
      surveyName: SurveyName,
      status: Status,
    });
    setOpenStatusDialog(true);
  };

  const confirmDeleteSurvey = async () => {
    try {
      setLoading(true);
      const response = await deleteBuildSurvey(deleteDetails?.surveyId, deleteDetails?.surveyName, deleteDetails?.status);

      if (response.success) {
        if (response.message === 'Survey deleted successfully') {
          setAlertSeverity('success');
          setSnackbarMessage(t('Survey deleted successfully'));
        } else if (response.message === 'Survey cannot be deleted in its current state') {
          setAlertSeverity('info');
          setSnackbarMessage(t('Survey cannot be deleted in its current state'));
        } else if (response.message === 'BuildSurvey record deleted successfully') {
          setAlertSeverity('success');
          setSnackbarMessage(t('Survey deleted successfully'));
        } else {
          setAlertSeverity('error');
          setSnackbarMessage(t('Failed to delete survey'));
        }
        fetchSurveys();
      } else {
        setAlertSeverity('error');
        setSnackbarMessage(t('Failed to delete survey'));
      }
    } catch (error) {
      console.error('Error initiating survey deletion process:', error);
      setAlertSeverity('error');
      setSnackbarMessage(error.message || t('Error initiating survey deletion process.'));
    } finally {
      setSnackbarOpen(true);
      setLoading(false);
      setOpenDeleteDialog(false);
    }
  };

  const confirmStatusChange = async () => {
    try {
      setLoading(true);
      const response = await updateSurveyStatus(statusDetails?.surveyId, statusDetails?.status);

      if (response.success) {
        setAlertSeverity('success');
        setSnackbarMessage(t('Survey status updated successfully'));
        fetchSurveys();
      } else {
        setAlertSeverity('error');
        setSnackbarMessage(t('Failed to update survey status'));
      }
    } catch (error) {
      setAlertSeverity('error');
      setSnackbarMessage(t('Error initiating survey status update process'));
    } finally {
      setSnackbarOpen(true);
      setLoading(false);
      setOpenStatusDialog(false);
    }
  };

  const formatDate = (isoDateString) => {
    return moment(isoDateString).format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <Grid container justifyContent="center" sx={{ py: 4, overflow: 'auto' }}>
      <Grid item xs={11} sx={{ overflowX: 'auto' }}>
        <Card variant="outlined">
          <CardHeader
            title={<Typography variant={isSmallScreen ? 'h6' : 'h5'} color="primary">{t('All Requested Surveys')}</Typography>}
            action={
              <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} alignItems="center" sx={{ gap: 1 }}>
                <Box display="flex" alignItems="center" sx={{ mb: isSmallScreen ? 1 : 0 }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder={t('Search...')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: isSmallScreen ? '100%' : 200 }}
                  />
                  <Tooltip title={t('Search')} arrow>
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <FormControl sx={{ minWidth: 120, mb: isSmallScreen ? 1 : 0 }}>
                  <InputLabel>{t('Status')}</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    size="small"
                    label={t('Status')}
                  >
                    <MenuItem value="All">{t('All')}</MenuItem>
                    <MenuItem value="Requested">{t('Requested')}</MenuItem>
                    <MenuItem value="Added">{t('Added')}</MenuItem>
                    <MenuItem value="Started">{t('Started')}</MenuItem>
                    <MenuItem value="InProgress">{t('InProgress')}</MenuItem>
                    <MenuItem value="Completed">{t('Completed')}</MenuItem>
                    <MenuItem value="Rejected">{t('Rejected')}</MenuItem>
                    <MenuItem value="Failed">{t('Failed')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            }
          />
            <CardContent>
              <Divider />
              {loading ? (
              <Box>
                {[...Array(rowsPerPage)].map((_, index) => (
                  <Skeleton key={index} variant="rectangular" height={80} sx={{ mb: 1 }} />
                ))}
              </Box>
            ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : filteredSurveys.length === 0 ? (
                <Typography variant="h6" color="textSecondary">
                  {t('No surveys found.')}
                </Typography>
              ) : (
                <Box sx={{ overflowX: 'auto' }}>
                <Table size={isSmallScreen ? "small" : "medium"} sx={{ minWidth: 1200 }}> 
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>{t('Build Survey ID')}</StyledTableCell>
                        <StyledTableCell>{t('Survey Product Name')}</StyledTableCell>
                        <StyledTableCell>{t('Survey Name')}</StyledTableCell>
                        <StyledTableCell>{t('Additional Context')}</StyledTableCell>
                        <StyledTableCell>{t('Requested Date')}</StyledTableCell>
                        <StyledTableCell>{t('Status')}</StyledTableCell>
                        <StyledTableCell>{t('Actions')}</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredSurveys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(survey => (
                        <TableRow key={survey.BuildSurveyID}>
                          <StyledTableCell>{survey.BuildSurveyID}</StyledTableCell>
                          <StyledTableCell>{survey.SurveyProductName}</StyledTableCell>
                          <StyledTableCell>{survey.SurveyName}</StyledTableCell>
                          <StyledTableCell>
                          {survey.AdditionalContext ? (
                           <span style={{ color: theme.palette.text.primary }}>
                             {survey.AdditionalContext}
                           </span>
                          ) : (
                            <span style={{ color: theme.palette.text.disabled }}>
                               No Additional Context
                            </span>
                        )}
                         </StyledTableCell>
                          <StyledTableCell>{formatDate(survey.CreatedDate)}</StyledTableCell>
                          <StyledTableCell>
                            <Chip label={survey.Status} color={getStatusColor(survey.Status)} />
                          </StyledTableCell>
                          <TableCell>
                            { survey.Status === 'Requested' && (
                              <>
                                <IconButton color="success" onClick={() => handleStatusChange(survey.BuildSurveyID, survey.SurveyName, 'Added')}>
                                  <Typography variant="body2">{t('Add')}</Typography>
                                </IconButton>
                                <IconButton color="error" onClick={() => handleStatusChange(survey.BuildSurveyID, survey.SurveyName, 'Reject')}>
                                  <Typography variant="body2">{t('Reject')}</Typography>
                                </IconButton>
                              </>
                            )}
                            <IconButton color="error" onClick={() => handleDelete(survey.BuildSurveyID, survey.SurveyProductName, survey.SurveyName, survey.Status)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredSurveys.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </CardContent>

        </Card>
      </Grid>

      {deleteDetails && ( <WarnConfirm
        open={openDeleteDialog}
        message={`Are you sure you want to delete the survey ${deleteDetails?.surveyName}?`}
        okText="Delete"
        cancelText="Cancel"
        onConfirm={confirmDeleteSurvey}
        onClose={() => setOpenDeleteDialog(false)}
      />)}
      <AlertSnackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        severity={alertSeverity}
        message={snackbarMessage}
      />

      <WarnConfirm
        open={openStatusDialog}
        message={t('Are you sure you want to change the status of this survey?')}
        onConfirm={confirmStatusChange}
        onClose={() => setOpenStatusDialog(false)}
      />
    </Grid>
  );
};

export default RequestedSurveysStatus;




// import React, { useState, useEffect } from 'react';
// import { Grid, Card, CardHeader, CardContent, Typography, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TextField, IconButton, Box, Divider, Tooltip, useMediaQuery, useTheme, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent, Chip, styled, Skeleton } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { BuildSurvey } from 'src/services/apiService/response-models';
// import { deleteBuildSurvey, getRequestSurveys } from 'src/services/apiService';
// import SearchIcon from '@mui/icons-material/Search';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AlertSnackbar from 'src/components/AlertSnackbar';
// import WarnConfirm from 'src/components/warn-confirm';
// import moment from 'moment';

// const getStatusColor = (status: string) => {
//   switch (status) {
//     case 'Started':
//       return 'primary';
//     case 'InProgress':
//       return 'warning';
//     case 'Completed':
//       return 'success';
//     case 'Added':
//       return 'info';
//     case 'Added':
//       return 'error';  
//     default:
//       return 'default';
//   }
// };

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   color: theme.palette.text.primary,
//   borderBottom: `1px solid ${theme.palette.divider}`,
//   wordWrap: 'break-word'
// }));

// const RequestedSurveysStatus = () => {
//   const { t } = useTranslation();
//   const [surveys, setSurveys] = useState<BuildSurvey[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [statusFilter, setStatusFilter] = useState<string>('All');
//   const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info'>('success');
//   const [snackbarMessage, setSnackbarMessage] = useState<string>('');
//   const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [deleteDetails, setDeleteDetails] = useState<{ surveyId: number, surveyName: string, status: string } | null>(null);
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

//   const fetchSurveys = async () => {
//     setLoading(true);
//     try {
//       const response = await getRequestSurveys();
//       setSurveys(response.data);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchSurveys();
//   }, []);

//   const filteredSurveys = surveys.filter(survey =>
//     (survey.SurveyProductName.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     survey.SurveyName.toLowerCase().includes(searchTerm.toLowerCase())) &&
//     (statusFilter === 'All' || survey.Status === statusFilter)
//   );

//   useEffect(() => {
//     setPage(0);
//   }, [statusFilter, searchTerm]);

//   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
//     setStatusFilter(event.target.value as string);
//   };

//   const handleDelete = (BuildSurveyID: number, SurveyProductName: string, SurveyName: string, Status: string) => {
//     setDeleteDetails({
//       surveyId: BuildSurveyID,
//       surveyName: `${SurveyProductName} - ${SurveyName}`,
//       status: Status,
//     });
//     setOpenDeleteDialog(true);
//   };

//   const confirmDeleteSurvey = async () => {
//     try {
//       setLoading(true);
//       const response = await deleteBuildSurvey(deleteDetails?.surveyId, deleteDetails?.surveyName, deleteDetails?.status);

//       if (response.success) {
//         if (response.message === 'Survey deleted successfully') {
//           setAlertSeverity('success');
//           setSnackbarMessage(t('Survey deleted successfully'));
//         } else if (response.message === 'Survey cannot be deleted in its current state') {
//           setAlertSeverity('info');
//           setSnackbarMessage(t('Survey cannot be deleted in its current state'));
//         } else if (response.message === 'BuildSurvey record deleted successfully') {
//           setAlertSeverity('success');
//           setSnackbarMessage(t('Survey deleted successfully'));
//         } else {
//           setAlertSeverity('error');
//           setSnackbarMessage(t('Failed to delete survey'));
//         }
//         fetchSurveys();
//       } else {
//         setAlertSeverity('error');
//         setSnackbarMessage(t('Failed to delete survey'));
//       }
//     } catch (error) {
//       console.error('Error initiating survey deletion process:', error);
//       setAlertSeverity('error');
//       setSnackbarMessage(error.message || t('Error initiating survey deletion process.'));
//     } finally {
//       setSnackbarOpen(true);
//       setLoading(false);
//       setOpenDeleteDialog(false);
//     }
//   };

//   const formatDate = (isoDateString) => {
//     return moment(isoDateString).format('YYYY-MM-DD HH:mm:ss');
//   };
//   return (
//     <Grid container justifyContent="center" sx={{ py: 4, overflow: 'auto' }}>
//       <Grid item xs={11} sx={{ overflowX: 'auto' }}>
//         <Card variant="outlined">
//           <CardHeader
//             title={<Typography variant={isSmallScreen ? "h6" : "h5"} color="primary">{t('All Requested Surveys')}</Typography>}
//             action={
//               <Box 
//                 display="flex" 
//                 flexDirection={isSmallScreen ? 'column' : 'row'} 
//                 alignItems="center" 
//                 sx={{ gap: 1 }}
//               >
//                 <Box display="flex" alignItems="center" sx={{ mb: isSmallScreen ? 1 : 0 }}>
//                   <TextField
//                     variant="outlined"
//                     size="small"
//                     placeholder={t('Search...')}
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     sx={{ width: isSmallScreen ? '100%' : 200 }}
//                   />
//                   <Tooltip title={t('Search')} arrow>
//                     <IconButton color="primary">
//                       <SearchIcon />
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//                 <FormControl sx={{ minWidth: 120, mb: isSmallScreen ? 1 : 0 }}>
//                   <InputLabel>{t('Status')}</InputLabel>
//                   <Select
//                     value={statusFilter}
//                     onChange={handleStatusFilterChange}
//                     size="small"
//                     label={t('Status')}
//                   >
//                     <MenuItem value="All">{t('All')}</MenuItem>
//                     <MenuItem value="Added">{t('Added')}</MenuItem>
//                     <MenuItem value="Started">{t('Started')}</MenuItem>
//                     <MenuItem value="InProgress">{t('InProgress')}</MenuItem>
//                     <MenuItem value="Completed">{t('Completed')}</MenuItem>
//                     <MenuItem value="Failed">{t('Failed')}</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Box>
//             }
//           />
//           <Divider />
//           <CardContent>
//             {loading ? (
//               <Box>
//                 {[...Array(rowsPerPage)].map((_, index) => (
//                   <Skeleton key={index} variant="rectangular" height={80} sx={{ mb: 1 }} />
//                 ))}
//               </Box>
//             ) : error ? (
//               <Typography color="error" variant="body2" align="center">{error}</Typography>
//             ) : (
//               <Box sx={{ overflowX: 'auto' }}>
//                 <Table size={isSmallScreen ? "small" : "medium"} sx={{ minWidth: 1200 }}> {/* Ensure minWidth for horizontal scrolling */}
//                   <TableHead>
//                     <TableRow>
//                       <StyledTableCell>{t('Build Survey ID')}</StyledTableCell>
//                       <StyledTableCell>{t('Survey Product Name')}</StyledTableCell>
//                       <StyledTableCell>{t('Survey Product Category')}</StyledTableCell>
//                       <StyledTableCell>{t('Survey Name')}</StyledTableCell>
//                       <StyledTableCell>{t('Additional Context')}</StyledTableCell>
//                       <StyledTableCell>{t('Status')}</StyledTableCell>
//                       <StyledTableCell>{t('Created Date')}</StyledTableCell>
//                       <StyledTableCell>{t('Updated Date')}</StyledTableCell>
//                       <StyledTableCell>{t('Actions')}</StyledTableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {filteredSurveys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(survey => (
//                       <TableRow key={survey.BuildSurveyID}>
//                         <StyledTableCell>{survey.BuildSurveyID}</StyledTableCell>
//                         <StyledTableCell>{survey.SurveyProductName}</StyledTableCell>
//                         <StyledTableCell>{survey.SurveyProductCategory}</StyledTableCell>
//                         <StyledTableCell>{survey.SurveyName}</StyledTableCell>
//                         <StyledTableCell>
//                         {survey.AdditionalContext ? (
//                            <span style={{ color: theme.palette.text.primary }}>
//                              {survey.AdditionalContext}
//                            </span>
//                           ) : (
//                             <span style={{ color: theme.palette.text.disabled }}>
//                                No Additional Context
//                             </span>
//                         )}
//                         </StyledTableCell>
//                         <StyledTableCell>
//                           <Chip label={survey.Status} color={getStatusColor(survey.Status)} />
//                         </StyledTableCell>
//                         <StyledTableCell>{formatDate(survey.CreatedDate)}</StyledTableCell>
//                         <StyledTableCell>
//                           {survey.UpdatedDate ? (
//                             <span style={{ color: theme.palette.text.primary }}>
//                               {formatDate(survey.UpdatedDate)}
//                             </span>
//                           ) : (
//                             <span style={{ color: theme.palette.text.disabled }}>
//                               Not Updated
//                             </span>
//                           )}
//                         </StyledTableCell>
//                         <StyledTableCell>
//                           <Tooltip title={t('Delete')} arrow>
//                             <IconButton color="error" onClick={() => handleDelete(survey.BuildSurveyID, survey.SurveyProductName, survey.SurveyName, survey.Status)}>
//                               <DeleteIcon />
//                             </IconButton>
//                           </Tooltip>
//                         </StyledTableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//                 <TablePagination
//                   rowsPerPageOptions={[10, 25, 50]}
//                   component="div"
//                   count={filteredSurveys.length}
//                   rowsPerPage={rowsPerPage}
//                   page={page}
//                   onPageChange={handleChangePage}
//                   onRowsPerPageChange={handleChangeRowsPerPage}
//                 />
//               </Box>
//             )}
//           </CardContent>
//         </Card>
//       </Grid>

//       {deleteDetails && ( <WarnConfirm
//   open={openDeleteDialog}
//   message={`Are you sure you want to delete the survey ${deleteDetails?.surveyName}?`}
//   okText="Delete"
//   cancelText="Cancel"
//   onConfirm={confirmDeleteSurvey}
//   onClose={() => setOpenDeleteDialog(false)}
// />)}
//       <AlertSnackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//         severity={alertSeverity}
//         message={snackbarMessage}
//       />
//     </Grid>
//   );
// };

// export default RequestedSurveysStatus;
