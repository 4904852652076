import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

function PageHeader() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Typography
        align="center"
        variant={mobile ? 'h5' : 'h3'}
        component={mobile ? 'h5' : 'h5'}
        sx={{
          mb: 1
        }}
      >
        {t('Evaluation and Assessment')}
      </Typography>
    </>
  );
}

export default PageHeader;
