import { FC, useState, useRef, ChangeEvent, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Chip,
  Typography,
  Card,
  Box,
  IconButton,
  Avatar,
  Button,
  MenuItem,
  Menu,
  useTheme,
  Divider,
  lighten,
  styled,
  TablePagination,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { Survey } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { useNavigate } from 'react-router-dom';
import Label from 'src/ui-bloom/components/Label';
import readFavoriteSurveyIds from 'src/utility/readFavoriteSurveyIds';
import { useDispatch } from 'react-redux';
import { RootState, useSelector } from 'src/store';
import SurveyContext from './SurveyContext';
import { getTrackers, resetActiveTrackerAndSurvey } from 'src/services/apiService';
import { useQuery } from 'react-query';
import AlertSnackbar from 'src/components/AlertSnackbar';
import { setActiveTracker } from 'src/store/slices/app';
import ConfirmDialog from 'src/components/confirm-dailog';

interface ResultsProps {
  surveys: Survey[];
}

const applyPagination = (
  surveys: Survey[],
  page: number,
  limit: number
): Survey[] => {
  return surveys.slice(page * limit, page * limit + limit);
};

const sorts = [
  {
    value: 'latest',
    text: 'Most recent'
  },
  {
    value: 'name',
    text: 'Name'
  },
  {
    value: 'fav',
    text: 'Favorite'
  }
];

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      height: ${theme.spacing(12)};
      width: ${theme.spacing(12)};
`
);

const DotError = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    background: ${theme.colors.error.main};
    width: ${theme.spacing(1.1)};
    height: ${theme.spacing(1.1)};
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);

const DotInfo = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    background: ${theme.colors.error.main};
    width: ${theme.spacing(1.1)};
    height: ${theme.spacing(1.1)};
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
     @keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
`
);
const DotSuccess = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    background: ${theme.colors.success.main};
    width: ${theme.spacing(1.1)};
    height: ${theme.spacing(1.1)};
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
    
`
);
const DotWarn = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    background: ${theme.colors.warning.main};
    width: ${theme.spacing(1.1)};
    height: ${theme.spacing(1.1)};
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);
const DotInProgress = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    background: ${theme.colors.primary.main};
    width: ${theme.spacing(1.1)};
    height: ${theme.spacing(1.1)};
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);
const ChipWrapper = styled(Chip)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      margin: ${theme.spacing(0.5)};
      padding: ${theme.spacing(1)};
      height: 28px;
      line-height: 28px;
      font-weight: bold;
`
);

const StyledButton = styled(Button)(
  ({ theme }) => `
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      border-radius: ${theme.general.borderRadius};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      min-height: ${theme.spacing(3)};
      margin-right: ${theme.spacing(0.5)};
      
      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
`
);


const FavIconButtonOn = styled(IconButton)(
  ({ theme }) => `
     color: ${theme.colors.error.main};

     &:hover {
      background: ${theme.colors.error.lighter};
    }
`
);
const FavIconButtonOff = styled(IconButton)(
  ({ theme }) => `

     &:hover {
      background: ${theme.colors.error.lighter};
    }
`
);
const IconButtonSuccess = styled(IconButton)(
  ({ theme }) => `
      background: ${theme.colors.success.lighter};
      color: ${theme.colors.success.main};
      width: ${theme.spacing(10)};
      height: ${theme.spacing(10)};
      margin: ${theme.spacing(1.5)};

      &:hover {
          background: ${lighten(theme.colors.success.lighter, 0.4)};
      }
`
);

const IconButtonError = styled(IconButton)(
  ({ theme }) => `
      background: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(10)};
      height: ${theme.spacing(10)};
      margin: ${theme.spacing(1.5)};

      &:hover {
          background: ${lighten(theme.colors.error.lighter, 0.4)};
      }
`
);

const Results: FC<ResultsProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(SurveyContext);
  const { userCtx } = useSelector((state: RootState) => state.app);

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const { t }: { t: any } = useTranslation();

  const paginatedItems = applyPagination(context.filteredItems, page, limit);

  const actionRef = useRef<any>(null);
  const [openSort, setOpenMenuSort] = useState<boolean>(false);
  const [sort, setSort] = useState<string>('Sort by...');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [trackerIdToDelete, setTrackerIdToDelete] = useState<number | null>(null);
  const { data: { data: myTrackers = [] } = {}, refetch } = useQuery(
    'trackers',
    getTrackers
  );

  // const { favoriteSurveyIds } = useSelector((state: RootState) => state.app);

  const onFavClick = (surveyId: number, isAdd: boolean) => {
    context.onAddToFavorite(surveyId, isAdd);
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const onViewDetails = (e: Survey) => {
    navigate(`/app/product-details/` + e.surveyID);
  };

  const handleResetSurvey = (trackerId: number) => {
    setTrackerIdToDelete(trackerId);
    setOpenConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteConfirmed = async () => {
    try {
      const response = await resetActiveTrackerAndSurvey(trackerIdToDelete);
      if (response.success === true) {
        setAlertSeverity('success');
        setSnackbarMessage('Reset Survey Successfully');
        dispatch(setActiveTracker({ tracker: null }));
        refetch();
      }
    } catch (error) {
      console.error('Error resetting the survey:', error);
      setAlertSeverity('error');
      setSnackbarMessage('Reset Survey Fail');
    }
    setSnackbarOpen(true);
    setOpenConfirmDelete(false);
  };


  const completionStatus = (e: Survey) => {
    const match = myTrackers.find((o) => e.surveyID == o.surveyID);
    if (!match) {
      return (
        <Label color="warning">
          <DotWarn />
          {t('Need To start')}
        </Label>
      );
    }
  
    if (match.isCompleted) {
      return (
        <Label color="success">
          <DotSuccess />
          {t('Complete')}
        </Label>
      );
    }
    if (!match.isCompleted) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Label color="error">
          <DotInfo sx={{ animation: 'pulse 0.5s infinite' }} />
          {t('In Progress')}
        </Label>
        <StyledButton
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleResetSurvey(match.trackerID)}
          className="MuiButton-primary"
        >
          {t('Reset Survey')}
        </StyledButton>
      </Box>
    );
  }
};
  return (
    <>
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography component="span" variant="subtitle1">
            {t('Showing')}
          </Typography>{' '}
          <b>{context.filteredItems.length}</b> {t('of')}{' '}
          <b>{context.totalCount}</b> <b>{t('total results')}</b>
        </Box>
        <Button
          size="small"
          variant="outlined"
          ref={actionRef}
          onClick={() => setOpenMenuSort(true)}
          endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
        >
          {sort}
        </Button>
        <Menu
          disableScrollLock
          anchorEl={actionRef.current}
          onClose={() => setOpenMenuSort(false)}
          open={openSort}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {sorts.map((_sort) => (
            <MenuItem
              key={_sort.value}
              onClick={() => {
                setSort(_sort.text);
                setOpenMenuSort(false);
                context.onSortChange(_sort.value);
              }}
            >
              {_sort.text}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Grid container spacing={4}>
        {/* <Grid item xs={12} md={6}>
          <Card
            sx={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              flexDirection: 'column',
              p: 3
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: `${theme.typography.pxToRem(23)}`
              }}
            >
              {t('Are these surveyGroup right for you?')}
            </Typography>
            <Box py={2}>
              <IconButtonSuccess>
                <ThumbUpTwoToneIcon fontSize="large" />
              </IconButtonSuccess>
              <IconButtonError>
                <ThumbDownTwoToneIcon fontSize="large" />
              </IconButtonError>
            </Box>
            <Typography
              variant="h4"
              fontWeight="normal"
              color="text.secondary"
              sx={{
                px: 2,
                lineHeight: 1.5
              }}
            >
              {t("We'll use your feedback to improve future recommendations")}
            </Typography>
          </Card>
        </Grid> */}
        {paginatedItems.map((item) => {
          return (
            <Grid key={item.surveyID} item xs={12} md={6}>
              <Card>
                <Box
                  p={3}
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <AvatarWrapper
                    src={cdnService.getImageByForCategoryName(
                      item.surveyGroup.surveyProductID.toString()
                    )}
                    variant="rounded"
                  >
                    <img
                      src={cdnService.noSurveyImage}
                      style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                      }}
                    />
                  </AvatarWrapper>
                  {/* <IconButton color="primary">
                    <MoreVertTwoToneIcon />
                  </IconButton> */}
                  {context.favoriteSurveyIds.includes(item.surveyID) ? (
                    <Tooltip
                      title="Click to remove from favorite"
                      placement="top"
                    >
                      <FavIconButtonOn
                        onClick={() => onFavClick(item.surveyID, false)}
                        size="small"
                      >
                        <FavoriteTwoToneIcon fontSize="small" />
                      </FavIconButtonOn>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Click to add to favorite" placement="top">
                      <FavIconButtonOn
                        onClick={() => onFavClick(item.surveyID, true)}
                        size="small"
                      >
                        <FavoriteBorderIcon fontSize="small" />
                      </FavIconButtonOn>
                    </Tooltip>
                  )}
                </Box>
                <Box px={3}>
                  <Typography
                    variant="h4"
                    sx={{
                      mb: 1
                    }}
                  >
                    {item.surveyName}
                  </Typography>
                  <Typography
                    sx={{
                      mb: 1
                    }}
                    variant="h6"
                    color="text.secondary"
                  >
                    {item.surveyGroup.surveyProductCategory} (
                    {item.surveyGroup.surveyProductName})
                  </Typography>
                  <Box mb={1} display={'flex'} justifyContent={'space-between'}>
                    {/* {userCtx.isAdmin && (
                      <>
                        {item.surveyGroup.surveyProductIsOpen ? (
                          <Label color="black">
                            <DotInfo />
                            {t('Open')}
                          </Label>
                        ) : (
                          <Label color="black">
                            <DotInfo />
                            {t('Not Open')}
                          </Label>
                        )}
                      </>
                    )} */}

                    {completionStatus(item)}
                  </Box>
                </Box>
                {/* <Box px={2} py={1}>
                  {['tag1', 'tag2'].map((value) => {
                    return (
                      <ChipWrapper
                        key={value}
                        color="secondary"
                        label={value}
                      />
                    );
                  })}
                </Box> */}

                <Divider />
                <Box px={3} py={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} textAlign={'right'}>
                      <Button
                        onClick={() => onViewDetails(item)}
                        size="small"
                        fullWidth
                        variant="outlined"
                      >
                        {t('View Assessment')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Card
            sx={{
              p: 2,
              mb: 10
            }}
          >
            <TablePagination
              component="div"
              count={context.filteredItems.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[20, 50, 100]}
            />
       <ConfirmDialog
      open={openConfirmDelete}
      onClose={handleCloseConfirmDelete}
      onConfirm={handleDeleteConfirmed}
      message="Are you sure you want to reset the survey?"
      confirmText="Reset"
      cancelText="Cancel"
    />
            <AlertSnackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={() => setSnackbarOpen(false)}
              severity={alertSeverity}
              message={snackbarMessage}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

Results.propTypes = {
  surveys: PropTypes.array.isRequired
};

Results.defaultProps = {
  surveys: []
};


export default Results;
