import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Stack,
  TextField,
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  Box,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { RootState, useSelector } from 'src/store';
import { createCompanyUser, createUser, deleteUserFromAuth0 } from 'src/services/apiService';
import AlertSnackbar from 'src/components/AlertSnackbar';

interface Companies {
  CompanyName: string;
}

interface AdminCompany {
  CompanyDomain: string;
}

const AddCompanyUsersByCompanyAdmin: React.FC = () => {
  const [companyUserEmail, setCompanyUserEmail] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [suggestedCompanies, setSuggestedCompanies] = useState<Companies[]>([]);
  const [isCompanyUserEmailValid, setIsCompanyUserEmailValid] = useState<boolean>(true);
  const [isCompanyUserEmailEmptyError, setIsCompanyUserEmailEmptyError] = useState<boolean>(false);
  const [isCompanyNameValid, setIsCompanyNameValid] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('CoeIndex@123');
  const [isCompanyNameEmptyError, setIsCompanyNameEmptyError] = useState<boolean>(false);
  const { t }: { t: any } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const { userCtx } = useSelector((state: RootState) => state.app);
  const userDomain = userCtx.email.split('@')[1];
  const companyUserEmailDomain = companyUserEmail.split('@')[1];
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClearCompanyUserEmail = () => {
    setCompanyUserEmail('');
    setIsCompanyUserEmailValid(true);
    setIsCompanyUserEmailEmptyError(false);
  };

  const handleClearCompanyName = () => {
    setCompanyName('');
    setIsCompanyNameValid(true);
    setIsCompanyNameEmptyError(false);
    setSuggestedCompanies([]);
  };

  // const handleSelectCompany = (company: Companies) => {
  //   setCompanyName(company.CompanyName);
  //   setSuggestedCompanies([]);
  // };

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleAddCompanyUser = async () => {
    if (!companyUserEmail || companyUserEmail.trim() === '') {
      setIsCompanyUserEmailEmptyError(true);
      return;
    }
  
    if (!validateEmail(companyUserEmail)) {
      setIsCompanyUserEmailValid(false);
      return;
    }
  
    if (!companyName) {
      setIsCompanyNameEmptyError(true);
      return;
    }
  
    if (companyName.trim() === '') {
      setIsCompanyNameValid(false);
      return;
    }
  
    const emailDomain = companyUserEmail.split('@')[1];
    if (emailDomain !== userDomain) {
      setSnackbarMessage('You can only add users with email addresses within your company domain');
      setAlertSeverity('error');
      setSnackbarOpen(true);
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const createUserResponse = await createUser(companyUserEmail);
      const newEmail = createUserResponse.data.email;
      const userPassword = createUserResponse.password;
  
      if (newEmail && createUserResponse.success) {
        await new Promise(resolve => setTimeout(resolve, 1500));
  
        let attempts = 0;
        const maxAttempts = 2;
        let success = false;
  
        while (attempts < maxAttempts && !success) {
          try {
            await createCompanyUser(newEmail, companyName,userPassword);
            success = true;
          } catch (error) {
            attempts++;
            if (attempts >= maxAttempts) {
              await deleteUserFromAuth0(createUserResponse.data.user_id);
              throw error;
            }
            await new Promise(resolve => setTimeout(resolve, 1500)); 
          }
        }
  
        if (success) {
          setSnackbarMessage('Company User created successfully. An email has been sent to the user for further instructions');
          setAlertSeverity('success');
          setCompanyUserEmail('');
          setCompanyName('');
        }
      }
    } catch (error) {
      console.error('Error adding user:', error);
      const errorMessage = error.response?.data?.error_description || error.response?.data?.message || 'Error adding user. Please try again.';
      setSnackbarMessage(errorMessage);
      setAlertSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setIsSubmitting(false);
    }
  };
  

  return (
    <Card sx={{ margin: 2, mt: 4, borderRadius: 2 }}>
      <CardContent>
        <Stack direction="column" spacing={2}>
          <TextField
            label={t('Add Company User')}
            fullWidth
            type="email"
            variant="outlined"
            value={companyUserEmail}
            onChange={(e) => {
              setCompanyUserEmail(e.target.value);
              setIsCompanyUserEmailValid(validateEmail(e.target.value));
              setIsCompanyUserEmailEmptyError(false);
            }}
            error={!isCompanyUserEmailValid || isCompanyUserEmailEmptyError}
            helperText={
              (!isCompanyUserEmailValid && 'Invalid email format') ||
              (isCompanyUserEmailEmptyError && 'Please enter an email')
            }
            sx={{ mr: 2 }}
            InputProps={{
              endAdornment: (
                <>
                  {companyUserEmail && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearCompanyUserEmail} edge="end">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
          <Autocomplete
            id="company-name-autocomplete"
            options={suggestedCompanies.map((company) => company.CompanyName)}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Company Name')}
                fullWidth
                variant="outlined"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                error={isCompanyNameEmptyError}
                helperText={isCompanyNameEmptyError && 'Company name cannot be empty'}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {companyName && (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearCompanyName} edge="end">
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            )}
            onChange={(event, value) => {
              if (value) {
                setCompanyName(value);
              } else {
                setCompanyName('');
              }
            }}
            value={companyName}
            renderOption={(props, option) => (
              <li {...props}>
                <span>{option}</span>
              </li>
            )}
            freeSolo
          />
        </Stack>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleAddCompanyUser} disabled={isSubmitting}>
            {t('Add')}
            {isSubmitting && <CircularProgress size={24} sx={{ ml: 1 }} />}
          </Button>
        </Box>
        
      </CardContent>
      <AlertSnackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        severity={alertSeverity}
        message={snackbarMessage}
      />
    </Card>
  );
};

export default AddCompanyUsersByCompanyAdmin;