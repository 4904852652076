import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';

class CDNService {
  private cdnUrl = process.env.REACT_APP_CDN_ENDPOINT || '';

  private sasUrl = process.env.REACT_APP_CDN_SAS_URL || '';

  private tenantCode = 'kieazy-app';

  public readonly imageContainerName = 'images';

  public FILE_UPLOADS_CONTAINER_NAME = 'file-uploads';

  public DEFAULT_UPLOADS_CONTAINER_NAME = 'file-uploads';
  //constructor(tenantCode: string, containerName:string) {
  //    this.sasUrl = process.env.REACT_APP_BLOB_SAS_URL || '';
  //    this.tenantCode = tenantCode;
  //    this.containerName = containerName;
  //}

  public init(tenantCode: string) {
    this.tenantCode = tenantCode;
  }

  public getContainerClient = (containerName: string): ContainerClient => {
    const blobServiceClient = new BlobServiceClient(this.sasUrl);
    const containerClient = blobServiceClient.getContainerClient(
      this.tenantCode + '/' + containerName
    );
    return containerClient;
  };

  public toCdnUrl(fileName: string, containerName?: string) {
    if (containerName)
      return this.cdnUrl + this.tenantCode + '/' + containerName + '/' + fileName;
    else
      return this.cdnUrl + this.tenantCode + '/' + fileName;
  }

  public toImageUrl(fileName: string) {
    if (!fileName)
      return null;
    const containerName = 'images';
    return this.cdnUrl + this.tenantCode + '/' + containerName + '/' + fileName;
  }

  
  // public toProfileImageUrl(fileName: string, userId: string) {
  //   if (!fileName || !userId) {
  //     return null;
  //   }
  //   const containerName = 'profile-images';
  //   return `${this.cdnUrl}${this.tenantCode}/${containerName}/user/${userId}/${fileName}`;
  // }
  

  public noImageUrl() {
    return this.cdnUrl + '_sys/no-image.jpg';
  }

  public toNfcUrl(nfcCode: string) {
    return 'https://kicard.co/nfc/' + nfcCode;
  }

  get noSurveyImage(): string {
    return this.cdnUrl + '/survey-images/' + process.env.REACT_APP_FALLBACK_IMAGE;
  }

  public async uploadFileToCloud(
    fileName: string,
    blob: any
  ): Promise<string> {
    let blobName = '';

    const getExt = (fileName: string): string => {
      const parts = fileName.split('.');
      return parts.length > 1 ? parts[parts.length - 1] : '';
    };

    const ext = getExt(fileName);

    blobName = 'img-' + new Date().getTime() + (ext ? '.' + ext : '');

    const containerClient = this.getContainerClient(
      this.imageContainerName
    );
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    await blockBlobClient.uploadBrowserData(blob, {
      onProgress: (o) => {
        //    total +=   o.loadedBytes;
      }
    });

    return blobName;
  };

  public async deleteFileFromCloud(
    fileName: string
  ): Promise<boolean> {
    const containerClient = this.getContainerClient(
      this.imageContainerName
    );
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    const res = await blockBlobClient.deleteIfExists();

    return res.succeeded;
  };


  getImageByForCategoryName(name: string) {
    if (!name)
      return this.noSurveyImage;

    return this.cdnUrl + '/survey-images/' + name +'.png';
  }

}
const cdnService = new CDNService();
export default cdnService;
