import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Footer from 'src/ui-bloom/components/Footer';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import QuestionPager from './question-pager';
import {
  FactEval,
  Response,
  Survey
} from 'src/services/apiService/response-models';
import {
  deleteEvalAnswer,
  endEval,
  getSurveyDetailById,
  saveEvalAnswer
} from 'src/services/apiService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import EvalContext from './EvalContext';
import ErrorPanel from 'src/components/error-panel';
import _ from 'lodash';
import useAuth from 'src/ui-bloom/hooks/useAuth';
import { setActiveTracker } from 'src/store/slices/app';

export default function EvalMain() {
  const { enqueueSnackbar } = useSnackbar();
  const { activeTracker } = useSelector((state: RootState) => state.app);
  const auth = useAuth();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [facts, setFacts] = useState(
    _.cloneDeep(activeTracker?.factEvals || [])
  );
  const [questionStartDisplayDataTime, setQuestionStartDisplayDataTime] =
    useState(new Date());
  const [
    questionResponceSubmitedDateTime,
    setQuestionResponceSubmitedDateTime
  ] = useState(new Date());
  const navigate = useNavigate();

  const [product, setProduct] = useState<Survey>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!activeTracker) {
      navigate('/app/not-found');
    } else {
      setIsLoading(true);
      getSurveyDetailById(Number(activeTracker.tracker.surveyID)).then((o) => {
        setProduct(o.data);
        setIsLoading(false);
      });
    }
  }, []);

  if (!activeTracker || !product) return <></>;

  const onAnswerSubmit = async (questionID: number, response: Response, comment: string | null) => {
    const factsCopy = [...facts];
    const match = factsCopy.find((o) => o.questionID === questionID);
    if (match) {
      match.responseID = response.responseID;
      match.trackerID = activeTracker.tracker.trackerID;
      match.recordResponceType = 'Update';
      if (comment !== null) {
        match.comment = comment; 
      }
      setFacts([...factsCopy]);
      await saveEvalAnswer(match);
    } else {
      const newFact: FactEval = {
        evalID: 0,
        trackerID: activeTracker.tracker.trackerID,
        questionID: questionID,
        responseID: response.responseID,
        surveyID: product.surveyID,
        userID: auth.user.id,
        comment: comment,
        questionStartDisplayDataTime: questionStartDisplayDataTime,
        questionResponceSubmitedDateTime: new Date(),
        backendRecordDataTime: new Date(),
        recordResponceType: 'Insert'
      };
      factsCopy.push(newFact);
      setFacts(factsCopy);
      await saveEvalAnswer(newFact);
    }
  };
  
  const onAnswerClear = async (questionID: number) => {
    const factsCopy = [...facts];
    const match = factsCopy.find((o) => o.questionID == questionID);
    if (match) {
      match.responseID = -1;
      match.trackerID = activeTracker.tracker.trackerID;
      match.recordResponceType = 'Update';
      setFacts(factsCopy.filter((o) => o.questionID != questionID));
      await deleteEvalAnswer(match);
    }
  };


  const onFinishEval = async () => {
    try {
      setIsSubmitting(true);
      await endEval(activeTracker.tracker.trackerID);
      localStorage.setItem(
        'tracker',
        JSON.stringify({
          productName: product.surveyName
        })
      );
      navigate('/app/eval-success');
    } catch (ex) {
      console.log(ex);
      enqueueSnackbar(`Failed to submit. Please try again`, {
        variant: 'error'
      });
      setIsSubmitting(false);
    }
  };

  return (
    <EvalContext.Provider
      value={{
        isLoading,
        product,
        activeIndex,
        setActiveIndex: (i) => {
          setActiveIndex(i);
          setQuestionStartDisplayDataTime(new Date());
        },
        answers: facts,
        isSubmitting,
        onAnswerSubmit: onAnswerSubmit,
        onAnswerClear: onAnswerClear
      }}
    >
      <PageTitleWrapper>
        <PageHeader
          title={product?.surveyName || ''}
          subTitle={product?.surveyCategory || ''}
        />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: mobile ? 2 : 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        {isLoading && !error && (
          <Grid item xs={12}>
            <LinearProgress sx={{ my: 3 }} />
          </Grid>
        )}
        {!isLoading && error && (
          <Grid item xs={12} py={3}>
            <ErrorPanel message={error} title="Critical error" />
          </Grid>
        )}
        {!isLoading && !error && (
          <Grid item xs={12}>
            {product.questions.length > 0 ? (
              <QuestionPager
                questions={product.questions}
                onFinishEval={onFinishEval}
              />
            ) : (
              <Box m={2}>
                <ErrorPanel message="No question in the product." />
              </Box>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          &nbsp;
        </Grid>
      </Grid>
    </EvalContext.Provider>
  );
}
