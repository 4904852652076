import React from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import BaseLayout from 'src/ui-bloom/layouts/BaseLayout';
import NotFound from '../contents/not-found';
import baseRoutes from './base';

import AccountDeletion from '../pages/account-deletion';
import BoxedSidebarLayout from '../ui-bloom/layouts/BoxedSidebarLayout';

import EvalMain from 'src/contents/eval-main';
import DashboardMain from 'src/contents/DashboardMain';
import ProductDetailView from 'src/contents/ProductDetail';
import AuthCallback from 'src/contents/auth-callback';
import ApplicationSetting from 'src/contents/settings';
import EvalSuccess from 'src/contents/eval-success';
import Status404 from 'src/contents/status-404';
import CollapsedSidebarLayout from 'src/ui-bloom/layouts/CollapsedSidebarLayout';
import AssessmentList from 'src/contents/ListOfAssessements';
import UserController from 'src/contents/UserController';
import CompanyAdminController from 'src/contents/CompanyAdminControllerBySuperAdmin';
import ConsultantAdminController from 'src/contents/ConsultantAdminControllerBySuperAdmin';
import SupportRequest from 'src/components/SupportRequest/SupportRequest';
import ConsultantAdmin from 'src/contents/ConsultantAdmin/ManageCompanyAdmins';
import CompaniesController from 'src/contents/CompaniesControllerBySuperAdmin';
import CompanyControllerByConsultant from 'src/contents/ConsultantAdmin/ManageCompanies';
import AddCompanyUsersByCompanyAdmin from 'src/contents/CompanyAdmin/AddCompanyUsers';
import ManageCompanyUsersByCompanyAdmin from 'src/contents/CompanyAdmin/ManageCompanyUsers';
import RequestSurvey from 'src/contents/SurveyRequest';
import RequestedSurveysStatus from 'src/contents/RequestedSurveysStatus';




const router: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="app" />
  },
  {
    path: 'app',
    element: <BaseLayout />,
    children: baseRoutes
  },
  // Extended Sidebar Layout
  {
    path: 'auth/callback',
    element: <AuthCallback />
  },
  {
    path: 'app',
    element: <CollapsedSidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" />
      },

      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: <DashboardMain />
          }
        ]
      },
      {
        path: 'active-eval',
        element: <EvalMain />
      },
      {
        path: 'eval-success',
        element: <EvalSuccess />
      },
      {
        path: 'product-details/:productId',
        element: <ProductDetailView />
      },
      {
        path: 'assessmentslist',
        element:<AssessmentList/>
      },
      {
        path: 'superadmin/usermanagement',
        element:<UserController/>
      },
      {
        path: 'superadmin/companymanagement',
        element:<CompaniesController/>
      },
      {
        path: 'superadmin/consultantadminmanagement',
        element:<ConsultantAdminController/>
      },
      {
        path: 'superadmin/companyadminmanagement',
        element:<CompanyAdminController/>
      },
      {
        path: 'requestsurvey',
        element: <RequestSurvey />
      },
      {
        path: 'requestedsurveysstatus',
        element: <RequestedSurveysStatus />
      },
      {
        path: 'settings',
        element: <ApplicationSetting />
      },
      {
        path: 'supportrequest',
        element: <SupportRequest />
      },
      {
        path: 'not-found',
        element: <NotFound />
      },
      {
        path: 'consulting-admin/companies',
        element:<CompanyControllerByConsultant/>
      },
      {
        path: 'consulting-admin/add-company-admin',
        element:<ConsultantAdmin/>
      },
      {
        path: 'company-admin/add-users',
        element:<AddCompanyUsersByCompanyAdmin/>
      },
      {
        path: 'company-admin/manage-users',
        element:<ManageCompanyUsersByCompanyAdmin/>
      },
      {
        path: '*',
        element: <Navigate to="not-found" />
      }
    ]
  },
  {
    path: 'status/404',
    element: <Status404 />
  },
  {
    path: 'app/delete-account',
    element: <AccountDeletion />
  }
];

export default router;
