import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  TablePagination,
  Skeleton,
  AlertColor,
  Paper,
  TableContainer,
  Typography,
  Tooltip,
} from '@mui/material';
import { AccountCircle, Email, HourglassFullTwoTone, VerifiedUser } from '@mui/icons-material';
import AlertSnackbar from '../../components/AlertSnackbar';
import { UsersApproval } from 'src/services/apiService/response-models';
import { updateUserApproval, getUsersApproval, getUnapprovedUserSignups } from 'src/services/apiService';
import { useTranslation } from 'react-i18next';

interface UserApprovalProps {
  searchKey: string;
  reloadTrigger: boolean;
}

const UserApproval: React.FC<UserApprovalProps> = ({ searchKey, reloadTrigger }) => {
  const [users, setUsers] = useState<UsersApproval[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('success');
  const { t }: { t: any } = useTranslation();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userRes = await getUnapprovedUserSignups();  
        const filteredUsers = userRes.data;
        setUsers(filteredUsers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };
    fetchUsers();
  }, [reloadTrigger]);

  const handleStatusChange = async (userId: number, newStatus: string) => {
    try {
      const isApproved = newStatus === 'Approved' ? 1 : 0;

      const userToUpdate = users.find((user) => user.UserID === userId);
      if (!userToUpdate) return;

      const updateData = {
        UserID: userToUpdate.UserID,
        IsApproved: isApproved,
      };

      await updateUserApproval(updateData);

      setUsers((prevUsers) =>
        prevUsers
          .map((user) => (user.UserID === userId ? { ...user, IsApproved: isApproved } : user))
          .filter((user) => user.IsApproved === 0)
      );

      setSnackbarMessage(`User ${userToUpdate.UserEmail} status changed to ${newStatus}, and a notification email has been sent`);
      setAlertSeverity(newStatus === 'Approved' ? 'success' : 'warning');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = useMemo(() => {
    return users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchKey.toLowerCase()) ||
        user.UserEmail.toLowerCase().includes(searchKey.toLowerCase())
    );
  }, [users, searchKey]);

  const paginatedUsers = useMemo(() => {
    return filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredUsers, page, rowsPerPage]);

  return (
    <>
      <Paper sx={{ padding: isSmallScreen? 0.5 : 2, boxShadow: 3 }} >
        <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#2442AF' }}>
                <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Id</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Email</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                Array.from(Array(10).keys()).map((index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={4}>
                      <Skeleton variant="rectangular" height={50} animation="wave" />
                    </TableCell>
                  </TableRow>
                ))
              ) : users.length === 0 ? (
                // Message when no users are found
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Typography variant="body1" color="error">
                      {t('No Pending Users Found')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                paginatedUsers.map((user) => (
                  <TableRow
                    key={user.UserID}
                    sx={{
                      '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                      '&:hover': { backgroundColor: '#e3f2fd', cursor: 'pointer' },
                    }}
                  >
                    <TableCell>{user.UserID}</TableCell>
                    <TableCell>
                      {user.name.trim() ? (
                        <Tooltip title={user.name.trim()} arrow>
                          <span style={{ display: 'inline-block', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            <AccountCircle sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                            <span style={{ color: theme.palette.text.primary }}>
                              {user.name.trim()}
                            </span>
                          </span>
                        </Tooltip>
                      ) : (
                        <span style={{ display: 'inline-block', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <AccountCircle sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                          <span style={{ color: theme.palette.text.disabled }}>
                            No Name
                          </span>
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Email sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                      {user.UserEmail || 'No Email'}
                    </TableCell>
                    <TableCell>
                      <Select
                        value={user.IsApproved === 1 ? 'Approved' : 'Pending'}
                        onChange={(e) => handleStatusChange(user.UserID, e.target.value as string)}
                        sx={{ width: isSmallScreen ? '100%' : '150px' }}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <MenuItem sx={{ color: '#ff9800', display: 'flex', alignItems: 'center' }} dense value="Pending">
                          <HourglassFullTwoTone sx={{ verticalAlign: 'middle', marginRight: 1, color: '#ff9800' }} />{' '}
                          <span style={{ color: 'textPrimary' }}>{t('Pending')}</span>
                        </MenuItem>
                        <MenuItem sx={{ color: '#4caf50', display: 'flex', alignItems: 'center' }} dense value="Approved">
                          <VerifiedUser sx={{ verticalAlign: 'middle', marginRight: 1, color: 'green' }} />{' '}
                          <span style={{ color: 'textPrimary' }}>{t('Approved')}</span>
                        </MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <AlertSnackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          severity={alertSeverity}
          message={snackbarMessage}
        />
        <Box mt={2}>
          <TablePagination
            component="div"
            count={filteredUsers.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </>
  );
};

export default UserApproval;
