import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Backdrop,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ProfileCompletionPopupProps {
  open: boolean;
  onClose: () => void;
}

const ProfileCompletionPopup: React.FC<ProfileCompletionPopupProps> = ({
  open,
  onClose,
}) => {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();

  const handleGoToProfile = () => {
    navigate('/app/settings');
    onClose();
  };

  return (
    <Backdrop
      open={open}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: '#fff',
        backdropFilter: 'blur(5px)', 
        backgroundColor: 'rgba(0, 0, 0, 0.4)', 
      }}
    >
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <Grid item xs={12} sm={10} md={8} lg={6} sx={{ mt: 10, ml: 8 }}>
          <Card sx={{ 
            boxShadow: 10, 
            borderRadius: 3, 
            background: 'linear-gradient(135deg, #ffffff, #f3f4f6)',
            color: '#333', 
            padding: 4, 
            maxWidth: 500,
            margin: 'auto', 
          }}>
            <CardHeader
              title={
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
                  {t('Complete Your Profile To Access The Dashboard')}
                </Typography>
              }
              sx={{ textAlign: 'center', pb: 0 }}
            />
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography variant="body1" sx={{ color: '#555', mb: 2 }}>
                {t('To get the best experience, please complete your profile.')}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                variant="contained"
                onClick={handleGoToProfile}
                sx={{
                  backgroundColor: '#1e3a8a', // Dark blue background
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#1e40af', // Slightly lighter blue on hover
                  },
                  padding: '0.75rem 2rem', // Increased padding for the button
                  fontSize: '1rem', // Larger font size
                }}
              >
                {t('Go to Profile')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default ProfileCompletionPopup;
