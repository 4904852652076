import {
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
  Tooltip,
  styled
} from '@mui/material';
import Logo from 'src/ui-bloom/components/LogoSign';

import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function NotFound(props: { title?: string; subTitle?: string }) {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <MainContent>
        <Container
          maxWidth="md"
          sx={{
            p: 10
          }}
        >
          <Logo />
          <Box textAlign="center">
            <Container maxWidth="xs">
              <Typography
                variant="h2"
                sx={{
                  mt: 4,
                  mb: 2
                }}
              >
                {t(
                  props.title ||
                    'Not found. The content you are looking is not available'
                )}
              </Typography>
              <Typography
                variant="h3"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 4
                }}
              >
                {t(
                  props.subTitle || 'We apologize for any inconveniences caused'
                )}
              </Typography>
            </Container>
            <img
              alt="Maintenance"
              height={250}
              src="/static/images/maintenance.svg"
            />
          </Box>
          <Divider
            sx={{
              my: 4
            }}
          />
        </Container>
      </MainContent>
    </>
  );
}

export default NotFound;
