import React, { useState, useEffect } from 'react';
import PageHeader from './PageHeader';
import { Helmet } from 'react-helmet-async';
import {
  Autocomplete,
  Box,
  Card,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  debounce,
  styled,
  useMediaQuery,
  useTheme,
  Backdrop,
} from '@mui/material';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import Footer from 'src/ui-bloom/components/Footer';
import Block, { LoadingBlock } from './Block';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllSurveyGroups, getUserByID } from 'src/services/apiService';
import { Survey, SurveyGroup } from 'src/services/apiService/response-models';
import ErrorPanel from 'src/components/error-panel';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import Sidebar from './Sidebar';
import Results from './Results';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import _ from 'lodash';
import SurveyContext, { CategoryItem } from './SurveyContext';
import { RootState, useDispatch, useSelector } from 'src/store';
import { setLoadedSurveys } from 'src/store/slices/app';
import ProfileCompletionPopup from 'src/components/ProfileCompletionPopup/ProfileCompletionPopup';


const SearchIconWrapper = styled(SearchTwoToneIcon)(
  ({ theme }) => `
        color: ${theme.colors.alpha.black[30]}
`
);

const DrawerWrapperMobile = styled(Drawer)(
  () => `
    width: 340px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
  }
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  position: absolute;
  background: ${theme.colors.alpha.white[100]};
  z-index: 5;
  top: calc(${theme.header.height} + ${theme.spacing(2)});
`
);

const searchTags = [
  { title: 'General' },
  { title: 'Agile' },
  { title: 'Cloud' },
  { title: 'Fabric' }
];

const jobsLocations = [
  { title: 'Bucharest, Romania' },
  { title: 'San Francisco, USA' },
  { title: 'Madrid, Spain' },
  { title: 'Berlin, Germany' },
  { title: 'Paris, France' },
  { title: 'London, UK' }
];

function DashboardMain() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userCtx, activeTracker } = useSelector((state: RootState) => state.app); 
  const userId = userCtx.userId;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [surveyGroups, setSurveyGroups] = useState<SurveyGroup[]>([]);
  const [categoryItems, setCategoryItems] = useState<CategoryItem[]>([]);
  const [favoriteSurveyIds, setFavoriteSurveyIds] = useState<number[]>([]);
  const [searchKey, setSearchKey] = useState('');
  const [sortBy, setSortBy] = useState('latest');
  const [showFavoriteCat, setShowFavoriteCat] = useState(false);
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [checkCategoryIds, setCheckCategoryIds] = useState<string[]>([]);
  const [isProfileComplete, setIsProfileComplete] = useState(true); 
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setErrorMsg('');
      try {
        const res = await getAllSurveyGroups();
        setSurveyGroups(res.data);

        const surveys = _.flatten(res.data.map((o) => o.surveys));
        setSurveys(surveys);
        let arr: CategoryItem[] = [];
        surveys.forEach((s) => {
          const match = arr.find((o) => o.id == s.surveyCategory);
          if (match) {
            match.count++;
            match.surveys.push(s);
          } else {
            arr.push({
              id: s.surveyCategory,
              desc: s.surveyGroup.surveyProductName,
              text: s.surveyCategory,
              count: 1,
              isSelected: false,
              surveys: [s]
            });
          }
        });

        setCategoryItems(arr);

        const userResponse = await getUserByID(userId);
        if (userResponse.success) {
          const user = userResponse.data[0];
          const isComplete = user.UserFirstName && user.UserLastName && user.UserPhoneNumber;
          setIsProfileComplete(!!isComplete);
          setPopupOpen(!isComplete);
        }
      } catch (ex) {
        console.log(ex);
        setErrorMsg('Failed to load data. ' + ex.message);
      }
      setIsLoading(false);
    })();
  }, [userId]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileComplete = () => {
    setIsProfileComplete(true);
    setPopupOpen(false);
  };

  const applyFilter = (): Survey[] => {
    const items = [...categoryItems];

    let results: CategoryItem[] = [];

    const selectedCats = items.filter((o) => o.isSelected);
    results = selectedCats.length ? selectedCats : items;

    let surveys = _.flatten(results.map((o) => o.surveys));

    if (showFavoriteCat) {
      surveys = surveys.filter((s) => favoriteSurveyIds.includes(s.surveyID));
    }

    if (checkCategoryIds.length) {
      surveys = surveys.filter((o) =>
        checkCategoryIds.includes(o.surveyCategory)
      );
    }

    if (activeTracker?.tracker && activeTracker?.tracker?.surveyID) {
      const activeTrackerSurveys = surveys.filter((s) => s.surveyID === activeTracker.tracker.surveyID);
      let remainingSurveys = surveys.filter((s) => s.surveyID !== activeTracker.tracker.surveyID);
    
      if (sortBy === 'latest') {
        remainingSurveys = _.orderBy(remainingSurveys, (o) => o.surveyID, 'desc');
      } else if (sortBy === 'name') {
        remainingSurveys = _.orderBy(remainingSurveys, (o) => o.surveyName, 'asc');
      } else if (sortBy === 'fav') {
        const favSurveys = remainingSurveys.filter((s) => favoriteSurveyIds.includes(s.surveyID));
        const others = _.differenceBy(remainingSurveys, favSurveys);
        remainingSurveys = [...favSurveys, ...others];
      }
    
      surveys = [...activeTrackerSurveys, ...remainingSurveys];
    } else {
      if (sortBy === 'latest') {
        surveys = _.orderBy(surveys, (o) => o.surveyID, 'desc');
      } else if (sortBy === 'name') {
        surveys = _.orderBy(surveys, (o) => o.surveyName, 'asc');
      } else if (sortBy === 'fav') {
        const favSurveys = surveys.filter((s) => favoriteSurveyIds.includes(s.surveyID));
        const others = _.differenceBy(surveys, favSurveys);
        surveys = [...favSurveys, ...others];
      }
    }

    const key = searchKey.trim().toLowerCase();
    if (key) {
      surveys = surveys.filter(
        (o) =>
          o.surveyName.toLowerCase().includes(key) ||
          o.surveyCategory.toLowerCase().includes(key) ||
          o.surveyDesc.toLowerCase().includes(key)
      );
    }
    return surveys;
  };

  const onAddToFavorite = (surveyId: number, isAdd) => {
    const arr = favoriteSurveyIds.filter((o) => o != surveyId);
    setFavoriteSurveyIds(isAdd ? [...arr, surveyId] : [...arr]);
  };

  const debouncedHandleSearch = debounce((o) => setSearchKey(o), 100);

  return (
    <SurveyContext.Provider
      value={{
        totalCount: surveys.length,
        categories: categoryItems,
        favoriteSurveyIds: favoriteSurveyIds,
        filteredItems: applyFilter(),
        isLoading: isLoading,
        onAddToFavorite: onAddToFavorite,
        sortBy: sortBy,
        onCategorySelect: (ids: string[]) => {
          setCheckCategoryIds([...ids]);
        },
        onSearchKeyChange: (o) => setSearchKey(o.trim()),
        showFavorite: showFavoriteCat,
        toggleShowFavorite: () => setShowFavoriteCat(!showFavoriteCat),
        onSortChange: (o) => setSortBy(o)
      }}
    >
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          px: mobile ? 2 : 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={mobile ? 1 : 4}
        padding={0}
      >
        {isLoading &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((o) => (
            <Grid key={o} item xs={12} md={6}>
              <LoadingBlock />
            </Grid>
          ))}
        {!isLoading && Boolean(errorMsg.length) && (
          <ErrorPanel message={errorMsg} title="Critical Error" />
        )}
      </Grid>
      {!isLoading && !Boolean(errorMsg.length) && (
        <Grid
          sx={{
            px: mobile ? 2 : 4
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={mobile ? 1 : 4}
        >
          <Grid item xs={12}>
            <Box mt={4}></Box>
            <IconButtonToggle
              sx={{
                display: { lg: 'none', xs: 'flex' }
              }}
              color="primary"
              onClick={handleDrawerToggle}
              size="small"
            >
              <MenuTwoToneIcon />
            </IconButtonToggle>
          </Grid>
          <Grid item xs={12}>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Box display={{ xs: 'none', lg: 'flex' }} ml={2} flexShrink={1}>
                <SearchIconWrapper />
              </Box>
              <Stack
                sx={{
                  p: 2,
                  flex: 1
                }}
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <TextField
                  sx={{
                    m: 0
                  }}
                  onChange={(o) => debouncedHandleSearch(o.target.value)}
                  placeholder={t('Search by name, category, description ...')}
                  value={searchKey}
                  fullWidth
                  variant="outlined"
                />
              </Stack>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            sx={{
              display: { xs: 'none', lg: 'inline-block' }
            }}
          >
            <Card>
              <Sidebar />
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            {surveyGroups && (
              <Results
                surveys={_.flatMap(surveyGroups.map((o) => o.surveys))}
              />
            )}
          </Grid>
        </Grid>
      )}
      {!isLoading && !Boolean(errorMsg.length) && (
        <DrawerWrapperMobile
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <Scrollbar>
            <Sidebar />
          </Scrollbar>
        </DrawerWrapperMobile>
      )}
      <ProfileCompletionPopup open={popupOpen} onClose={handleProfileComplete} />
      <Footer />
    </SurveyContext.Provider>
  );
}

export default DashboardMain;

