import { createContext, useContext, useState } from 'react';

interface UserContextType {
  notificationCount: number;
  setNotificationCount: React.Dispatch<React.SetStateAction<number>>;
}

const UserContext = createContext<UserContextType>({
  notificationCount: 0,
  setNotificationCount: () => {},
});


export const UserProvider: React.FC = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState<number>(0);
  return (
    <UserContext.Provider value={{ notificationCount, setNotificationCount }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);