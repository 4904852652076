import React, { useState } from 'react';
import {
  Box,
  Container,
  Tab,
  Tabs,
  Card,
  CardContent,
  TextField,
  Button,
  Stack,
  Divider,
  styled,
  InputAdornment,
  IconButton,
  debounce,
  useTheme,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import { createUser } from 'src/services/apiService';
import AlertSnackbar from 'src/components/AlertSnackbar';
import ManageCompanies from './ManageCompaniesByConsultant';
import AddCompany from './AddCompany';

const CompanyControllerByConsultant: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [searchKeys, setSearchKeys] = useState<{ [key: number]: string }>({ 0: '', 1: ''});
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isEmailEmptyError, setIsEmailEmptyError] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    setIsEmailEmptyError(false);
    setIsEmailValid(true);
    setSearchKeys({ ...searchKeys, [newValue]: '' });
  };

  const handleClearSearch = (tabIndex: number) => {
    setSearchKeys({ ...searchKeys, [tabIndex]: '' });
  };

  const SearchIconWrapper = styled(SearchTwoToneIcon)(({ theme }) => `
      color: ${theme.palette.text.disabled};
    `);

  const debouncedHandleSearch = debounce((key: number, value: string) => {
    setSearchKeys((prev) => ({ ...prev, [key]: value }));
  }, 100);


  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Company Management</title>
      </Helmet>
      <Box my={4}>
        <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
          <Tab
            label="Manage Companies"
            sx={{
              backgroundColor: currentTab === 0 ? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 0 ? '#fff' : '#777',
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
          <Tab
            label="Add Companies"
            sx={{
              backgroundColor: currentTab === 1 ? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 1 ? '#fff' : '#777',
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
        </Tabs>

        {(currentTab === 0 ) && (
          <Card
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              ...(currentTab === 0 && { marginRight: 2 }),
              width: '100%',
              mt: 2,
              mb: 2,
            }}
          >
            <Box display={{ xs: 'none', lg: 'flex' }} ml={2} flexShrink={1}>
              <SearchIconWrapper />
            </Box>
            <Stack
              sx={{
                p: 2,
                flex: 1,
              }}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <TextField
                sx={{
                  m: 0,
                }}
                onChange={(o) => debouncedHandleSearch(currentTab, o.target.value)}
                placeholder={t('Search by name, email ...')}
                value={searchKeys[currentTab]}
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <>
                      {searchKeys[currentTab] && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => handleClearSearch(currentTab)} edge="end">
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Stack>
          </Card>
        )}

        {currentTab === 0 && (
          <Card sx={{ marginBottom: 2 }}>
            <ManageCompanies searchKey={searchKeys[0]} />
          </Card>
        )}
        {currentTab === 1 && (
          <Card sx={{ marginBottom: 2 , mt: 2 }}>
            <AddCompany />
          </Card>
        )}
      </Box>
    </Container>
  );
};

export default CompanyControllerByConsultant;
