import { createContext, useState } from 'react';

interface ProfileImageContextValue {
  profileImage: string | null;
  setProfileImage: (image: string) => void;
}

const ProfileImageContext = createContext<ProfileImageContextValue>({
    profileImage: null,
  } as ProfileImageContextValue);

const ProfileImageProvider = ({ children }) => {
  const [profileImage, setProfileImage] = useState(null);

  return (
    <ProfileImageContext.Provider value={{ profileImage, setProfileImage }}>
      {children}
    </ProfileImageContext.Provider>
  );
};

export { ProfileImageProvider, ProfileImageContext };