import { useContext, useEffect, useState } from 'react';

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  ListItemIcon,
  Checkbox,
  List,
  ListItemButton,
  ListItemText,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Label from 'src/ui-bloom/components/Label';
import { SurveyGroup } from 'src/services/apiService/response-models';
import _ from 'lodash';
import readFavoriteSurveyIds from 'src/utility/readFavoriteSurveyIds';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import SurveyContext from './SurveyContext';

const AccordionSummaryWrapper = styled(AccordionSummary)(
  () => `
      &.Mui-expanded {
        min-height: 48px;
      }

      .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
      }
  `
);

const ListItemWrapper = styled(ListItemButton)(
  () => `
  
      &.MuiButtonBase-root {
        border-radius: 0;
      }
  `
);

function Sidebar() {
  const { t }: { t: any } = useTranslation();
  const context = useContext(SurveyContext);

  // const [showFavorite, setShowFavorite] = useState(false);
  //const { favoriteSurveyIds } = useSelector((state: RootState) => state.app);

  //const [productNames, setProductNames] = useState<FilterListItem[]>([]);
  // const [proficiencyLevels, setProficiencyLevels] = useState<FilterListItem[]>(
  //   []
  // );

  const [checked, setChecked] = useState(['']);
  const [checked2, setChecked2] = useState(['']);

  useEffect(() => {}, []);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    context.onCategorySelect(newChecked.filter((o) => o.length));
  };

  const handleToggle2 = (value: string) => () => {
    const currentIndex = checked2.indexOf(value);
    const newChecked = [...checked2];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked2(newChecked);
  };

  const [checked3, setChecked3] = useState([0]);

  const handleToggle3 = (value: number) => () => {
    const currentIndex = checked3.indexOf(value);
    const newChecked = [...checked3];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked3(newChecked);
  };

  if (context.categories.length == 0) return <></>;
  return (
    <>
      {context.favoriteSurveyIds.length > 0 && (
        <Accordion
          sx={{
            p: 1
          }}
          defaultExpanded
        >
          <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">{t('Favorite')}</Typography>
          </AccordionSummaryWrapper>
          <AccordionDetails
            sx={{
              p: 0
            }}
          >
            <List disablePadding component="div">
              <ListItemWrapper
                sx={{
                  py: 0,
                  px: 2
                }}
                onClick={() => context.toggleShowFavorite()}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 32
                  }}
                >
                  <Checkbox
                    edge="start"
                    checked={context.showFavorite}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Show Favorite'}
                  primaryTypographyProps={{ variant: 'body1' }}
                />
                <Label color="primary">
                  {context.favoriteSurveyIds.length}
                </Label>
              </ListItemWrapper>
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        sx={{
          p: 1
        }}
        defaultExpanded
      >
        <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{t('Evaluation Category')}</Typography>
        </AccordionSummaryWrapper>
        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          <List disablePadding component="div">
            {context.categories.map((item) => {
              return (
                <ListItemWrapper
                  sx={{
                    py: 0,
                    px: 2
                  }}
                  key={item.id}
                  onClick={handleToggle(item.id)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 32
                    }}
                  >
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                  <Label color="primary">{item.count}</Label>
                </ListItemWrapper>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
      {/* <Divider />
      <Accordion
        sx={{
          p: 1
        }}
        defaultExpanded
      >
        <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{t('Product Name')}</Typography>
        </AccordionSummaryWrapper>
        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          <List disablePadding component="div">
            {productNames.map((value) => {
              return (
                <ListItemWrapper
                  sx={{
                    py: 0,
                    px: 2
                  }}
                  key={value.id}
                  onClick={handleToggle2(value.id)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 32
                    }}
                  >
                    <Checkbox
                      edge="start"
                      checked={checked2.indexOf(value.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={value.text}
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                  <Label color="primary">{value.count}</Label>
                </ListItemWrapper>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion> */}
      {/* <Divider />
      <Accordion
        sx={{
          p: 1
        }}
        defaultExpanded
      >
        <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{t('Proficiency Level')}</Typography>
        </AccordionSummaryWrapper>
        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          <List disablePadding component="div">
            {proficiencyLevels.map((value) => {
              return (
                <ListItemWrapper
                  sx={{
                    py: 0,
                    px: 2
                  }}
                  key={value.id}
                  onClick={handleToggle2(value.id)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 32
                    }}
                  >
                    <Checkbox
                      edge="start"
                      checked={checked2.indexOf(value.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={value.text}
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                  <Label color="primary">{value.count}</Label>
                </ListItemWrapper>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion> */}
    </>
  );
}

export default Sidebar;
