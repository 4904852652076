import React, { useState } from 'react';
import { Button, TextField, Checkbox, FormControlLabel, Grid, Typography, CardContent, Box, Alert, Snackbar, CircularProgress, styled } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DatePicker } from '@mui/lab';
import { RootState, useSelector } from 'src/store';
import { CreateCompanies, getCompanies } from 'src/services/apiService';
import AlertSnackbar from 'src/components/AlertSnackbar';
import { useTranslation } from 'react-i18next';

const RedAsterisk = styled('span')({
  color: 'red',
});

const AddCompanyBySuperAdmin = () => {
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'warning'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t }: { t: any } = useTranslation();
  
  const validationSchema = yup.object({
    CompanyName: yup.string().required('Company Name is required'),
    CompanyType: yup.string().required('Company Type is required'),
    CompanyIndustry: yup.string().required('Company Industry is required'),
    CompanyHeadquarters: yup.string().nullable(),
    CompanyFoundedYear: yup.number().nullable(),
    CompanyRevenue: yup.number().nullable(),
    CompanyNumberOfEmployees: yup.number().nullable(),
    CompanyMarketSegment: yup.string().nullable(),
    CompanyParentCompanyID: yup.number().nullable(),
    CompanyGlobalPresence: yup.string().nullable(),
    CompanyWebsiteURL: yup.string().url('Enter a valid URL').required('Company Website URL is required'),
    CompanyContactInfo: yup.string().nullable(),
    CompanyDescription: yup.string().nullable(),
    CompanyIsConsultingCompany: yup.boolean().required(),
    ConsultingCompanyID: yup.number().nullable(),
  });

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    const filteredValues = Object.entries(values)
         .filter(([key, value]) => value!== null && value!== '')
         .reduce((acc, [key, value]) => ({...acc, [key]: value }), {}) as { CompanyName: string };
  
    try {
      const response = await getCompanies();
      const companies = response.data;
      const companyExists = companies.some((company) => company.CompanyName === filteredValues.CompanyName);
  
      if (companyExists) {
        setAlertSeverity('error');
        setSnackbarMessage('Company already exists');
        setSnackbarOpen(true);
        setIsSubmitting(false);
        return;
      }
  
      await CreateCompanies(filteredValues);
      setAlertSeverity('success');
      setSnackbarMessage('Company created successfully');
      formik.resetForm();
    } catch (error) {
      setAlertSeverity('error');
      setSnackbarMessage('Error submitting company');
    }
    setSnackbarOpen(true);
    setIsSubmitting(false);
  };
  
  const formik = useFormik({
    initialValues: {
      CompanyName: '',
      CompanyType: '',
      CompanyIndustry: '',
      CompanyHeadquarters: '',
      CompanyFoundedYear: null,
      CompanyRevenue: null,
      CompanyNumberOfEmployees: null,
      CompanyMarketSegment: '',
      CompanyParentCompanyID: null,
      CompanyGlobalPresence: '',
      CompanyWebsiteURL: '',
      CompanyContactInfo: '',
      CompanyDescription: '',
      CompanyIsConsultingCompany: false,
      ConsultingCompanyID: '',
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });
  

  return (
    <CardContent>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
              <InfoIcon style={{ marginRight: '10px', color: '#1976d2' }} />
              <Typography variant="h5" color="primary">Company Information</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="CompanyName"
              label={
                <>
                  {t('Company Name')} <RedAsterisk>*</RedAsterisk>
                </>
              }
              value={formik.values.CompanyName}
              onChange={formik.handleChange}
              error={formik.touched.CompanyName && Boolean(formik.errors.CompanyName)}
              helperText={formik.touched.CompanyName && formik.errors.CompanyName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="CompanyType"
              label={
                <>
                  {t('Company Type')} <RedAsterisk>*</RedAsterisk>
                </>
              }
              value={formik.values.CompanyType}
              onChange={formik.handleChange}
              error={formik.touched.CompanyType && Boolean(formik.errors.CompanyType)}
              helperText={formik.touched.CompanyType && formik.errors.CompanyType}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="CompanyIndustry"
              label={
                <>
                  {t('Company Industry')} <RedAsterisk>*</RedAsterisk>
                </>
              }
              value={formik.values.CompanyIndustry}
              onChange={formik.handleChange}
              error={formik.touched.CompanyIndustry && Boolean(formik.errors.CompanyIndustry)}
              helperText={formik.touched.CompanyIndustry && formik.errors.CompanyIndustry}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Headquarters"
              name="CompanyHeadquarters"
              value={formik.values.CompanyHeadquarters}
              onChange={formik.handleChange}
              error={formik.touched.CompanyHeadquarters && Boolean(formik.errors.CompanyHeadquarters)}
              helperText={formik.touched.CompanyHeadquarters && formik.errors.CompanyHeadquarters}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Company Founded Year"
              views={['year']}
              value={formik.values.CompanyFoundedYear ? new Date(formik.values.CompanyFoundedYear, 0, 1) : null}
              onChange={(date) => {
                const year = date ? date.getFullYear() : null;
                formik.setFieldValue('CompanyFoundedYear', year);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={formik.touched.CompanyFoundedYear && Boolean(formik.errors.CompanyFoundedYear)}
                  helperText={formik.touched.CompanyFoundedYear && formik.errors.CompanyFoundedYear}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Revenue"
              name="CompanyRevenue"
              value={formik.values.CompanyRevenue ?? ''}
              onChange={formik.handleChange}
              error={formik.touched.CompanyRevenue && Boolean(formik.errors.CompanyRevenue)}
              helperText={formik.touched.CompanyRevenue && formik.errors.CompanyRevenue}
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Number of Employees"
              name="CompanyNumberOfEmployees"
              value={formik.values.CompanyNumberOfEmployees ?? ''}
              onChange={formik.handleChange}
              error={formik.touched.CompanyNumberOfEmployees && Boolean(formik.errors.CompanyNumberOfEmployees)}
              helperText={formik.touched.CompanyNumberOfEmployees && formik.errors.CompanyNumberOfEmployees}
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Market Segment"
              name="CompanyMarketSegment"
              value={formik.values.CompanyMarketSegment}
              onChange={formik.handleChange}
              error={formik.touched.CompanyMarketSegment && Boolean(formik.errors.CompanyMarketSegment)}
              helperText={formik.touched.CompanyMarketSegment && formik.errors.CompanyMarketSegment}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Parent Company ID"
              name="CompanyParentCompanyID"
              value={formik.values.CompanyParentCompanyID ?? ''}
              onChange={formik.handleChange}
              error={formik.touched.CompanyParentCompanyID && Boolean(formik.errors.CompanyParentCompanyID)}
              helperText={formik.touched.CompanyParentCompanyID && formik.errors.CompanyParentCompanyID}
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Global Presence"
              name="CompanyGlobalPresence"
              value={formik.values.CompanyGlobalPresence}
              onChange={formik.handleChange}
              error={formik.touched.CompanyGlobalPresence && Boolean(formik.errors.CompanyGlobalPresence)}
              helperText={formik.touched.CompanyGlobalPresence && formik.errors.CompanyGlobalPresence}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="CompanyWebsiteURL"
              label={
                <>
                  {t('Company Website URL')} <RedAsterisk>*</RedAsterisk>
                </>
              }
              value={formik.values.CompanyWebsiteURL}
              onChange={formik.handleChange}
              error={formik.touched.CompanyWebsiteURL && Boolean(formik.errors.CompanyWebsiteURL)}
              helperText={formik.touched.CompanyWebsiteURL && formik.errors.CompanyWebsiteURL}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Contact Info"
              name="CompanyContactInfo"
              value={formik.values.CompanyContactInfo}
              onChange={formik.handleChange}
              error={formik.touched.CompanyContactInfo && Boolean(formik.errors.CompanyContactInfo)}
              helperText={formik.touched.CompanyContactInfo && formik.errors.CompanyContactInfo}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Description"
              name="CompanyDescription"
              value={formik.values.CompanyDescription}
              onChange={formik.handleChange}
              error={formik.touched.CompanyDescription && Boolean(formik.errors.CompanyDescription)}
              helperText={formik.touched.CompanyDescription && formik.errors.CompanyDescription}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="CompanyIsConsultingCompany"
                  checked={formik.values.CompanyIsConsultingCompany}
                  onChange={formik.handleChange}
                />
              }
              label="Company is a consulting company"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Consulting Company ID"
              name="ConsultingCompanyID"
              value={formik.values.ConsultingCompanyID}
              onChange={formik.handleChange}
              error={formik.touched.ConsultingCompanyID && Boolean(formik.errors.ConsultingCompanyID)}
              helperText={formik.touched.ConsultingCompanyID && formik.errors.ConsultingCompanyID}
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
              Add Company
              {isSubmitting && <CircularProgress size={24} sx={{ ml: 1 }} />}
            </Button>
          </Grid>
        </Grid>
      </form>
      <AlertSnackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        severity={alertSeverity}
        message={snackbarMessage}
      />
    </CardContent>
  );
};

export default AddCompanyBySuperAdmin;
