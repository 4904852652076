import { createContext } from 'react';
import { Survey, SurveyGroup } from 'src/services/apiService/response-models';

export interface CategoryItem {
  id: string;
  desc: string;
  text: string;
  count: number;
  isSelected: boolean;
  surveys: Survey[];
}
export interface SurveyContextType {
  isLoading: boolean;
  filteredItems: Survey[];
  categories: CategoryItem[];
  favoriteSurveyIds: number[];
  onSearchKeyChange: (e: string) => void;
  onCategorySelect: (e: string[]) => void;
  onAddToFavorite: (e: number, isAdd: boolean) => void;
  toggleShowFavorite: () => void;
  onSortChange: (e: string) => void;
  showFavorite: boolean;
  totalCount: number;
  sortBy: string; //'latest' | 'name' | 'fav';
}

const initialState: SurveyContextType = {
  isLoading: false,
  showFavorite: false,
  filteredItems: [],
  categories: [],
  favoriteSurveyIds: [],
  onSearchKeyChange: () => Promise.resolve(),
  onCategorySelect: () => Promise.resolve(),
  onAddToFavorite: () => Promise.resolve(),
  toggleShowFavorite: () => Promise.resolve(),
  sortBy: 'latest',
  onSortChange: () => Promise.resolve(),
  totalCount: 0
};

const SurveyContext = createContext<SurveyContextType>(initialState);

export default SurveyContext;
