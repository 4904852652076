import React, { useState } from 'react';
import {
  Box,
  Container,
  Tab,
  Tabs,
  Card,
  CardContent,
  TextField,
  Button,
  Stack,
  Divider,
  styled,
  InputAdornment,
  IconButton,
  debounce,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import { createNonCompanyUser, createUser, deleteUserFromAuth0 } from 'src/services/apiService';
import AlertSnackbar from '../../components/AlertSnackbar';
import AssignSurveysToCompanies from '../ConsultantAdmin/ManageCompanies/ManageCompaniesByConsultant';
import AssignSurveysToIndividualUsers from './AssignSurveysToIndividualUsers';
import UserApproval from './userApproval';
import ManageUsers from './ManageUsers';
import AssignSurveysToCompanyUsers from './AssignSurveysToCompanyUsers';

const UserController: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [assignSurveySubTab, setAssignSurveySubTab] = useState<number>(0);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('CoeIndex@123');
  const [searchKeys, setSearchKeys] = useState<{ [key: number]: string }>({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '',});
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isEmailEmptyError, setIsEmailEmptyError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    setIsEmailEmptyError(false);
    setIsEmailValid(true);
    setUserEmail('');
    setSearchKeys({ ...searchKeys, [newValue]: '' });
  };

  const handleAssignSurveySubTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setAssignSurveySubTab(newValue);
    setSearchKeys((prevKeys) => ({
      ...prevKeys,
      [newValue + 2]: '', 
    }));
  };

  const handleAddUser = async () => {
    if (!userEmail) {
      setIsEmailEmptyError(true);
      return;
    }
  
    if (!validateEmail(userEmail)) {
      setIsEmailValid(false);
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const createUserResponse = await createUser(userEmail);
      const newEmail = createUserResponse.data.email;
      const TemporaryPassword  = createUserResponse.password;
  
      if (newEmail && createUserResponse.success) {
        await new Promise(resolve => setTimeout(resolve, 1500)); 
  
        let attempts = 0;
        const maxAttempts = 2;
        let success = false;
  
        while (attempts < maxAttempts && !success) {
          try {
            await createNonCompanyUser(newEmail, TemporaryPassword );
            success = true;
          } catch (error) {
            attempts++;
            if (attempts >= maxAttempts) {
              await deleteUserFromAuth0(createUserResponse.data.user_id);
              throw error;
            }
            await new Promise(resolve => setTimeout(resolve, 1500)); 
          }
        }
  
        if (success) {
          setSnackbarMessage('Non-company user created successfully. An email has been sent to the user for further instructions');
          setAlertSeverity('success');
          setUserEmail('');
          setReloadTrigger(prev => !prev);
        }
      }
    } catch (error) {
      console.error('Error adding user:', error);
      const errorMessage = error.response?.data?.error_description || error.response?.data?.message || 'Error adding user. Please try again.';
      setSnackbarMessage(errorMessage);
      setAlertSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setIsSubmitting(false);
    }
  };
  
  const handleClearUserEmail = () => {
    setUserEmail('');
    setIsEmailValid(true);
    setIsEmailEmptyError(false);
  };

  const handleClearSearch = (tabIndex: number) => {
    setSearchKeys({ ...searchKeys, [tabIndex]: '' });
  };

  const SearchIconWrapper = styled(SearchTwoToneIcon)(({ theme }) => `
      color: ${theme.palette.text.disabled};
    `);

  const debouncedHandleSearch = debounce((key: number, value: string) => {
    setSearchKeys((prev) => ({ ...prev, [key]: value }));
  }, 100);

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>User Management</title>
      </Helmet>
      <Box my={4}>
        <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
          <Tab
            label="User Management"
            sx={{
              backgroundColor: currentTab === 0 ? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 0 ? '#fff' : '#777',
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
          <Tab
            label="Approve User"
            sx={{
              backgroundColor: currentTab === 1 ? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 1 ? '#fff' : '#777',
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
          <Tab
            label="Assign Surveys"
            sx={{
              backgroundColor: currentTab === 2 ? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 2 ? '#fff' : '#777',
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
          <Tab
            label="Add User"
            sx={{
              backgroundColor: currentTab === 3 ? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 3 ? '#fff' : '#777',
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
        </Tabs>

        {(currentTab === 0 || currentTab === 1) && (
          <Card
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              ...(currentTab === 0 && { marginRight: 2 }),
              width: '100%',
              mt: 2,
              mb: 2,
            }}
          >
            <Box display={{ xs: 'none', lg: 'flex' }} ml={2} flexShrink={1}>
              <SearchIconWrapper />
            </Box>
            <Stack
              sx={{
                p: 2,
                flex: 1,
              }}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <TextField
                sx={{
                  m: 0,
                }}
                onChange={(o) => debouncedHandleSearch(currentTab, o.target.value)}
                placeholder={t('Search by name, email ...')}
                value={searchKeys[currentTab]}
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <>
                      {searchKeys[currentTab] && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => handleClearSearch(currentTab)} edge="end">
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Stack>
          </Card>
        )}

        {currentTab === 0 && (
          <Card sx={{ marginBottom: 2 }}>
            <ManageUsers searchKey={searchKeys[0]} />
          </Card>
        )}
        {currentTab === 1 && (
          <Card sx={{ marginBottom: 2 }}>
            <UserApproval searchKey={searchKeys[1]} reloadTrigger={reloadTrigger} />
          </Card>
        )}
        {currentTab === 2 && (
          <>
            <Tabs value={assignSurveySubTab} onChange={handleAssignSurveySubTabChange} variant="fullWidth" sx={{ mt: 2 }}>
              <Tab
                label="Individual Users"
                sx={{
                  backgroundImage: assignSurveySubTab === 0 ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' : '#f0f0f0',
                  backgroundColor: assignSurveySubTab === 0 ? '' : '#ebebeb',
                  '&:not(:last-child)': {
                    borderRight: '1px solid #ccc',
                  },
                }}
              />
              <Tab
                label="Company Users"
                sx={{
                  backgroundImage: assignSurveySubTab === 1 ? 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)' : '#f0f0f0',
                  backgroundColor: assignSurveySubTab === 1 ? '' : '#ebebeb',
                  '&:not(:last-child)': {
                    borderRight: '1px solid #ccc',
                  },
                }}
              />
            </Tabs>

            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                width: '100%',
                mt: 2,
                mb: 2,
              }}
            >
              <Box display={{ xs: 'none', lg: 'flex' }} ml={2} flexShrink={1}>
                <SearchIconWrapper />
              </Box>
              <Stack
                sx={{
                  p: 2,
                  flex: 1,
                }}
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <TextField
                  sx={{
                    m: 0,
                  }}
                  onChange={(o) => debouncedHandleSearch(currentTab + assignSurveySubTab, o.target.value)}
                  placeholder={t('Search by id, name, email ...')}
                  value={searchKeys[currentTab + assignSurveySubTab]}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <>
                        {searchKeys[currentTab + assignSurveySubTab] && (
                          <InputAdornment position="end">
                            <IconButton onClick={() => handleClearSearch(currentTab + assignSurveySubTab)} edge="end">
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
              </Stack>
            </Card>

            {assignSurveySubTab === 0 && (
              <Card sx={{ marginBottom: 2 }}>
                <AssignSurveysToIndividualUsers searchKey={searchKeys[2]} />
              </Card>
            )}
            {assignSurveySubTab === 1 && (
              <Card sx={{ marginBottom: 2 }}>
                <AssignSurveysToCompanyUsers searchKey={searchKeys[3]} />
              </Card>
            )}
          </>
        )}
        {currentTab === 3 && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
            <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  label={t('Add User')}
                  fullWidth
                  type="email"
                  variant="outlined"
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                    setIsEmailValid(validateEmail(e.target.value));
                    setIsEmailEmptyError(false);
                  }}
                  error={!isEmailValid || isEmailEmptyError}
                  helperText={
                    (!isEmailValid && 'Invalid email format') ||
                    (isEmailEmptyError && 'Please enter an email')
                  }
                  sx={{ mr: 2, flex: 1 }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {userEmail && (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearUserEmail} edge="end">
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
                </Stack>
                <Box mt={2}>
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddUser}
                  disabled={isSubmitting}
                >
                  {t('Add')}
                  {isSubmitting && <CircularProgress size={24} sx={{ ml: 1 }} />}
                </Button>
                </Box>
            </CardContent>
          </Card>
        )}

      </Box>
      <AlertSnackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        severity={alertSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default UserController;
