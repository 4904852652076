import CircleNotificationsTwoToneIcon from '@mui/icons-material/CircleNotificationsTwoTone';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { User } from 'src/models/user';

export interface SettingTab {
  readonly name: string;
  readonly text: string;
  readonly role?: string;
  readonly icon: React.ReactNode;
}

const SettingTabs: SettingTab[] = [
  {
    name: 'general',
    text: 'User Profile',
    icon: <SettingsIcon />
  }
  // {
  //     name: 'nfcMap',
  //     text: 'NFC Code Mapping',
  //     icon: <CreditCardIcon />
  // },
];

const getSettingTabs = (user: User) => {
  const userRoles = user.userRoles || [];

  return SettingTabs.filter((o) => !o.role || userRoles.includes(o.role));
};
export default getSettingTabs;
