import React, { useState, useEffect, useMemo, ChangeEvent, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  TablePagination,
  Skeleton,
  Paper,
  Typography,
  TableContainer,
  IconButton,
  Collapse,
  Input,
  TextField,
  InputAdornment,
  Tooltip,
  CircularProgress
} from '@mui/material';
import {
  Assessment,
  ExpandMore,
  ExpandLess,
  Delete,
  BusinessCenter,
  CloudUpload,
  Search
} from '@mui/icons-material';
import {
  getAllSurveyGroups,
  getCompanies,
  CreateCompanySurveyApproval,
  getAssignedSurveysForCompany,
  removeAssignedSurvey,
  uploadFileToAzure,
  getCompaniesByConsultant
} from 'src/services/apiService';
import { Companies, Survey, SurveyGroup, UsersApproval } from 'src/services/apiService/response-models';
import AlertSnackbar from '../../../components/AlertSnackbar';
import WarnConfirm from 'src/components/warn-confirm';
import BusinessIcon from '@mui/icons-material/Business';
import { RootState, useSelector } from 'src/store';
interface ManageCompaniesProps {
  searchKey: string;
}

const ManageCompanies: React.FC<ManageCompaniesProps> = ({ searchKey }) => {
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [surveyGroups, setSurveyGroups] = useState<SurveyGroup[]>([]);
  const [companies, setCompanies] = useState<Companies[]>([]);
  const [assignedSurveys, setAssignedSurveys] = useState<{ [key: number]: UsersApproval[] }>({});
  const [assignedSurveyGroups, setAssignedSurveyGroups] = useState<{ [key: number]: UsersApproval[] }>({});
  const [loadingCompanySurveys, setLoadingCompanySurveys] = useState<{ [key: number]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAfterAssignSurvey, SetLoadingAfterAssignSurvey] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const fileInputSurveyRef = useRef<HTMLInputElement>(null);
  const fileInputSurveyGroupRef = useRef<HTMLInputElement>(null);  
  const surveyType={survey:'surveyFile',surveyGroup: 'surveyGroupFile'}
  const [surveyNameForUpload, setSurveyNameForUpload] = useState<string>('');
  const [searchSurveys, setSearchSurveys] = useState<{ [key: number]: { survey: string; group: string } }>({});
  const [deleteDetails, setDeleteDetails] = useState<{
    approvalId: number,
    surveyName: string,
    company: Companies
  } | null>(null);
  const { userCtx } = useSelector((state: RootState) => state.app);
  const consultantID  = userCtx.userId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [surveyRes, companyRes] = await Promise.all([
          getAllSurveyGroups(),
          getCompaniesByConsultant(consultantID)
        ]);
        setSurveyGroups(surveyRes.data);
        const surveys = surveyRes.data.flatMap((group) => group.surveys);
        setSurveys(surveys);
        const companiesByConsultant = companyRes.data
        setCompanies(companiesByConsultant);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  

  const getSurveyOrGroupName = (approval) => {
    if (approval.SurveyID) {
      const survey = surveys.find((s) => s.surveyID === approval.SurveyID);
      return survey ? survey.surveyName : 'No survey data found';
    } else if (approval.SurveyProductID) {
      const surveyGroup = surveyGroups.find((group) => group.surveyProductID === approval.SurveyProductID);
      return surveyGroup ? surveyGroup.surveyProductName : 'No survey group data found';
    }
    return 'No survey data found';
  };


  const getMatchingSurvey = (fileName: string,survey: string) => {
    const fileNameWithoutExtension = fileName.replace('.pdf', '');
    const fileNameParts = fileNameWithoutExtension.split(' - ');
    const extractedSurveyName = fileNameParts.slice(1).join(' - ').trim().toLowerCase(); 
    if (extractedSurveyName === survey.toLowerCase()) {
      return true;
    }
  
    return null;
  };

  const handleFileInputChange = async (event: ChangeEvent<HTMLInputElement>, companyId: number, surveyType: string) => {
    SetLoadingAfterAssignSurvey(true)
    const files = event.target.files;
    if (files && files.length > 0) {
      try {
        if (surveyType === 'surveyFile') {
          const uploadPromises = Array.from(files).map(async (file) => {
            const fileName = file.name.toLowerCase();
            const surveyName = surveyNameForUpload.toLowerCase();
            const matchingSurvey = getMatchingSurvey(fileName, surveyName);
            if (matchingSurvey) {
              const fileUrl = await uploadFileToAzure(file, companyId, 'company');
              return fileUrl;
            } else {
              throw new Error('Invalid file upload: File does not match the assigned survey');
            }
          });
  
          await Promise.all(uploadPromises);
  
          setAlertSeverity('success');
          setSnackbarMessage('Files uploaded successfully');
        } else if (surveyType === 'surveyGroupFile') {
          const surveyGroup = surveyGroups.find((group) => group.surveyProductName.toLowerCase() === surveyNameForUpload.toLowerCase());
          if (surveyGroup) {
            const surveysInGroup = surveyGroup.surveys;
            const uploadPromises = Array.from(files).map(async (file) => {
              const fileName = file.name.toLowerCase();
              const matchingSurvey = surveysInGroup.find((survey) => getMatchingSurvey(fileName, survey.surveyName));
              if (matchingSurvey) {
                const fileUrl = await uploadFileToAzure(file, companyId, 'company');
                return fileUrl;
              } else {
                throw new Error(`Invalid file upload: File ${file.name} does not match any survey in the assigned survey group`);
              }
            });
  
            await Promise.all(uploadPromises);
  
            setAlertSeverity('success');
            setSnackbarMessage('Files uploaded successfully');
          } else {
            throw new Error('Survey group not found');
          }
        }
  
        if (surveyType === 'surveyFile' && fileInputSurveyRef.current) {
          fileInputSurveyRef.current.value = '';
        } else if (surveyType === 'surveyGroupFile' && fileInputSurveyGroupRef.current) {
          fileInputSurveyGroupRef.current.value = '';
        }
      } catch (error) {
        console.error('File upload error:', error);
        setAlertSeverity('error');
        setSnackbarMessage(error.message);
      } finally {
        setSnackbarOpen(true);
        SetLoadingAfterAssignSurvey(false);
      }
    }
  };
  
  
  
  const handleUploadClick = (surveyType: string, surveyName: string) => {
    if (surveyType === 'surveyFile' && fileInputSurveyRef.current) {
      fileInputSurveyRef.current.click();
      setSurveyNameForUpload(surveyName);
    } else if (surveyType === 'surveyGroupFile' && fileInputSurveyGroupRef.current) {
      fileInputSurveyGroupRef.current.click();
      setSurveyNameForUpload(surveyName);
    }
  };
  

  const handleAssignSurvey = async (companyId: number, surveyId: number | null) => {
    const company = companies.find((company) => company.CompanyID === companyId);
    const survey = surveys.find((survey) => survey.surveyID === surveyId);
    if (company) {
      SetLoadingAfterAssignSurvey(true);
      try {
       const response= await CreateCompanySurveyApproval({
          CompanyID: companyId,
          SurveyID: surveyId || null,
          ApprovalType: 'Company Individual Survey Approval'
        });
        if(response.data.success===true)
          {
            setSearchSurveys((prev) => ({ ...prev, [companyId]: { survey: '', group: '' } }));
            setAlertSeverity('success');
            setSnackbarMessage(surveyId !== null
              ? `Assigned survey "${survey?.surveyName}" to company ${company.CompanyName}`
              : `Removed survey from company ${company.CompanyName}`);
          }
        else{
          setAlertSeverity('error');
          setSnackbarMessage(`SurveyGroup "${survey?.surveyName}" already assigned to the company ${company.CompanyName}`)
        }
        fetchAssignedSurveys(companyId);
      } catch (error) {
        console.error('Error assigning survey:', error);
        setAlertSeverity('error');
        setSnackbarMessage('Failed to assign survey.');
      }
      finally {
        SetLoadingAfterAssignSurvey(false);
      }
      setSnackbarOpen(true);
    }
  };

  const handleAssignSurveyGroup = async (companyId: number, surveyGroupId: number | null) => {
    const company = companies.find((company) => company.CompanyID === companyId);
    const surveyGroup = surveyGroups.find((group) => group.surveyProductID === surveyGroupId);
    if (company) {
      SetLoadingAfterAssignSurvey(true);
      try {
        const response=await CreateCompanySurveyApproval({
          CompanyID: companyId,
          SurveyProductID: surveyGroupId || null,
          ApprovalType: 'Company Survey Group Approval'
        });
        if(response.data.success===true)
          {
            setSearchSurveys((prev) => ({ ...prev, [companyId]: { survey: '', group: '' } }));
            setAlertSeverity('success');
            setSnackbarMessage(surveyGroupId !== null
              ? `Assigned survey group "${surveyGroup?.surveyProductName}" to company ${company.CompanyName}`
              : `Removed survey group from company ${company.CompanyName}`);
          }
        else{
          setAlertSeverity('error');
          setSnackbarMessage(`SurveyGroup "${surveyGroup?.surveyProductName}" already assigned to the company ${company.CompanyName}`)
        }
        fetchAssignedSurveys(companyId);
      } catch (error) {
        console.error('Error assigning survey group:', error);
        setAlertSeverity('error');
        setSnackbarMessage('Failed to assign survey group.');
      }
      finally {
        SetLoadingAfterAssignSurvey(false);
      }
      setSnackbarOpen(true);
    }
  };

  const handleDeleteSurvey = (approvalId: number, surveyName: string, company: Companies) => {
    setDeleteDetails({ approvalId, surveyName, company });
    setOpenDeleteDialog(true);
  };

  const confirmDeleteSurvey = async () => {
    if (deleteDetails) {
      try {
        await removeAssignedSurvey(deleteDetails.approvalId,deleteDetails.surveyName);
        setAlertSeverity('success');
        setSnackbarMessage(`Survey ${deleteDetails.surveyName} removed from company ${deleteDetails.company.CompanyName}`);
        fetchAssignedSurveys(deleteDetails.company.CompanyID);
      } catch (error) {
        console.error('Error removing survey:', error);
        setAlertSeverity('error');
        setSnackbarMessage('Failed to remove survey.');
      }
      setSnackbarOpen(true);
      setOpenDeleteDialog(false);
    }
  };

  const fetchAssignedSurveys = async (companyId: number) => {
    setLoadingCompanySurveys((prev) => ({ ...prev, [companyId]: true }));
    try {
      const response = await getAssignedSurveysForCompany(companyId);
      const individualSurveys = response.data.filter((item: UsersApproval) => item.SurveyID !== null);
      const surveyGroups = response.data.filter((item: UsersApproval) => item.SurveyProductID !== null);
      
      setAssignedSurveys((prev) => ({ ...prev, [companyId]: individualSurveys }));
      setAssignedSurveyGroups((prev) => ({ ...prev, [companyId]: surveyGroups }));
    } catch (error) {
      console.error('Error fetching assigned surveys:', error);
    }
    setLoadingCompanySurveys((prev) => ({ ...prev, [companyId]: false }));
  };
  
  
  const handleSearchSurveyChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, companyId: number) => {
    const { value } = event.target;
    setSearchSurveys((prev) => ({
      ...prev,
      [companyId]: { ...prev[companyId], survey: value }
    }));
  };
  
  const handleSearchSurveyGroupChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, companyId: number) => {
    const { value } = event.target;
    setSearchSurveys((prev) => ({
      ...prev,
      [companyId]: { ...prev[companyId], group: value }
    }));
  };
  
  const handleRowToggle = (companyId: number) => {
    setExpandedRows((prev) => {
      const newState = { ...prev, [companyId]: !prev[companyId] };
      Object.keys(newState).forEach((key) => {
        if (key !== companyId.toString()) {
          newState[parseInt(key)] = false;
        }
      });
  
      if (newState[companyId]) {
        fetchAssignedSurveys(companyId);
      } else {
        const { [companyId]: removed, ...rest } = assignedSurveys;
        const { [companyId]: removedGroups, ...restGroups } = assignedSurveyGroups;
        setAssignedSurveys(rest);
        setAssignedSurveyGroups(restGroups);
      }
  
      return newState;
    });
  };
  

  const filteredCompanies = useMemo(() => {
    const lowercasedSearchKey = (searchKey || '').toLowerCase();
  
    return companies.filter((company) => {
      const companyName = (company.CompanyName || '').toLowerCase();
      const companyType = (company.CompanyType || '').toLowerCase();
  
      return companyName.includes(lowercasedSearchKey) || companyType.includes(lowercasedSearchKey);
    });
  }, [companies, searchKey]);
  
  const getFilteredSurveys = (companyId: number) => {
    const searchQuery = searchSurveys[companyId]?.survey?.toLowerCase() || '';
    if (!searchQuery) return surveys;
    return surveys.filter((survey) => survey.surveyName.toLowerCase().includes(searchQuery));
  };
  
  const getFilteredSurveyGroups = (companyId: number) => {
    const searchQuery = searchSurveys[companyId]?.group?.toLowerCase() || '';
    if (!searchQuery) return surveyGroups;
    return surveyGroups.filter((group) => group.surveyProductName.toLowerCase().includes(searchQuery));
  };

  return (
    <Paper sx={{ padding: isSmallScreen ? 0.5 : 2, boxShadow: 3 }}>
       {loadingAfterAssignSurvey && (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
      )}
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#2442AF' }}>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}></TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Company Id</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Company Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Company Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Assign Individual Survey</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Assign Survey Group</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from(Array(10).keys()).map((index) => (
                <TableRow                   key={index}>
                <TableCell colSpan={6}>
                  <Skeleton variant="rectangular" height={50} animation="wave" />
                </TableCell>
              </TableRow>
            ))
          ) : (
            filteredCompanies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((company) => (
              <React.Fragment key={company.CompanyID}>
                <TableRow
                  key={company.CompanyID}
                  sx={{
                    '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                    '&:hover': { backgroundColor: '#e3f2fd', cursor: 'pointer' }
                  }}
                >
                  <TableCell>
                    <IconButton size="small" onClick={() => handleRowToggle(company.CompanyID)}>
                      {expandedRows[company.CompanyID] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{company.CompanyID}</TableCell>
                  <TableCell><BusinessIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />{company.CompanyName || 'No Company Name'}</TableCell>
                  <TableCell><BusinessCenter sx={{ verticalAlign: 'middle', marginRight: 1 }} />{company.CompanyType || 'No Company Type'}</TableCell>
                  <TableCell>
  <TextField
    value={searchSurveys[company.CompanyID]?.survey || ''}
    onChange={(event) => handleSearchSurveyChange(event, company.CompanyID)}
    placeholder="Search Surveys"
    variant="outlined"
    sx={{ width: isSmallScreen? '120%' : '170px' }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      ),
    }}
  />
  {searchSurveys[company.CompanyID]?.survey && (
    <Box sx={{ position: 'absolute', zIndex: 1, backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', width: '100%', maxWidth: '250px' }}>
      {getFilteredSurveys(company.CompanyID).length > 0? (
        <Box sx={{ overflowY: 'auto' }}>
          {getFilteredSurveys(company.CompanyID).slice(0, 5).map((survey) => (
            <Tooltip key={survey.surveyID} title={survey.surveyName} arrow>
              <MenuItem
                key={survey.surveyID}
                value={survey.surveyID}
                onClick={() => handleAssignSurvey(company.CompanyID, survey.surveyID)}
                sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <Assessment sx={{ color: '#4caf50', verticalAlign: 'iddle', marginRight: 1 }} />
                <Typography noWrap sx={{ flex: 1 }}>{survey.surveyName}</Typography>
              </MenuItem>
            </Tooltip>
          ))}
        </Box>
      ) : (
        <Box sx={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography color="error" sx={{ textAlign: 'center' }}>
            No surveys found
          </Typography>
        </Box>
      )}
    </Box>
  )}
</TableCell>

<TableCell>
  <TextField
    value={searchSurveys[company.CompanyID]?.group || ''}
    onChange={(event) => handleSearchSurveyGroupChange(event, company.CompanyID)}
    placeholder="Search Survey Groups"
    variant="outlined"
    sx={{ width: isSmallScreen? '120%' : '215px' }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      ),
    }}
  />
  {searchSurveys[company.CompanyID]?.group && (
    <Box sx={{ position: 'absolute', zIndex: 1, backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', width: '100%', maxWidth: '250px' }}>
      {getFilteredSurveyGroups(company.CompanyID).length > 0? (
        <Box sx={{ overflowY: 'auto' }}>
          {getFilteredSurveyGroups(company.CompanyID).slice(0, 5).map((group) => (
            <Tooltip key={group.surveyProductID} title={group.surveyProductName} arrow>
              <MenuItem
                key={group.surveyProductID}
                value={group.surveyProductID}
                onClick={() => handleAssignSurveyGroup(company.CompanyID, group.surveyProductID)}
                sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <Assessment sx={{ color: '#4caf50', verticalAlign: 'iddle', marginRight: 1 }} />
                <Typography noWrap sx={{ flex: 1 }}>{group.surveyProductName}</Typography>
              </MenuItem>
            </Tooltip>
          ))}
        </Box>
      ) : (
        <Box sx={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography color="error" sx={{ textAlign: 'center' }}>
            No survey groups found
          </Typography>
        </Box>
      )}
    </Box>
  )}
</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={expandedRows[company.CompanyID]} timeout="auto" unmountOnExit>
  <Box sx={{ margin: 1 }}>
    {loadingCompanySurveys[company.CompanyID] ? (
      <>
        <Box>
          <Skeleton animation="wave" height={30} />
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '15%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '60%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '10%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '15%' }}><Skeleton animation="wave" height={30} /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={4}><Skeleton animation="wave" height={40} /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box>
          <Skeleton animation="wave" height={30} />
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '15%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '60%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '10%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '15%' }}><Skeleton animation="wave" height={30} /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={4}><Skeleton animation="wave" height={40} /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    ) : (
      <>
        <Box
          sx={{
            mb: 2,
            backgroundColor: '#f0f8ff',
            padding: 2,
            borderRadius: 1,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0'
          }}
        >
          <Typography variant="h5" gutterBottom component="div" color="textDark">
            Assigned Surveys
          </Typography>
          <Table size="small">
                          <TableHead>
                            <TableRow>
                            <TableCell width="20%">SurveyId</TableCell>
                            <TableCell width="60%">SurveyName</TableCell>
                            <TableCell width="5%">Action</TableCell>
                            <TableCell width="15%">Upload Survey</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {assignedSurveys[company.CompanyID] && assignedSurveys[company.CompanyID].length > 0 ? (
                              assignedSurveys[company.CompanyID].map((survey) => (
                                <TableRow key={survey.ApprovalID}>
                                  <TableCell>{survey.SurveyID}</TableCell>
                                  <TableCell>{getSurveyOrGroupName(survey)}</TableCell>
                                  <TableCell>
                                    <IconButton
                                      aria-label="delete"
                                      color='error'
                                      onClick={() => handleDeleteSurvey(survey.ApprovalID, getSurveyOrGroupName(survey), company)}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </TableCell>
                                 <TableCell>
                                 <Input
                     id={`upload-input-${survey.ApprovalID}`}
                     type="file"
                     inputProps={{ accept: '.pdf', multiple: false }}
                     onChange={(event) => handleFileInputChange(event as ChangeEvent<HTMLInputElement>, survey.CompanyID,surveyType.survey)}
                     sx={{ display: 'none' }}
                     inputRef={fileInputSurveyRef }
                  />
                 <IconButton
                     aria-label="upload"
                     onClick={() => handleUploadClick(surveyType.survey,getSurveyOrGroupName(survey))}
                     sx={{ marginLeft: '30px' }}
                  >
                       <CloudUpload />
                 </IconButton>
                </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4}><Typography color={'error'} align='center'>No assigned surveys found for this user</Typography></TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                        </Box>
        <Box
          sx={{
            backgroundColor: '#f0f8ff',
            padding: 2,
            borderRadius: 1,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0'
          }}
        >
          <Typography variant="h5" gutterBottom component="div" color="textDark">
            Assigned Survey Groups
          </Typography>
          <Table size="small">
                          <TableHead>
                            <TableRow>
                            <TableCell width="20%">SurveyId</TableCell>
                            <TableCell width="60%">SurveyName</TableCell>
                            <TableCell width="5%">Action</TableCell>
                            <TableCell width="15%">Upload Surveys</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {assignedSurveyGroups[company.CompanyID] && assignedSurveyGroups[company.CompanyID].length > 0 ? (
                              assignedSurveyGroups[company.CompanyID].map((group) => (
                                <TableRow key={group.ApprovalID}>
                                  <TableCell>{group.SurveyProductID}</TableCell>
                                  <TableCell>{getSurveyOrGroupName(group)}</TableCell>
                                  <TableCell>
                                    <IconButton
                                      aria-label="delete"
                                      color='error'
                                      onClick={() => handleDeleteSurvey(group.ApprovalID, getSurveyOrGroupName(group), company)}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </TableCell>
                                  <TableCell>
                                  <Input
                     id={`upload-input-${group.ApprovalID}`}
                     type="file"
                     inputProps={{ accept: '.pdf', multiple: true }}
                     onChange={(event) => handleFileInputChange(event as ChangeEvent<HTMLInputElement>, group.CompanyID,surveyType.surveyGroup)}
                     sx={{ display: 'none' }}
                     inputRef={fileInputSurveyGroupRef }
                  />
                 <IconButton
                     aria-label="upload"
                     onClick={() => handleUploadClick(surveyType.surveyGroup,getSurveyOrGroupName(group))}
                     sx={{ marginLeft: '30px' }}
                  >
                       <CloudUpload />
                 </IconButton>
                </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>                           
                                <TableCell colSpan={4}><Typography color={'error'} align='center'>No assigned survey groups found for this user</Typography></TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                       </Box>
                       </>
                     )}
                      </Box>
                 </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={filteredCompanies.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(_, newPage) => setPage(newPage)}
      onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
    />
<WarnConfirm
  open={openDeleteDialog}
  message={`Are you sure you want to delete the survey ${deleteDetails?.surveyName} from company ${deleteDetails?.company.CompanyName}?`}
  okText="Delete"
  cancelText="Cancel"
  onConfirm={confirmDeleteSurvey}
  onClose={() => setOpenDeleteDialog(false)}
/>
    <AlertSnackbar
      open={snackbarOpen}
      autoHideDuration={5000}
      onClose={() => setSnackbarOpen(false)}
      severity={alertSeverity}
      message={snackbarMessage}
    />
  </Paper>
);
};

export default ManageCompanies;

