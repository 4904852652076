import {
  Box,
  Card,
  Typography,
  Container,
  Divider,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';

import { useTranslation } from 'react-i18next';
import useAuth from 'src/ui-bloom/hooks/useAuth';

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
);

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
  `
);

const ButtonSearch = styled(Button)(
  ({ theme }) => `
      margin-right: -${theme.spacing(1)};
  `
);

function Status404() {
  const { t }: { t: any } = useTranslation();
  const { user, logout } = useAuth();
  return (
    <>
      <Helmet>
        <title>Status - 404</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <img alt="404" height={400} src="/static/images/coe-banner.png" />
            <Typography
              variant="h2"
              sx={{
                my: 2
              }}
            >
              {t('You account approval is still pending.')}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                mb: 4
              }}
            >
              {t(
                'If you signed up recently, we are currently reviewing your profile, please visit after sometime.'
              )}
            </Typography>
          </Box>
          <Container maxWidth="sm">
            <Card
              sx={{
                textAlign: 'center',
                mt: 3,
                p: 4
              }}
            >
              <Button href="https://app.evaomax.com/" variant="outlined">
                {t('Go to CoE Index App')}
              </Button>
              <Button sx={{ ml: 4 }} onClick={logout} variant="outlined">
                {t('Log out')}
              </Button>
            </Card>
          </Container>
        </Container>
      </MainContent>
    </>
  );
}

export default Status404;
