import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Collapse,
  Container,
  IconButton,
  Typography,
  styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setActiveTracker } from 'src/store/slices/app';
import { useDispatch } from 'react-redux';

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
        background-color: ${theme.colors.success.main};
        color: ${theme.palette.success.contrastText};
        width: ${theme.spacing(12)};
        height: ${theme.spacing(12)};
        box-shadow: ${theme.colors.shadows.success};
        margin-left: auto;
        margin-right: auto;
  
        .MuiSvgIcon-root {
          font-size: ${theme.typography.pxToRem(45)};
        }
  `
);
const MainContent = styled(Box)(
  () => `
      height: 100%;
      overflow: auto;
      flex: 1;
  `
);
export default function EvalSuccess() {
  const navigate = useNavigate();
  const [info, setInfo] = useState<{ productName: string } | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const trackerStr = localStorage.getItem('tracker');
    if (!trackerStr) {
      navigate('/app/not-found');
    } else {
      setInfo(JSON.parse(trackerStr));
      localStorage.removeItem('tracker');
      setTimeout(() => {
        dispatch(setActiveTracker({ tracker: null }));
      });
    }
  }, []);

  return (
    <MainContent>
      <Container
        sx={{
          my: 4
        }}
        maxWidth="md"
      >
        {/* <Logo /> */}
        <Card
          sx={{
            mt: 3,
            pt: 4
          }}
        >
          {' '}
          <Box px={4}>
            <Typography
              variant="h2"
              sx={{
                mb: 1
              }}
            >
              {info?.productName}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                mb: 3
              }}
            ></Typography>
          </Box>{' '}
          <Box px={4} py={8}>
            <Container maxWidth="sm">
              <AvatarSuccess>
                <CheckTwoToneIcon />
              </AvatarSuccess>
              <Collapse in={true}>
                <Alert
                  sx={{
                    mt: 5
                  }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {}}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="info"
                >
                  {'A evaluation report will be generated and shared with you'}
                </Alert>
              </Collapse>

              <Typography
                align="center"
                sx={{
                  pt: 5,
                  pb: 4,
                  lineHeight: 1.5,
                  px: 10
                }}
                variant="h2"
              >
                {'Thank You. Evaluation has been submitted.'}
              </Typography>

              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  navigate('/app/dashboard');
                }}
              >
                Continue to dashboard
              </Button>
            </Container>
          </Box>
        </Card>
      </Container>
    </MainContent>
  );
}
