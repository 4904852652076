import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PageHeader: React.FC<{ mobile: boolean }> = ({ mobile }) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Box textAlign="center" mb={2}>
      <Typography
        align="center"
        variant={mobile ? 'h4' : 'h1'}
        component={mobile ? 'h4' : 'h3'}
        sx={{
          mb: 1, mt: 2
        }}
      >
        {t('Download Assessments')}
      </Typography>
    </Box>
  );
};

export default PageHeader;
