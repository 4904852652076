import React, { useState, useEffect, useMemo, ChangeEvent, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  TablePagination,
  Skeleton,
  Paper,
  Typography,
  TableContainer,
  IconButton,
  Collapse,
  Input,
  InputAdornment,
  TextField,
  Tooltip,
  CircularProgress,
  Chip
} from '@mui/material';
import { AccountCircle, Assessment, Email, ExpandLess, ExpandMore, Group, Delete, CloudUpload, Search } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorIcon from '@mui/icons-material/Error';
import {
  getAllSurveyGroups,
  getUsersApproval,
  CreateUserSurveyApproval,
  getAssignedSurveysForUser,
  removeAssignedSurvey,
  uploadFileToAzure,
  getUserByID,
  getSurveyStatus,
  getAllReportsFromBlob,
  getIndividualUsers
} from 'src/services/apiService';
import { Survey, SurveyGroup, UsersApproval } from 'src/services/apiService/response-models';
import AlertSnackbar from '../../components/AlertSnackbar';
import WarnConfirm from 'src/components/warn-confirm';

interface AssignSurveysToIndividualUsersProps {
  searchKey: string;
}

const AssignSurveysToIndividualUsers: React.FC<AssignSurveysToIndividualUsersProps> = ({ searchKey }) => {
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [surveyGroups, setSurveyGroups] = useState<SurveyGroup[]>([]);
  const [users, setUsers] = useState<UsersApproval[]>([]);
  const [assignedSurveys, setAssignedSurveys] = useState<{ [key: number]: UsersApproval[] }>({});
  const [assignedSurveyGroups, setAssignedSurveyGroups] = useState<{ [key: number]: UsersApproval[] }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAfterAssignSurvey, SetLoadingAfterAssignSurvey] = useState(false);
  const [loadingUserSurveys, setLoadingUserSurveys] = useState<{ [key: number]: boolean }>({});
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});
  const [expandedGroups, setExpandedGroups] = useState([]);
  const theme = useTheme();
  const [uploadedFileNames, setUploadedFileNames] = useState<{ [key: string]: string[] }>({});
  const [uploadedFileNamesForSurveyGroups, setUploadedFileNamesForSurveyGroups] = useState<{ [key: string]: string[] }>({});
  const [surveyStatuses, setSurveyStatuses] = useState({});
  const [groupSurveyStatuses, setGroupSurveyStatuses] = useState({});
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [surveyGroupId, setSurveyGroupId] = useState<number | null>(null);
  const fileInputSurveyRef = useRef<HTMLInputElement>(null);
  const fileInputSurveyGroupRef = useRef<HTMLInputElement>(null);  
  const surveyType={survey:'surveyFile',surveyGroup: 'surveyGroupFile'}
  const [surveyNameForUpload, setSurveyNameForUpload] = useState<string>('');
  const [searchSurveys, setSearchSurveys] = useState<{ [key: number]: { survey: string; group: string } }>({});
  const [deleteDetails, setDeleteDetails] = useState<{
    approvalId: number,
    surveyName: string,
    user: UsersApproval
  } | null>(null);

  
  useEffect(() => {
    const fetchStatuses = async () => {
      if (!users || users.length === 0 || !assignedSurveys) return;

      const statuses = {};
      const newUploadedFileNames = {}; 

      for (let user of users) {
        for (let survey of assignedSurveys[user.UserID] || []) {
          const status = await fetchSurveyStatus(user.UserID, survey.SurveyID);
          statuses[`${user.UserID}-${survey.SurveyID}`] = status;

          if (status === 'Completed') {
            try {
              const response = await getAllReportsFromBlob(user.UserID);
              newUploadedFileNames[user.UserID] = response.pdfNames;
            } catch (error) {
              console.error('Error fetching uploaded file names:', error);
            }
          }
        }
      }
      setSurveyStatuses(statuses);
      setUploadedFileNames(newUploadedFileNames);
    };

    fetchStatuses();
  }, [users, assignedSurveys]);

  const fetchGroupStatuses = async (userId: number, groupProductId: number): Promise<{ statuses: { [key: string]: string }; fileNames: { [key: string]: string[] } }> => {
    const groupStatuses: { [key: string]: string } = {};
    const newUploadedFileNames: { [key: string]: string[] } = {};
  
    const surveysInGroup = surveys.filter(
      (survey) => survey.surveyProductID === groupProductId
    );
  
    for (let survey of surveysInGroup) {
      const status = await fetchSurveyStatus(userId, survey.surveyID);
      groupStatuses[`${userId}-${survey.surveyID}`] = status;
  
      if (status === 'Completed') {
        try {
          const response = await getAllReportsFromBlob(userId);
          newUploadedFileNames[userId] = response.pdfNames;
        } catch (error) {
          console.error('Error fetching uploaded file names:', error);
        }
      }
    }
  
    return { statuses: groupStatuses, fileNames: newUploadedFileNames };
  };
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [surveyRes, userRes] = await Promise.all([
          getAllSurveyGroups(),
          getIndividualUsers()
        ]);
  
        const surveyGroupsData = surveyRes.data;
        const surveysData = surveyGroupsData.flatMap((group) => group.surveys);
  
        const individualUsers = userRes.data;
  
        setSurveyGroups(surveyGroupsData);
        setSurveys(surveysData);
        setUsers(individualUsers);
        setLoading(false);
  
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  const getStatusChip = (status) => {
    const chipStyles = {
      
    };
  
    switch (status) {
      case 'Completed':
        return <Chip label="Completed" color="success" icon={<DoneIcon />} size="small" sx={chipStyles} />;
      case 'In Progress':
        return <Chip label="In Progress" color="warning" icon={<HourglassEmptyIcon />} size="small" sx={chipStyles} />;
      case 'Not Started':
        return <Chip label="Not Started" color="primary" icon={<ErrorIcon />} size="small" sx={chipStyles} />;
      case 'Loading':
        return <CircularProgress size={20} color="inherit" sx={{ marginLeft: '17px' }} />;
      default:
        return <Chip label="Unknown" color="default" size="small" sx={chipStyles} />;
    }
  };
  
 const handleExpandClick = async (approvalID: number, userId: number, groupProductId: number) => {
  const newExpandedGroups = expandedGroups.includes(approvalID)
    ? expandedGroups.filter((id) => id !== approvalID)
    : [...expandedGroups, approvalID];

  setExpandedGroups(newExpandedGroups);

  if (newExpandedGroups.includes(approvalID)) {
    const statusesAndFileNames = await fetchGroupStatuses(userId, groupProductId);
    setGroupSurveyStatuses((prev) => ({ ...prev, ...statusesAndFileNames.statuses }));
    setUploadedFileNamesForSurveyGroups((prev) => ({ ...prev, ...statusesAndFileNames.fileNames }));
  } else {
    setGroupSurveyStatuses({});
    setUploadedFileNamesForSurveyGroups({});
  }
};

  
  const getSurveyOrGroupName = (approval) => {
    if (approval.SurveyID) {
      const survey = surveys.find((s) => s.surveyID === approval.SurveyID);
      return survey ? survey.surveyName : 'No survey data found';
    } else if (approval.SurveyProductID) {
      const surveyGroup = surveyGroups.find((group) => group.surveyProductID === approval.SurveyProductID);
      return surveyGroup ? surveyGroup.surveyProductName : 'No survey group data found';
    }
    return 'No survey data found';
  };


  const getMatchingSurvey = (fileName: string, survey: string, UserEmail: string) => {
    const fileNameWithoutExtension = fileName.replace('.pdf', '');
    const fileNameParts = fileNameWithoutExtension.split(' - ');
    const extractedSurveyName = fileNameParts.slice(1).join(' - ').trim().toLowerCase();
    const extractedUsername = fileNameParts[0].trim().toLowerCase();
    const userEmailParts = UserEmail.split('@');
    const userEmailUsername = userEmailParts[0].trim().toLowerCase();
  
    if (extractedSurveyName === survey.toLowerCase() && userEmailUsername.includes(extractedUsername)) {
      return true;
    }
  
    return null;
  };


  const handleFileInputChange = async (event: ChangeEvent<HTMLInputElement>, userId: number, surveyType: string, UserEmail: string) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      SetLoadingAfterAssignSurvey(true);
      try {
        let newUploadedFileNames: string[] = [];
        if (surveyType === 'surveyFile') {
          const uploadPromises = Array.from(files).map(async (file) => {
            const fileName = file.name.toLowerCase();
            const surveyName = surveyNameForUpload.toLowerCase();
            const matchingSurvey = getMatchingSurvey(fileName, surveyName, UserEmail);
            if (matchingSurvey) {
              const fileUrl = await uploadFileToAzure(file, userId, 'user');
              newUploadedFileNames.push(file.name);
              return fileUrl;
            } else {
              throw new Error('Invalid file upload: File does not match the assigned survey');
            }
          });
  
          await Promise.all(uploadPromises);
          setUploadedFileNames((prev) => ({ ...prev, [userId]: [...newUploadedFileNames] }));
        } else if (surveyType === 'surveyGroupFile') {
            const uploadPromises = Array.from(files).map(async (file) => {
              const fileName = file.name.toLowerCase();
              const surveyName = surveyNameForUpload.toLowerCase();
              const matchingSurvey = getMatchingSurvey(fileName, surveyName, UserEmail);
              if (matchingSurvey) {
                const fileUrl = await uploadFileToAzure(file, userId, 'user');
                newUploadedFileNames.push(file.name);
                return fileUrl;
              } else {
                throw new Error('Invalid file upload: File does not match the assigned survey');
              }
            });
    
            await Promise.all(uploadPromises);
            setUploadedFileNamesForSurveyGroups((prev) => ({ ...prev, [userId]: [...newUploadedFileNames] }));
        }
        setAlertSeverity('success');
        setSnackbarMessage('Assessment report uploaded and generated successfully');
      } catch (error) {
        setAlertSeverity('error');
        setSnackbarMessage(error.message);
      } finally {
        setSnackbarOpen(true);
        SetLoadingAfterAssignSurvey(false);
        if (surveyType === 'surveyFile' && fileInputSurveyRef.current) {
          fileInputSurveyRef.current.value = null;
        } else if (surveyType === 'surveyGroupFile' && fileInputSurveyGroupRef.current) {
          fileInputSurveyGroupRef.current.value = null;
        }
      }
    }
  };
  

  const handleUploadClick = (surveyType: string, surveyName: string) => {
    if (surveyType === 'surveyFile' && fileInputSurveyRef.current) {
      fileInputSurveyRef.current.click();
      setSurveyNameForUpload(surveyName);
    } else if (surveyType === 'surveyGroupFile' && fileInputSurveyGroupRef.current) {
      fileInputSurveyGroupRef.current.click();
      setSurveyNameForUpload(surveyName);
    }
  };
  
  
  const handleAssignSurvey = async (userId: number, surveyId: number | null) => {
    const user = users.find((user) => user.UserID === userId);
    const survey = surveys.find((survey) => survey.surveyID === surveyId);
    if (user) {
      SetLoadingAfterAssignSurvey(true)
      try {
       const response= await CreateUserSurveyApproval({
          UserID: userId,
          SurveyID: surveyId || null,
          ApprovalType: 'User Individual Survey Approval'
        });
        if(response.data.success===true)
          {
            setSearchSurveys((prev) => ({ ...prev, [userId]: { survey: '', group: '' } }));
            setAlertSeverity('success');
            setSnackbarMessage(surveyId !== null
          ? `Survey "${survey?.surveyName}" assigned successfully! An email has been sent to ${user.UserEmail} with survey details.`
          : `Removed survey from user ${user.name}`);
          }
          else
          {
            setAlertSeverity('error');
            setSnackbarMessage(`Survey "${survey?.surveyName}" already assigned to the user ${user.UserEmail}`)
          }       
        fetchAssignedSurveys(userId);
      } catch (error) {
        console.error('Error assigning survey:', error);
        setAlertSeverity('error');
        setSnackbarMessage('Failed to assign survey.');
      }
     finally {
      SetLoadingAfterAssignSurvey(false);
    }
      setSnackbarOpen(true);
    }
  };

  const handleAssignSurveyGroup = async (userId: number, surveyGroupId: number | null) => {
    const user = users.find((user) => user.UserID === userId);
    const surveyGroup = surveyGroups.find((group) => group.surveyProductID === surveyGroupId);
    if (user) {
      SetLoadingAfterAssignSurvey(true);
      try {
        const response=await CreateUserSurveyApproval({
          UserID: userId,
          SurveyProductID: surveyGroupId || null,
          ApprovalType: 'User Survey Group Approval'
        });
        if(response.data.success===true)
          {
           setSearchSurveys((prev) => ({ ...prev, [userId]: { survey: '', group: '' } }));
           setAlertSeverity('success');
           setSnackbarMessage(surveyGroupId !== null
          ? `Survey Group "${surveyGroup?.surveyProductName}" assigned successfully! An email has been sent to ${user.UserEmail} with survey group details.`
          : `Removed survey group from user ${user.name}`);
          }
          else
          {
            setAlertSeverity('error');
            setSnackbarMessage(`SurveyGroup "${surveyGroup?.surveyProductName}" already assigned to the user ${user.UserEmail}`)
          }
          fetchAssignedSurveys(userId); 
      } catch (error) {
        console.error('Error assigning survey group:', error);
        setAlertSeverity('error');
        setSnackbarMessage('Failed to assign survey group.');
      }
      finally {
        SetLoadingAfterAssignSurvey(false);
      }
      setSnackbarOpen(true);
    }
  };

  const fetchAssignedSurveys = async (userId: number) => {
    setLoadingUserSurveys((prev) => ({ ...prev, [userId]: true }));
    try {
      const response = await getAssignedSurveysForUser(userId);
      const individualSurveys = response.data.filter((item: UsersApproval) => item.SurveyID !== null);
      const surveyGroups = response.data.filter((item: UsersApproval) => item.SurveyProductID !== null);
      
      setAssignedSurveys((prev) => ({ ...prev, [userId]: individualSurveys }));
      setAssignedSurveyGroups((prev) => ({ ...prev, [userId]: surveyGroups }));
    } catch (error) {
      console.error('Error fetching assigned surveys:', error);
    }
    setLoadingUserSurveys((prev) => ({ ...prev, [userId]: false }));
  };

  const handleDeleteSurvey = (approvalId: number, surveyName: string, user: UsersApproval) => {
    setDeleteDetails({ approvalId, surveyName, user });
    setOpenDeleteDialog(true);
  };

  const confirmDeleteSurvey = async () => {
    if (deleteDetails) {
      try {
        await removeAssignedSurvey(deleteDetails.approvalId,deleteDetails.surveyName);
        setAlertSeverity('success');
        setSnackbarMessage(`Survey ${deleteDetails.surveyName} removed from user ${deleteDetails.user.UserEmail}`);
        fetchAssignedSurveys(deleteDetails.user.UserID);
      } catch (error) {
        console.error('Error removing survey:', error);
        setAlertSeverity('error');
        setSnackbarMessage('Failed to remove survey.');
      }
      setSnackbarOpen(true);
      setOpenDeleteDialog(false);
    }
  };

  const handleSearchSurveyChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, userId: number) => {
    const { value } = event.target;
    setSearchSurveys((prev) => ({
      ...prev,
      [userId]: { ...prev[userId], survey: value }
    }));
  };
  
  const handleSearchSurveyGroupChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, userId: number) => {
    const { value } = event.target;
    setSearchSurveys((prev) => ({
      ...prev,
      [userId]: { ...prev[userId], group: value }
    }));
  };
  
  const handleRowToggle = (userId: number) => {
    setExpandedRows((prev) => {
      const newState = { ...prev, [userId]: !prev[userId] };
      Object.keys(newState).forEach((key) => {
        if (key !== userId.toString()) {
          newState[parseInt(key)] = false;
        }
      });
  
      if (newState[userId]) {
        fetchAssignedSurveys(userId);
      } else {
        const { [userId]: removed, ...rest } = assignedSurveys;
        const { [userId]: removedGroups, ...restGroups } = assignedSurveyGroups;
        setAssignedSurveys(rest);
        setAssignedSurveyGroups(restGroups);
      }
  
      return newState;
    });
  };
  
  const fetchSurveyStatus = async (UserID: number, SurveyID: number) => {
    try {
      const statusResponse = await getSurveyStatus(UserID, SurveyID);
      return statusResponse.status;
    } catch (error) {
      console.error('Error fetching survey status:', error);
      return 'Error';
    }
  };
  

  const getFilteredSurveys = (userId: number) => {
    const searchQuery = searchSurveys[userId]?.survey?.toLowerCase() || '';
    if (!searchQuery) return surveys;
    return surveys.filter((survey) => survey.surveyName.toLowerCase().includes(searchQuery));
  };
  
  const getFilteredSurveyGroups = (userId: number) => {
    const searchQuery = searchSurveys[userId]?.group?.toLowerCase() || '';
    if (!searchQuery) return surveyGroups;
    return surveyGroups.filter((group) => group.surveyProductName.toLowerCase().includes(searchQuery));
  };
  


  const filteredUsers = useMemo(() => {
    const lowercasedSearchKey = (searchKey || '').toLowerCase();

    return users.filter((user) => {
      const userName = (user.name || '').toLowerCase();
      const userEmail = (user.UserEmail || '').toLowerCase();

      return userName.includes(lowercasedSearchKey) || userEmail.includes(lowercasedSearchKey);
    });
  }, [users, searchKey]);

  return (
    <Paper elevation={0} sx={{ padding: 2 }}>
      {loadingAfterAssignSurvey && (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
      )}
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#2442AF' }}>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}></TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>User Id</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>User Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>User Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Assign Individual Survey</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Assign Survey Group</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {loading ? (
              Array.from(Array(10).keys()).map((index) => (
                <TableRow key={index}>
                  <TableCell colSpan={6}>
                    <Skeleton variant="rectangular" height={50} animation="wave" />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                <React.Fragment key={`${user.UserID}-${user.RoleID}`}>
                  <TableRow
                    sx={{
                      '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                      '&:hover': { backgroundColor: '#e3f2fd', cursor: 'pointer' }
                    }}>
                    <TableCell>
                      <IconButton onClick={() => handleRowToggle(user.UserID)}>
                        {expandedRows[user.UserID] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </TableCell>
                    <TableCell>{user.UserID}</TableCell>
                    <TableCell>
                      {user.name.trim() ? (
                        <Tooltip title={user.name.trim()} arrow>
                          <span style={{ display: 'inline-block', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            <AccountCircle sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                            <span style={{ color: theme.palette.text.primary }}>
                              {user.name.trim()}
                            </span>
                          </span>
                        </Tooltip>
                      ) : (
                        <span style={{ display: 'inline-block', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <AccountCircle sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                          <span style={{ color: theme.palette.text.disabled }}>
                            No Name
                          </span>
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      
                      <Email sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                      {user.UserEmail || 'No Email'}
                    </TableCell>
                    <TableCell>
                    <TextField
                      value={searchSurveys[user.UserID]?.survey || ''}
                      onChange={(event) => handleSearchSurveyChange(event, user.UserID)}
                      placeholder="Search Surveys"
                      variant="outlined"
                      sx={{ width: isSmallScreen ? '120%' : '170px' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
  {searchSurveys[user.UserID]?.survey && (
    <Box sx={{ position: 'absolute', zIndex: 1, backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', width: '100%', maxWidth: '250px' }}>
      {getFilteredSurveys(user.UserID).length > 0 ? (
        <Box sx={{ overflowY: 'auto' }}>
          {getFilteredSurveys(user.UserID).slice(0, 5).map((survey) => (
            <Tooltip key={survey.surveyID} title={survey.surveyName} arrow>
              <MenuItem
                key={survey.surveyID}
                value={survey.surveyID}
                onClick={() => handleAssignSurvey(user.UserID, survey.surveyID)}
                sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <Assessment sx={{ color: '#4caf50', verticalAlign: 'middle', marginRight: 1 }} />
                <Typography noWrap sx={{ flex: 1 }}>{survey.surveyName}</Typography>
              </MenuItem>
            </Tooltip>
          ))}
        </Box>
      ) :  (
        <Box sx={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography color="error" sx={{ textAlign: 'center' }}>
            No surveys found
          </Typography>
        </Box>
      )}
    </Box>
  )}
  </TableCell>

  <TableCell>
  <TextField
    value={searchSurveys[user.UserID]?.group || ''}
    onChange={(event) => handleSearchSurveyGroupChange(event, user.UserID)}
    placeholder="Search Survey Groups"
    variant="outlined"
    sx={{ width: isSmallScreen? '120%' : '215px' }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      ),
    }}
  />
  {searchSurveys[user.UserID]?.group && (
    <Box sx={{ position: 'absolute', zIndex: 1, backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', width: '100%', maxWidth: '250px' }}>
      {getFilteredSurveyGroups(user.UserID).length > 0? (
        <Box sx={{ overflowY: 'auto' }}>
          {getFilteredSurveyGroups(user.UserID).slice(0, 5).map((group) => (
            <Tooltip key={group.surveyProductID} title={group.surveyProductName} arrow>
              <MenuItem
                key={group.surveyProductID}
                value={group.surveyProductID}
                onClick={() => handleAssignSurveyGroup(user.UserID, group.surveyProductID)}
                sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <Assessment sx={{ color: '#4caf50', verticalAlign: 'iddle', marginRight: 1 }} />
                <Typography noWrap sx={{ flex: 1 }}>{group.surveyProductName}</Typography>
              </MenuItem>
            </Tooltip>
          ))}
        </Box>
      ) : (
        <Box sx={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography color="error" sx={{ textAlign: 'center' }}>
            No survey groups found
          </Typography>
        </Box>
      )}
    </Box>
  )}
</TableCell>
                </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={expandedRows[user.UserID]} timeout="auto" unmountOnExit>
  <Box sx={{ margin: 1 }}>
    {loadingUserSurveys[user.UserID] ? (
      <>
        <Box>
          <Skeleton animation="wave" height={30} />
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '15%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '55%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '10%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '10%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '15%' }}><Skeleton animation="wave" height={30} /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={5}><Skeleton animation="wave" height={40} /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box>
          <Skeleton animation="wave" height={30} />
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '15%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '55%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '10%' }}><Skeleton animation="wave" height={30} /></TableCell>
              <TableCell style={{ width: '10%' }}></TableCell>
              <TableCell style={{ width: '15%' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={5}><Skeleton animation="wave" height={40} /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    ) : (
      <>
        <Box
          sx={{
            mb: 2,
            backgroundColor: '#f0f8ff',
            padding: 2,
            borderRadius: 1,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0'
          }}
        >
          <Typography variant="h5" gutterBottom component="div" color="textDark">
            Assigned Surveys
          </Typography>
          <Table size="small" aria-label="assigned-surveys">
            <TableHead>
              <TableRow>
              <TableCell width="20%">SurveyId</TableCell>
              <TableCell width="50%">SurveyName</TableCell>
              <TableCell width="5%">Action</TableCell>
              <TableCell width="10%">Status</TableCell>
              <TableCell width="15%" >Upload Survey</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {assignedSurveys[user.UserID] && assignedSurveys[user.UserID].length > 0 ? (
    assignedSurveys[user.UserID].map((survey) => {
      const status = surveyStatuses[`${user.UserID}-${survey.SurveyID}`] || 'Loading';
      const uploadedFiles = uploadedFileNames[user.UserID] || [];
      const isUploadEnabled = status === 'Completed' && uploadedFiles.length > 0;

      return (
        <TableRow key={survey.ApprovalID}>
          <TableCell>{survey.SurveyID}</TableCell>
          <TableCell>
            <Tooltip title={getSurveyOrGroupName(survey)}>
              <span style={{ display: 'inline-block', maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {getSurveyOrGroupName(survey)}
              </span>
            </Tooltip>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="delete"
              color='error'
              onClick={() => handleDeleteSurvey(survey.ApprovalID, getSurveyOrGroupName(survey), user)}
            >
              <Delete />
            </IconButton>
          </TableCell>
          <TableCell>
            {getStatusChip(status)}
          </TableCell>
          <TableCell>
  {uploadedFiles.filter((fileName) => {
    const fileNameWithoutExtension = fileName.replace('.pdf', '');
    const fileNameParts = fileNameWithoutExtension.split(' - ');
    const extractedSurveyName = fileNameParts.slice(1).join(' - ').trim().toLowerCase();
    return extractedSurveyName === getSurveyOrGroupName(survey).toLowerCase();
  }).length > 0 ? (
    uploadedFiles.filter((fileName) => {
      const fileNameWithoutExtension = fileName.replace('.pdf', '');
      const fileNameParts = fileNameWithoutExtension.split(' - ');
      const extractedSurveyName = fileNameParts.slice(1).join(' - ').trim().toLowerCase();
      return extractedSurveyName === getSurveyOrGroupName(survey).toLowerCase();
    }).map((fileName) => (
      <Tooltip title={fileName + '.pdf'} arrow>
        <span style={{ 
          display: 'inline-block', 
          maxWidth: '150px', 
          overflow: 'hidden', 
          textOverflow: 'ellipsis', 
          whiteSpace: 'nowrap', 
          color: '#3f51b5', 
          fontWeight: 'bold', 
          fontSize: '14px' 
        }}>
          {fileName}
        </span>
      </Tooltip>
    ))
  ) : (
    <>
      <Input
        id={`upload-input-${survey.ApprovalID}`}
        type="file"
        inputProps={{ accept: '.pdf', multiple: false }}
        onChange={(event) => handleFileInputChange(event as ChangeEvent<HTMLInputElement>, user.UserID, surveyType.survey, user.UserEmail)}
        sx={{ display: 'none' }}
         inputRef={fileInputSurveyRef}
         disabled={!isUploadEnabled}
      />
      <IconButton
        aria-label="upload"
        onClick={() => handleUploadClick(surveyType.survey, getSurveyOrGroupName(survey))}
        sx={{ marginLeft: '30px' }}
        disabled={!isUploadEnabled} 
      >
        <CloudUpload />
      </IconButton>
    </>
  )}
</TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow>
      <TableCell colSpan={6}>
        <Typography color={'error'} align='center'>No assigned surveys found for this user</Typography>
      </TableCell>
    </TableRow>
  )}
</TableBody>
          </Table>
        </Box>

        <Box
  sx={{
    backgroundColor: '#f0f8ff',
    padding: 2,
    borderRadius: 1,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    border: '1px solid #e0e0e0'
  }}
>
  <Typography variant="h5" gutterBottom component="div" color="textDark">
    Assigned Survey Groups
  </Typography>
  <Table size="small" aria-label="assigned-survey-groups">
    <TableHead>
      <TableRow>
        <TableCell width="20%">Survey Product ID</TableCell>
        <TableCell width="51%">Survey Product Name</TableCell>
        <TableCell>Action</TableCell>
        <TableCell width="30%"></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {assignedSurveyGroups[user.UserID] && assignedSurveyGroups[user.UserID].length > 0 ? (
        assignedSurveyGroups[user.UserID].map((group) => (
          <React.Fragment key={group.ApprovalID}>
            <TableRow>
              <TableCell>{group.SurveyProductID}</TableCell>
              <TableCell>
                {getSurveyOrGroupName(group)}
                <IconButton
                  aria-label="expand"
                  onClick={() => handleExpandClick(group.ApprovalID,group.UserID,group.SurveyProductID)}
                >
                  {expandedGroups.includes(group.ApprovalID) ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton
                  aria-label="delete"
                  color='error'
                  onClick={() => handleDeleteSurvey(group.ApprovalID, getSurveyOrGroupName(group), user)}
                >
                  <Delete />
                </IconButton>
              </TableCell>
              <TableCell />
            </TableRow>
            {expandedGroups.includes(group.ApprovalID) && (
              <TableRow key={group.ApprovalID}>
                <TableCell colSpan={4}>
                  <Box sx={{ backgroundColor: '#fff', padding: 2, borderRadius: 1, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', border: '1px solid #ddd' }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell width="20%">SURVEYID</TableCell>
                          <TableCell width="50%">SURVEYNAME</TableCell>
                          <TableCell width="5%"></TableCell>
                          <TableCell width="10%">STATUS</TableCell>
                          <TableCell width="15%" >UPLOAD SURVEYS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
  {surveys.filter((survey) => survey.surveyProductID === group.SurveyProductID).map((survey) => {
    const status = groupSurveyStatuses[`${user.UserID}-${survey.surveyID}`] || 'Loading';
    const isUploadEnabled = status === 'Completed';
    const uploadedFiles = uploadedFileNamesForSurveyGroups[user.UserID] || [];

    return (
      <TableRow key={survey.surveyID}>
        <TableCell>{survey.surveyID}</TableCell>
        <TableCell>
          <Tooltip title={survey.surveyName}>
            <span style={{ display: 'inline-block', maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {survey.surveyName}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          {getStatusChip(status)}
        </TableCell>
        <TableCell>
          {uploadedFiles.filter((fileName) => {
            const fileNameWithoutExtension = fileName.replace('.pdf', '');
            const fileNameParts = fileNameWithoutExtension.split(' - ');
            const extractedSurveyName = fileNameParts.slice(1).join(' - ').trim().toLowerCase();
            return extractedSurveyName === survey.surveyName.toLowerCase();
          }).length > 0 ? (
            uploadedFiles.filter((fileName) => {
              const fileNameWithoutExtension = fileName.replace('.pdf', '');
              const fileNameParts = fileNameWithoutExtension.split(' - ');
              const extractedSurveyName = fileNameParts.slice(1).join(' - ').trim().toLowerCase();
              return extractedSurveyName === survey.surveyName.toLowerCase();
            }).map((fileName) => (
              <Tooltip title={fileName + '.pdf'} arrow>
                <span style={{
                  display: 'inline-block',
                  maxWidth: '150px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: '#3f51b5',
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}>
                  {fileName}
                </span>
              </Tooltip>
            ))
          ) : (
            <>
              <Input
                id={`upload-input-${survey.surveyID}`}
                type="file"
                inputProps={{ accept: '.pdf', multiple: false }}
                onChange={(event) => handleFileInputChange(event as ChangeEvent<HTMLInputElement>, user.UserID, surveyType.surveyGroup, user.UserEmail)}
                sx={{ display: 'none' }}
                inputRef={fileInputSurveyGroupRef}
                disabled={!isUploadEnabled}
              />
              <IconButton
                aria-label="upload"
                onClick={() => handleUploadClick(surveyType.surveyGroup, survey.surveyName)}
                sx={{ marginLeft: '30px' }}
                disabled={!isUploadEnabled}
              >
                <CloudUpload />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
    );
  })}
</TableBody>
                    </Table>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={4}><Typography color={'error'} align='center'>No assigned survey groups found for this user</Typography></TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
</Box>
      </>
    )}
  </Box>
</Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
  
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
      />

<WarnConfirm
  open={openDeleteDialog}
  message={`Are you sure you want to delete the survey ${deleteDetails?.surveyName} from user ${deleteDetails?.user.UserEmail}?`}
  okText="Delete"
  cancelText="Cancel"
  onConfirm={confirmDeleteSurvey}
  onClose={() => setOpenDeleteDialog(false)}
/>
      <AlertSnackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        severity={alertSeverity}
        message={snackbarMessage}
      />
    </Paper>
  );
};

export default AssignSurveysToIndividualUsers;
  

