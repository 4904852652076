import React, { useState } from 'react';
import {
  Box,
  Container,
  Tabs,
  Tab,
  Card,
  TextField,
  IconButton,
  Stack,
  Divider,
  styled,
  InputAdornment,
  debounce,
  useTheme,
  Button,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import ManageConsultantAdmin from './ManageConsultantAdmin';
import { createConsultantAdmin, createUser,deleteUserFromAuth0 } from 'src/services/apiService';
import AlertSnackbar from 'src/components/AlertSnackbar';

const ConsultantAdminController: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>('');
  const [password, setPassword] = useState<string>('CoeIndex@123');
  const [consultingAdminEmail, setConsultingAdminEmail] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
  const [isConsultingAdminValid, setIsConsultingAdminValid] = useState<boolean>(true);
  const [isConsultingAdminEmptyError, setIsConsultingAdminEmptyError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    setSearchKey('');
    setConsultingAdminEmail('');
    setIsConsultingAdminValid(true);
    setIsConsultingAdminEmptyError(false);
  };

  const handleClearSearch = () => {
    setSearchKey('');
  };

  const SearchIconWrapper = styled(SearchTwoToneIcon)(
    ({ theme }) => `
      color: ${theme.palette.text.disabled};
    `
  );

  const debouncedHandleSearch = debounce((value: string) => {
    setSearchKey(value);
  }, 100);

  const handleAddConsultingAdmin = async () => {
    if (!consultingAdminEmail) {
      setIsConsultingAdminEmptyError(true);
      return;
    }
  
    if (!validateEmail(consultingAdminEmail)) {
      setIsConsultingAdminValid(false);
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const createUserResponse = await createUser(consultingAdminEmail);
      const newEmail = createUserResponse.data.email;
      const userPassword = createUserResponse.password;
  
      if (newEmail && createUserResponse.success) {
        await new Promise(resolve => setTimeout(resolve, 1500));
  
        let attempts = 0;
        const maxAttempts = 2;
        let success = false;
  
        while (attempts < maxAttempts && !success) {
          try {
            await createConsultantAdmin(newEmail,userPassword);
            success = true;
          } catch (error) {
            attempts++;
            if (attempts >= maxAttempts) {
              await deleteUserFromAuth0(createUserResponse.data.user_id);
              throw error;
            }
            await new Promise(resolve => setTimeout(resolve, 1500));
          }
        }
  
        if (success) {
          setSnackbarMessage('ConsultantAdmin created successfully. An email has been sent to the user for further instructions');
          setAlertSeverity('success');
          setConsultingAdminEmail('');
          setReloadTrigger(prev => !prev);
        }
      }
    } catch (error) {
      console.error('Error adding user:', error);
      const errorMessage = error.response?.data?.error_description || error.response?.data?.message || 'Error adding user. Please try again.';
      setSnackbarMessage(errorMessage);
      setAlertSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setIsSubmitting(false);
    }
  };
  

  const handleClearConsultingAdminEmail = () => {
    setConsultingAdminEmail('');
    setIsConsultingAdminValid(true);
    setIsConsultingAdminEmptyError(false);
  };

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Consultant Admin Management</title>
      </Helmet>
      <Box my={4}>
        <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
          <Tab
            label="Manage Consultant Admins"
            sx={{
              backgroundColor: currentTab === 0 ? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 0 ? '#fff' : '#777',
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
          <Tab
            label="Add Consultant Admin"
            sx={{
              backgroundColor: currentTab === 1 ? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 1 ? '#fff' : '#777',
            }}
          />
        </Tabs>

        {currentTab === 0 && (
          <>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                width: '100%',
                mt: 2,
                mb: 2,
              }}
            >
              <Box display={{ xs: 'none', lg: 'flex' }} ml={2} flexShrink={1}>
                <SearchIconWrapper />
              </Box>
              <Stack
                sx={{
                  p: 2,
                  flex: 1,
                }}
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <TextField
                  sx={{
                    m: 0,
                  }}
                  onChange={(o) => debouncedHandleSearch(o.target.value)}
                  placeholder={t('Search by name, email ...')}
                  value={searchKey}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <>
                        {searchKey && (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearSearch} edge="end">
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
              </Stack>
            </Card>
            <Box mt={2}>
              <Card sx={{ marginBottom: 2 }}>
                <ManageConsultantAdmin searchKey={searchKey} />
              </Card>
            </Box>
          </>
        )}

        {currentTab === 1 && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  label={t('Add Consultant Admin')}
                  fullWidth
                  variant="outlined"
                  value={consultingAdminEmail}
                  onChange={(e) => {
                    setConsultingAdminEmail(e.target.value);
                    setIsConsultingAdminValid(validateEmail(e.target.value));
                    setIsConsultingAdminEmptyError(false);
                  }}
                  error={!isConsultingAdminValid || isConsultingAdminEmptyError}
                  helperText={
                    (!isConsultingAdminValid && t('Invalid email format')) ||
                    (isConsultingAdminEmptyError && t('Please enter an email'))
                  }
                  sx={{ mr: 2, flex: 1 }}
                  InputProps={{
                      endAdornment: (
                             <>
                              {consultingAdminEmail && (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClearConsultingAdminEmail} edge="end">
                                       <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                               )}
                             </>
                       ),
                    }}
             />
              </Stack>
              <Box mt={2}>
                <Button variant="contained" color="primary" onClick={handleAddConsultingAdmin} disabled={isSubmitting}>
                  {t('Add')}
                  {isSubmitting && <CircularProgress size={24} sx={{ ml: 1 }} />}
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
      <AlertSnackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        severity={alertSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default ConsultantAdminController;
