import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Tabs,
  Tab,
  Card,
  TextField,
  IconButton,
  Stack,
  Divider,
  styled,
  InputAdornment,
  debounce,
  useTheme,
  Button,
  CardContent,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import ManageCompanyAdmin from './ManageCompanyAdmin';
import AlertSnackbar from 'src/components/AlertSnackbar';
import { createCompanyAdmin, createCompanyUser, createUser, deleteUserFromAuth0, getCompanies } from 'src/services/apiService';
import { Companies } from 'src/services/apiService/response-models';

const CompanyAdminController: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>('');
  const [companyUserSearchKey, setCompanyUserSearchKey] = useState<string>('');
  const [companyAdminEmail, setCompanyAdminEmail] = useState<string>('');
  const [companyUserEmail, setCompanyUserEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('CoeIndex@123');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [isCompanyAdminEmailValid, setIsCompanyAdminEmailValid] = useState<boolean>(true);
  const [isCompanyAdminEmailEmptyError, setIsCompanyAdminEmailEmptyError] = useState<boolean>(false);
  const [isCompanyUserEmailValid, setIsCompanyUserEmailValid] = useState<boolean>(true);
  const [isCompanyUserEmailEmptyError, setIsCompanyUserEmailEmptyError] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [isCompanyNameValid, setIsCompanyNameValid] = useState<boolean>(true);
  const [isCompanyNameEmptyError, setIsCompanyNameEmptyError] = useState<boolean>(false);
  const [suggestedCompanies, setSuggestedCompanies] = useState<Companies[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    setSearchKey('');
    setCompanyUserSearchKey('');
    setCompanyAdminEmail('');
    setCompanyUserEmail('');
    setCompanyName('');
    setIsCompanyAdminEmailEmptyError(false);
    setIsCompanyUserEmailEmptyError(false);
    setIsCompanyNameEmptyError(false);
    setIsCompanyAdminEmailValid(true);
    setIsCompanyUserEmailValid(true);
    setIsCompanyNameValid(true);
  };

  const handleClearSearch = () => {
    setSearchKey('');
  };

  const SearchIconWrapper = styled(SearchTwoToneIcon)(
    ({ theme }) => ({
      color: theme.palette.text.disabled,
    })
  );

  const debouncedHandleSearch = debounce((value: string) => {
    setSearchKey(value);
  }, 100);

  // const debouncedHandleCompanyUserSearch = debounce((value: string) => {
  //   setCompanyUserSearchKey(value);
  //   fetchSuggestedCompanies(value);
  // }, 100);

  // const fetchSuggestedCompanies = async (companyName: string) => {
  //   try {
  //     const response = await getCompanies();
  //     const suggestedCompanies = response.data.filter((company) =>
  //       company.CompanyName.toLowerCase().includes(companyName.toLowerCase())
  //     );
  //     setSuggestedCompanies(suggestedCompanies);
  //   } catch (error) {
  //     console.error('Error fetching suggested companies:', error);
  //   }
  // };

  const handleAddCompanyAdmin = async () => {
    if (!companyAdminEmail) {
      setIsCompanyAdminEmailEmptyError(true);
      return;
    }
  
    if (!validateEmail(companyAdminEmail)) {
      setIsCompanyAdminEmailValid(false);
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const createUserResponse = await createUser(companyAdminEmail);
      const newEmail = createUserResponse.data.email;
      const userPassword = createUserResponse.password;
  
      if (newEmail && createUserResponse.success) {
        await new Promise(resolve => setTimeout(resolve, 1500)); 
  
        let attempts = 0;
        const maxAttempts = 2;
        let success = false;
  
        while (attempts < maxAttempts && !success) {
          try {
            await createCompanyAdmin(newEmail,userPassword);
            success = true;
          } catch (error) {
            attempts++;
            if (attempts >= maxAttempts) {
              await deleteUserFromAuth0(createUserResponse.data.user_id);
              throw error;
            }
            await new Promise(resolve => setTimeout(resolve, 1500)); 
          }
        }
  
        if (success) {
          setSnackbarMessage('CompanyAdmin created successfully. An email has been sent to the user for further instructions');
          setAlertSeverity('success');
          setCompanyAdminEmail('');
        }
      }
    } catch (error) {
      console.error('Error adding user:', error);
      const errorMessage = error.response?.data?.error_description || error.response?.data?.message || 'Error adding user. Please try again.';
      setSnackbarMessage(errorMessage);
      setAlertSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setIsSubmitting(false);
    }
  };
  
  const handleAddCompanyUser = async () => {
    if (!companyUserEmail || companyUserEmail.trim() === '') {
      setIsCompanyUserEmailEmptyError(true);
      return;
    }
  
    if (!validateEmail(companyUserEmail)) {
      setIsCompanyUserEmailValid(false);
      return;
    }
  
    if (!companyName) {
      setIsCompanyNameEmptyError(true);
      return;
    }
  
    if (companyName.trim() === '') {
      setIsCompanyNameValid(false);
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const createUserResponse = await createUser(companyUserEmail);
      const newEmail = createUserResponse.data.email;
      const userPassword = createUserResponse.password;
  
      if (newEmail && createUserResponse.success) {
        await new Promise(resolve => setTimeout(resolve, 1500));
  
        let attempts = 0;
        const maxAttempts = 2;
        let success = false;
  
        while (attempts < maxAttempts && !success) {
          try {
            await createCompanyUser(newEmail, companyName, userPassword);
            success = true;
          } catch (error) {
            attempts++;
            if (attempts >= maxAttempts) {
              await deleteUserFromAuth0(createUserResponse.data.user_id);
              throw error;
            }
            await new Promise(resolve => setTimeout(resolve, 1500));
          }
        }
  
        if (success) {
          setSnackbarMessage('Company User created successfully. An email has been sent to the user for further instructions');
          setAlertSeverity('success');
          setCompanyUserEmail('');
          setCompanyName('');
        }
      }
    } catch (error) {
      console.error('Error adding user:', error);
      const errorMessage = error.response?.data?.error_description || error.response?.data?.message || 'Error adding user. Please try again.';
      setSnackbarMessage(errorMessage);
      setAlertSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setIsSubmitting(false);
    }
  };
  

  const handleClearCompanyAdminEmail = () => {
    setCompanyAdminEmail('');
    setIsCompanyAdminEmailValid(true);
    setIsCompanyAdminEmailEmptyError(false);
  };

  const handleClearCompanyUserEmail = () => {
    setCompanyUserEmail('');
    setIsCompanyUserEmailValid(true);
    setIsCompanyUserEmailEmptyError(false);
  };

  const handleClearCompanyName = () => {
    setCompanyName('');
    setIsCompanyNameValid(true);
    setIsCompanyNameEmptyError(false);
    setSuggestedCompanies([]);
  };

  const handleSelectCompany = (company: Companies) => {
    setCompanyName(company.CompanyName);
    setSuggestedCompanies([]);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Company Admin Management</title>
      </Helmet>
      <Box my={4}>
        <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
          <Tab
            label="Manage Company Admins"
            sx={{
              backgroundColor: currentTab === 0? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 0? '#fff' : '#777',
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
          <Tab
            label="Add Company Admin"
            sx={{
              backgroundColor: currentTab === 1? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 1? '#fff' : '#777',
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
          <Tab
            label="Add Company User"
            sx={{
              backgroundColor: currentTab === 2? theme.palette.primary.main : '#ebebeb',
              color: currentTab === 2? '#fff' : '#777',
            }}
          />
        </Tabs>

        {currentTab === 0 && (
          <>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                width: '100%',
                mt: 2,
                mb: 2,
              }}
            >
              <Box display={{ xs: 'none', lg: 'flex' }} ml={2} flexShrink={1}>
                <SearchIconWrapper />
              </Box>
              <Stack
                sx={{
                  p: 2,
                  flex: 1,
                }}
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <TextField
                  sx={{
                    m: 0,
                  }}
                  onChange={(o) => debouncedHandleSearch(o.target.value)}
                  placeholder={t('Search by name, email ...')}
                  value={searchKey}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <>
                        {searchKey && (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearSearch} edge="end">
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
              </Stack>
            </Card>
            <Card sx={{ marginBottom: 2 }}>
              <ManageCompanyAdmin searchKey={searchKey} />
            </Card>
          </>
        )}

        {currentTab === 1 && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField
                  label={t('Add Company Admin')}
                  fullWidth
                  type="email"
                  variant="outlined"
                  value={companyAdminEmail}
                  onChange={(e) => {
                    setCompanyAdminEmail(e.target.value);
                    setIsCompanyAdminEmailValid(validateEmail(e.target.value));
                    setIsCompanyAdminEmailEmptyError(false);
                  }}
                  error={!isCompanyAdminEmailValid || isCompanyAdminEmailEmptyError}
                  helperText={
                    (!isCompanyAdminEmailValid && 'Invalid email format') ||
                    (isCompanyAdminEmailEmptyError && 'Please enter an email')
                  }
                  sx={{ mr: 2, flex: 1 }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {companyAdminEmail && (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearCompanyAdminEmail} edge="end">
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
              </Stack>
              <Box mt={2}>
                <Button variant="contained" color="primary" onClick={handleAddCompanyAdmin} disabled={isSubmitting}>
                {t('Add')}
                {isSubmitting && <CircularProgress size={24} sx={{ ml: 1 }} />}
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}

{currentTab === 2 && (
  <Card sx={{ mt: 2 }}>
    <CardContent>
      <Stack direction="column" spacing={2}>
        <TextField
          label={t('Add Company User')}
          fullWidth
          type="email"
          variant="outlined"
          value={companyUserEmail}
          onChange={(e) => {
            setCompanyUserEmail(e.target.value);
            setIsCompanyUserEmailValid(validateEmail(e.target.value));
            setIsCompanyUserEmailEmptyError(false);
          }}
          error={!isCompanyUserEmailValid || isCompanyUserEmailEmptyError}
          helperText={
            (!isCompanyUserEmailValid && 'Invalid email format') ||
            (isCompanyUserEmailEmptyError && 'Please enter an email')
          }
          sx={{ mr: 2, flex: 1 }}
          InputProps={{
            endAdornment: (
              <>
                {companyUserEmail && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearCompanyUserEmail} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
        {/* 
        <Autocomplete
          id="company-name-autocomplete"
          options={suggestedCompanies.map((company) => company.CompanyName)}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Company Name')}
              fullWidth
              variant="outlined"
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
                // debouncedHandleCompanyUserSearch(e.target.value); 
              }}
              error={isCompanyNameEmptyError}
              helperText={isCompanyNameEmptyError && 'Company name cannot be empty'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {companyName && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearCompanyName} edge="end">
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, value) => {
            if (value) {
              setCompanyName(value);
            } else {
              setCompanyName(''); 
            }
          }}
          value={companyName}
          renderOption={(props, option) => (
            <li {...props}>
              <span>{option}</span>
            </li>
          )}
          freeSolo
        />
        */}
        <TextField
          label={t('Company Name')}
          fullWidth
          variant="outlined"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          error={isCompanyNameEmptyError}
          helperText={isCompanyNameEmptyError && 'Company name cannot be empty'}
          InputProps={{
            endAdornment: (
              <>
                {companyName && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearCompanyName} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      </Stack>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleAddCompanyUser} disabled={isSubmitting}>
          {t('Add')}
          {isSubmitting && <CircularProgress size={24} sx={{ ml: 1 }} />}
        </Button>
      </Box>
    </CardContent>
  </Card>
)}
      </Box>
      <AlertSnackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        severity={alertSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default CompanyAdminController;
