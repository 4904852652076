import axios, { AxiosResponse } from 'axios';
import { Tracker } from '../apiService/response-models';
import { ApiUrls } from '../endpoints';
import { QueryClient } from 'react-query';

export const appQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});

export const getTrackers = (): Promise<AxiosResponse<Tracker[]>> => {
  const dataUrl = ApiUrls.surveyEndpoint + '/trackers';
  return axios.get(dataUrl);
};
