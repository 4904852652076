import React, { useState } from 'react';
import { Box, Card, Dialog, DialogContent, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet-async';

const StyledIframe = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 'none', 
  overflow: 'hidden', 
});

const SupportRequest: React.FC = () => {

  return (
    <Box p={4}>
      <Helmet>
        <title>Support Request</title>
      </Helmet>
    <Card sx={{ p: 3 }}>
      <StyledIframe
        title="Feedback Form"
        id="freshwidget-embedded-form"
        src="https://support.cloudomaxcorp.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Support+Request&submitTitle=Create+Request&searchArea=no"
        scrolling="no"
        frameBorder="0"
        seamless
        style={{ width: '100%', height: '100vh', border: 'none' }}
      ></StyledIframe>
    </Card>
  </Box>
  );
};

export default SupportRequest;
