import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  Checkbox,
  IconButton,
  Skeleton,
  TablePagination,
  Container,
  Box,
  Typography,
  useMediaQuery,
  ListItemText
} from '@mui/material';
import { downloadSurveyPDF, getAllReportsFromBlob } from 'src/services/apiService';
import { RootState, useSelector } from 'src/store';
import { useTranslation } from 'react-i18next';
import PageHeader from './PageHeader';
import { Helmet } from 'react-helmet-async';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';

const AssessmentList: React.FC = () => {
  const [pdfNames, setPdfNames] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedSurveys, setSelectedSurveys] = useState<number[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const isExtraLargeScreen = useMediaQuery('(min-width: 2560px)');
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const { userCtx } = useSelector((state: RootState) => state.app);
  const userId = userCtx.userId;
  const userEmail = userCtx.email;
  const { t }: { t: any } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllReportsFromBlob(userId);
        if (!response.success) {
          setDataFetched(true);
          return;
        }

        const cleanedPdfNames = response.pdfNames.map(name => {
          const parts = name.split(' - ');
          return parts.slice(1).join(' - ');
        });
        setPdfNames(cleanedPdfNames);
        setDataFetched(true);
      } catch (error) {
        console.error('Error fetching assessments:', error);
        setDataFetched(true);
      }
    };

    fetchData();
  }, [userId]);

  const handleDownload = async (surveyName: string, userId: number) => {
    try {
      const pdfBlob = await downloadSurveyPDF(userEmail, surveyName, userId);
      if (!pdfBlob) {
        console.error(`PDF not found for survey ${surveyName}`);
        return;
      }
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `survey_${surveyName}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(`Error downloading survey ${surveyName}:`, error);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedSurveys([]);
    } else {
      setSelectedSurveys([...pdfNames.map((_, index) => index)]);
    }
    setSelectAll(!selectAll);
  };

  const handleDownloadAll = async () => {
    try {
      for (const index of selectedSurveys) {
        await handleDownload(pdfNames[index], userId);
      }
    } catch (error) {
      console.error('Error downloading all surveys:', error);
    }
  };

  const handleCheckboxChange = (index: number) => {
    if (selectedSurveys.includes(index)) {
      setSelectedSurveys(prevState => prevState.filter(id => id !== index));
    } else {
      setSelectedSurveys(prevState => [...prevState, index]);
    }
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedSurveys = pdfNames.slice(page * limit, page * limit + limit);

  return (
    <Container maxWidth={isExtraLargeScreen ? 'xl' : 'lg'}>
      <Helmet>
        <title>Assessments List</title>
      </Helmet>
      <PageHeader mobile={isSmallScreen} />
      {!dataFetched ? (
        <Card>
          <CardContent>
            {[...Array(10)].map((_, index) => (
              <List key={index}>
                <ListItem>
                  <Skeleton variant="rectangular" width="100%" height={60} />
                </ListItem>
              </List>
            ))}
          </CardContent>
        </Card>
      ) : pdfNames.length === 0 ? (
        <Card>
          <CardContent>
            <Typography variant="h6" align="center" color="error">
              No Assessments Found.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardContent>
            <List>
              <ListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <IconButton onClick={handleSelectAll} sx={{ p: 1 }}>
                  {selectAll ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
                </IconButton>
                <ListItemText
                  primary="Select All"
                  primaryTypographyProps={{ variant: 'body1', sx: { fontWeight: 'bold', fontSize: isSmallScreen ? '0.8rem' : '1rem', color: 'textPrimary' } }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleDownloadAll}
                  disabled={selectedSurveys.length <= 1}
                  sx={{ fontSize: isSmallScreen ? '0.7rem' : '0.8rem', minWidth: '90px' }}
                >
                  {t('Download All')}
                </Button>
              </ListItem>
              {paginatedSurveys.map((surveyName, index) => (
                <Grid item key={index} xs={12}>
                  <ListItem
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover': { backgroundColor: '#f5f5f5' }, mb: 1 }}
                    onMouseEnter={() => console.log('Hovered over survey:', index)}
                  >
                    <Checkbox
                      checked={selectedSurveys.includes(index)}
                      onChange={() => handleCheckboxChange(index)}
                      sx={{ ml: 0, mr: 1, '& .MuiSvgIcon-root': { fontSize: '1.3rem' } }}
                    />
                    <ListItemText
                      primary={surveyName}
                      primaryTypographyProps={{ variant: 'body1', sx: { fontWeight: 'bold', fontSize: isSmallScreen ? '0.8rem' : '1rem', color: 'textPrimary' } }}
                    />
                    <Box sx={{ flex: '0 0 auto', minWidth: '90px', ml: isSmallScreen ? 1 : 0 }}>
                      <Button
                        onClick={() => handleDownload(surveyName, userId)}
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ width: '100%', fontSize: isSmallScreen ? '0.7rem' : '0.8rem' }}
                      >
                        {t('Download')}
                      </Button>
                    </Box>
                  </ListItem>
                </Grid>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
      <Box mt={2} />
      {pdfNames.length > 0 && (
        <Card>
          <CardContent>
            <TablePagination
              component="div"
              count={pdfNames.length}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={handleLimitChange}
            />
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default AssessmentList;
